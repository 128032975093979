import { ScoreState, Tier, Aims_version } from "machine-trust-platform";

export const APP_CONFIG_URI = "/config.json";
export const JWT_REFRESH_BUFFER_MILLISECONDS = 5 * 60 * 1000; // 5 minutes
export const INACTIVITY_TIMEOUT = 15 * 60 * 1000; // 15 minutes
export const PENDING_LOGOUT_TIMEOUT = 2 * 60 * 1000; // 2 minutes

export const SUPPORTED_ROLES = ["admin", "developer", "executive", "scientist"];

export const CACHED_DATA_EXPIRY_MILLISECONDS = 5 * 60 * 1000; // 5 minutes



// The MTPPath enum is to represent the first portion (www.example.com/MTPPath/more/stuff) of the url this usually
// indicates what service (Projects, Tools) the user is currently reviewing
// Example use case is to determine the content to be displayed in the drawer.
export enum MTPPath {
  HOME = "/",
  AI_GOVERNANCE = "/organization",
  PROJECTS = "/projects",
  TOOLS = "/tools-sandbox",
  STANDARDS = "/standards",
  ADDON = "/addons",
  // SETTINGS = "/settings",
}

export const USER = {
  SUPPORTED_ROLES: ["admin", "developer", "executive", "scientist"],
  CONSENT_FORM: {
    NOT_COMPLETE: 0,
    ACCEPTED: 1,
    DECLINED: 2,
  },
};

export const SCORECARD = {
  /* ------ Scorecard Score ------ */
  SCORE: {
    STATE: {
      DRAFT: 1 as ScoreState,
      REVIEW: 2 as ScoreState,
      APPROVED: 3 as ScoreState,
    },
    getState: (int: ScoreState): string => {
      switch (int) {
        case SCORECARD.SCORE.STATE.REVIEW:
          return "pending.label";

        case SCORECARD.SCORE.STATE.APPROVED:
          return "approved.label";

        case SCORECARD.SCORE.STATE.DRAFT:
        default:
          return "draft.label";
      }
    },
  },
};

export const PROJECT = {
  CYCLE: {
    DEVELOPMENT: 0,
    DATA: 1,
    DRIFT: 2,
    DEPLOY: 3,
  },
  getCycle: (int: number): string => {
    switch (int) {
      case 0:
        return "development.label";
      case 1:
        return "data.label";
      case 2:
        return "drift.label";
      case 3:
      default:
        return "deploy.labal";
    }
  },
};

export const ORGANIZATION = {
  TIER: {
    ALL: 1 as Tier, // The lowest tier which every organization has access too
    GOLD: 2 as Tier,
    PLATINUM: 3 as Tier,
    DIAMOND: 4 as Tier,
  },
  AIMS_VERSION:{
    DEFAULT: "default",
    ISED: "ised",
    RCMP: "rcmp",
    PSPC: "pspc",
  }
};
