/*
 * File: Questionnaire
 * Project: mtp-webui
 * File Created: Friday, 9th April 2021 1:01:13 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Saturday, 28th August 2021 10:46:00 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * Last Modified: Saturday, 28th August 2021 10:46:00 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Model, Survey, StylesManager } from 'survey-react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from '../../../../../components'

import { getAnswers, cacheAnswers, flushCachedAnswers } from '../../../scorecard.helper';
import { useDispatch, useSelector } from '../../../../../hooks';
// Then locate them below and uncomment
import type { Score, Scorecard } from 'machine-trust-platform';

import { selectSelectedDraft, updateScorecardScore, submitScorecardScore } from '../../../slices/score.slice';

interface Props {
  json: any;

  scorecard: Scorecard;
  currentPage: number;
  setCurrentPage: (val: number) => void
  hasAnswers: boolean;
  // eslint-disable
  setHasAnswers: (val: boolean) => void;
  // saveDraft
}

const SurveyContainer = ({ scorecard, json, currentPage, setCurrentPage, hasAnswers, setHasAnswers }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { path } = useRouteMatch();

  const [completeDisable, setCompleteDisable] = useState(true);

  const scorecardUuid = scorecard ? scorecard.uuid : '';

  // Get the selected score only if it is in the draft state
  const selectedDraft = useSelector((state) => selectSelectedDraft(state, scorecard.uuid)); //Archit

  StylesManager.applyTheme('bootstrap');

  const surveyModel = useMemo(() => {
    const model = new Model(json);

    model.showNavigationButtons = false;
    model.isRequired = true;
    model.currentPageNo = currentPage;
    
    if (hasAnswers) model.data = getAnswers(scorecardUuid);
    
    return model

    /** Eslint is complaining that hasAnswers and selectedDraft are not necessary
     * However, when every these change the values of getAnswers change 
     * (and sometimes currentPage).
     */ 
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [json, currentPage, scorecardUuid, hasAnswers, selectedDraft])



  const handleShowComplete = useCallback((model: Model) => {
    setCompleteDisable(!(Object.values(model.data as Record<string, number>).filter(x => x > 0).length > 0 && model.isLastPage))
  }, [])

  const handlePageRender = () => surveyModel.data = getAnswers(scorecardUuid);

  /**
   * Description
   * @param {Model} model
   * @returns {any}
   */
  const handleSaveDraft = (model: Model) => {

    let answers = model && Object.keys(model.data).length !== 0 ? model.data : undefined;
    answers = !answers && scorecard ? getAnswers(scorecard.uuid) : answers;
    if (answers) {
      if (selectedDraft) {
        dispatch(updateScorecardScore({ score: { ...selectedDraft, answers }, scorecardUuid }));
      } else {
        dispatch(submitScorecardScore({ scorecardUuid, answers, isDraft: true }));
      }
    }
  };

  /**
   * Handles the onComplete of the survey
   * and submits the answers
   */
  const onComplete = (model: Model) => {
    if (Object.keys(model.data).length !== 0) {
      let answers = model && Object.keys(model.data).length !== 0 ? model.data : undefined;
      dispatch(submitScorecardScore({ scorecardUuid, answers, isDraft: false })).then((response: any) => {
        flushCachedAnswers(scorecard.uuid);
        history.push(`${path}/results?scorecard=${scorecardUuid}&score=${(response.payload["score"] as Score).uuid}`);
      });
    }
  };




  /**
   * Called only on page change
   * @param {*} model
   */
  const doOnCurrentPageChanged = useCallback((model: Model) => {
    const surveyComplete = document.getElementById('surveyComplete');
    // TODO: Currently we are filtering every time the there is a change this can be optimized
    if (surveyComplete) handleShowComplete(model)//setCompleteDisable(!(Object.keys(model.data).length > 0 && model.isLastPage));

    const surveyPrev = document.getElementById('surveyPrev');
    if (surveyPrev) surveyPrev.style.display = !model.isFirstPage ? 'inline' : 'none';

    const surveyNext = document.getElementById('surveyNext');
    if (surveyNext) surveyNext.style.display = !model.isLastPage ? 'inline' : 'none';

    // const surveyPageNo = document.getElementById('surveyPageNo');
    // if (surveyPageNo) surveyPageNo.value = model.currentPageNo;
  }, [])
  /**
   * Called when a question Value has been changed.
   * @param {*} model
   */
  const doOnValueChanged = useCallback(
    (model: Model) => {
      const surveyComplete = document.getElementById('surveyComplete');
      if (surveyComplete) handleShowComplete(model)

      const surveyDraft = document.getElementById('surveyDraft');
      if (surveyDraft) surveyDraft.style.display = Object.keys(model.data).length > 0 ? 'inline' : 'none';

      if (Object.keys(model.data).length > 0) {
        cacheAnswers(scorecardUuid, model.data);
        if (!hasAnswers) setHasAnswers(true);
      }
    },
    [hasAnswers, scorecardUuid, setHasAnswers]
  )

  useEffect(() => {
    doOnValueChanged(surveyModel);
    doOnCurrentPageChanged(surveyModel);
  }, [surveyModel, doOnValueChanged, doOnCurrentPageChanged]);

  return (
    <>
      {json && <>
        
        <Survey
          id="questionnaire"
          model={surveyModel}
          onComplete={onComplete}
          onAfterRenderPage={handlePageRender}
          onCurrentPageChanged={doOnCurrentPageChanged}
          onValueChanged={doOnValueChanged}
        />

        <div className="panel-footer card-footer">
          <div className="sv-footer__left ">
            {/* TODO: ARCHIT Replace these buttons with the new button components */}
            <Button
              id="surveyPrev"
              onClick={(e) => {
                surveyModel.prevPage();
                setCurrentPage(currentPage - 1);
                e.preventDefault();
              }}>
              {t('previous.label')}

            </Button>

          </div>
          <div className="sv-footer__right right-survey-footer">
            <Button id="surveyDraft"
              onClick={() => handleSaveDraft(surveyModel)}>
              {t('save.label')}
            </Button>

            <Button
              id="surveyNext"
              onClick={() => {
                surveyModel.nextPage();
                setCurrentPage(currentPage + 1);
              }}>
              {t('next.label')}
            </Button>
            <Button id="surveyComplete" disabled={completeDisable} onClick={() => surveyModel.completeLastPage()}>
              {t('complete.label')}
            </Button>
          </div>
        </div>
      </>}
    </>
  );
};

export default SurveyContainer;
