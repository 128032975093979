import FrameworkContent from "./FrameworkContent.json";
import FrameworkContent_pspc from "./FrameworkContent_pspc.json";
import FrameworkContent_ised from "./FrameworkContent_ised.json";
import SurveyForm from "../../../components/SurveyForm"
import { useSelector } from '../../../hooks'
import { selectAimsVersion } from '../../../features/auth/auth.slice';
import { Aims_version } from 'machine-trust-platform';

const FrameworkContainer = () => { 
  FrameworkContent.elements[1].imageLink = `${process.env.PUBLIC_URL}/images/ai-governance-process-pyramid.png`;
  FrameworkContent.elements[2].imageLink = `${process.env.PUBLIC_URL}/images/framework-process-flow.png`;
  FrameworkContent_ised.elements[1].imageLink = `${process.env.PUBLIC_URL}/images/ai-governance-process-pyramid.png`;
  FrameworkContent_ised.elements[2].imageLink = `${process.env.PUBLIC_URL}/images/td2-framework-proposal.png`;
  FrameworkContent_pspc.elements[1].imageLink = `${process.env.PUBLIC_URL}/images/ai-governance-process-pyramid.png`;
  FrameworkContent_pspc.elements[2].imageLink = `${process.env.PUBLIC_URL}/images/framework-circulation.png`;

  
  const organizationAimsVersion = useSelector(selectAimsVersion) as Aims_version
  let content;
  if(organizationAimsVersion == "rcmp"){
    content = FrameworkContent
  }else if(organizationAimsVersion=="ised"){
    content = FrameworkContent_ised
  }else if(organizationAimsVersion=="pspc"){
    content = FrameworkContent_pspc
  }
  
  return (
      <div>
        <SurveyForm content={content} Survey_KEY={'FrameworkAnswers'} name={"Framework"} completeButton={false}/>
      </div>
    );
};
  

export default FrameworkContainer;