import { combineReducers } from "@reduxjs/toolkit";
import scorecardReducer from './scorecard.slice';
import questionReducer from './question.slice';
import requirementReducer from './requirement.slice';
import scoreReducer from './score.slice';
import benchmarkReducer from './benchmark.slice';


export default combineReducers({
  element: scorecardReducer,
  question: questionReducer,
  requirement: requirementReducer,
  score: scoreReducer,
  benchmark: benchmarkReducer,
})