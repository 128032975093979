import React, { useState } from 'react';
import { useSprings, animated } from '@react-spring/web';
import { useMeasure } from 'react-use';


interface Content {
  tab: string;
  jsx: React.ReactElement
}

interface Props {
  content: Content[]
}

const TabbedContainer = ({ content }: Props) => {
  const tabs = content.filter((c) => c && c.tab);

  const [activeTab, setActiveTab] = useState(tabs[0].tab);

  // Used to make sure the container fits all tabs
  const [tabRef, { width: tabsWidth }] = useMeasure<HTMLUListElement>();

  const [props, api] = useSprings(tabs.length, (i) => ({
    opacity: tabs[i] && tabs[i].tab === activeTab ? 1 : 0,
    position: tabs[i] && tabs[i].tab === activeTab ? 'static' : 'absolute',
    display: tabs[i] && tabs[i].tab === activeTab ? 'block' : 'none',
  }));

  api.start((i) => {
    return {
      opacity: tabs[i] && tabs[i].tab === activeTab ? 1 : 0,
      position: tabs[i] && tabs[i].tab === activeTab ? 'static' : 'absolute',
      display: tabs[i] && tabs[i].tab === activeTab ? 'block' : 'none',
    };
  });

  const handleTabSelection = (e: any, data: any) => {
    setActiveTab(data);
    e.preventDefault();
  };

  const handleKeyDown = (e: any, index: number, data: any) => {
    if (e.keycode === index + 1) setActiveTab(data);
    e.preventDefault();
  };

  return (
    <div className="tabs-content-container" style={{ minWidth: tabsWidth + 16 }}>
      {/* eslint-disable react/jsx-props-no-spreading */}
      <ul className="tabs" ref={tabRef}>
        {tabs.map((c, i) => (
          <li key={c.tab} className={`${activeTab === c.tab ? 'active' : ''}`}>
            <a type="button" href={"/"} tabIndex={i} onClick={(e) => handleTabSelection(e, c.tab)} onKeyDown={(e) => handleKeyDown(e, i, c.tab)}>
              {c.tab}
            </a>
          </li>
        ))}
      </ul>
      <div className="tabs-content">
        {props.map(({display, opacity, position}, i) => (
          <animated.div className="content-display" key={tabs[i].tab} style={{display, opacity, position: position as any  }}>
            {tabs[i].jsx}
          </animated.div>
        ))}
      </div>
    </div>
  );
};

export default TabbedContainer;