// const Survey_KEY = 'SurveyAnswers';

/**
 * Description
 * @param {any} answers
 * @returns {any}
 */
export const cacheSurveyAnswers = (answers: any, Survey_KEY:any) => {
  const stringify = JSON.stringify(answers);
  window.localStorage.setItem(Survey_KEY, stringify);
};

/**
 * Description
 * @returns {any}
 */
export const flushCachedSurveyAnswers = (Survey_KEY:any) => {
  delete window.localStorage[Survey_KEY];
};

/**
 * Description
 * @returns {any}
 */
export const getSurveyAnswers = (Survey_KEY:any) => {
  const answers = window.localStorage.getItem(Survey_KEY);
  return answers ? JSON.parse(answers) : null;
};
