import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../../store";
import config from '../../../config.json'

import type { AIF360InputRequest, FaultReport, Tool, FairlearnMLInputRequest, FairlearnMLInput, AIF360Input } from "machine-trust-platform";
import { uploadDatasetFile, uploadModelFile } from "../../../utils/api";


interface ToolsState {
  list: Array<Tool>;
  selected?: Tool;
  userLastModTime: number;
  lastFetchTime: number;
  status: "idle" | "loading" | "failed";
}

const initialState: ToolsState = {
  list: [],
  selected: undefined,
  userLastModTime: -1,
  lastFetchTime: -1,
  status: 'idle'
}

/* -------------------------- Async Thunk --------------------------*/


// This gets the data for scorecards
const getAllTools = createAsyncThunk(
  "all/tools",
  async (_, thunkApi) => {

    try {
      const response = await axios({
        url: `${config.ai_tool_uri}/GetToolsList`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
      })

      if (response.data.faultReport.isFault) return thunkApi.rejectWithValue(response.data.faultReport as FaultReport)

      return response.data.response

    } catch (err) {
      return thunkApi.rejectWithValue(err)
    }
  }
);


interface aif360RequestArg {
  userId: string;
  input: AIF360Input;
  datasetFile: File;
}

const aif360Request = createAsyncThunk(
  'tools/aif360/request',
  async ({ userId, input, datasetFile }: aif360RequestArg, thunkApi) => {
    
    try {
      const datasetId = await uploadDatasetFile(userId, datasetFile);

      const request: AIF360InputRequest = {
        userId: userId,
        datasetId: datasetId,
        dependantAttributes: [input.dependentFeature as string],
        favorableLabels: [input.favorableLabel as number],
        unfavorableLabels: [input.unfavorableLabel as number],

        protectedAttributes: input.protectedFeatures as string[],
        privilegedGroups: input.privilegedGroups as any[],
        unprivilegedGroups: input.unprivilegedGroups as any[],

        dropAttributes: input.dropFeatures as string[],
        mitigate: false
      }

      const response = await axios({
        url: `${config.ai_tool_uri}/AIF360/AddUserRequest`,
        data: request,
        method: "POST",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
      })

      if (response.data.isFault) {
        thunkApi.rejectWithValue(response.data.faultReport.faultDescription)
      }

      return response.data.response

    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
  }
)
interface fairlearnMLRequestArg {
  userId: string;
  input: FairlearnMLInput;
  datasetFile: File;
  modelFile: File;
}

const fairlearnMLRequest = createAsyncThunk(
  'tools/fairlearnML/request',
  async ({userId , input, datasetFile, modelFile}: fairlearnMLRequestArg, thunkApi) => {
    
    try {
      // Upload the Dataset and Model Files to get their Ids
      const res = await Promise.all([uploadDatasetFile(userId, datasetFile as File), uploadModelFile(userId, modelFile as File)])
      const [datasetFileId, modelFileId] = res

      const req: FairlearnMLInputRequest = {
        userId: userId,
        datasetId: datasetFileId,
        modelId: modelFileId,
        dependantFeature: input.dependentFeature as string,
        sensitiveFeatures: input.protectedFeatures as string[],
        dropAttributes: input.dropFeatures as string[]
      }

      const response = await axios({
        url: `${config.ai_tool_uri}/ML/AddUserRequest`,
        data: req,
        method: "POST",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        }
      })

      if (response.data.isFault) {
        thunkApi.rejectWithValue(response.data.faultReport.faultDescription)
      }

      return response.data.response

    } catch (err) {
      return thunkApi.rejectWithValue(err)
    }

  }
)



/* -------------------------- App Thunk --------------------------*/

// ...



export const toolsSlice = createSlice({
  name: "tools",
  initialState,
  reducers: {

    updateToolStatus: (state, action ) => {
      state.status = action.payload
    },
    setSelectedTool: (state, action: PayloadAction<Tool>) => {
      state.selected = action.payload
    },

  },
  extraReducers: (builder) => {
    builder

      /*-------------- Get All Tools --------------  */
      .addCase(getAllTools.fulfilled, (state, action) => {
        state.list = action.payload
        state.lastFetchTime = (new Date()).getTime()
      })

      .addMatcher(
        isFulfilled,
        (state) => {
          state.status = 'idle'
        }
      )
      .addMatcher(
        isPending,
        (state) => {
          state.status = 'loading'
        }
      )
      .addMatcher(
        isRejected,
        (state) => {
          state.status = 'failed'
        }
      )
  },
});

// Tools State Actions
export const { updateToolStatus, setSelectedTool } = toolsSlice.actions;
export { getAllTools, aif360Request, fairlearnMLRequest };

// Selectors
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectToolStatus = (state: RootState) => state.tools.element.status
export const selectToolList = (state: RootState) => state.tools.element.list
export const selectToolLastFetchTime = (state: RootState) => state.tools.element.lastFetchTime

export default toolsSlice.reducer;
