import React, { useState } from 'react';
import Tab from './Tab';
import { useHistory, useLocation } from 'react-router';
// import { Link } from 'react-router-dom';
import { MTPPath } from '../../../../../constants';
import { getArrayOfPathName } from '../../../../utils/urlUtils';
import { SERVICES_LINK_LIST } from './links'
import styles from './service.tabs.module.scss'


const ServiceTabs = () => {
  const location = useLocation();
  const history = useHistory()

  // Parse the location and determine if the link has been selected or not
  const [activeTab, setActiveTab] = useState(`/${getArrayOfPathName(location)[0]}`)

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, path: MTPPath) => {
    setActiveTab(path)
    history.push(path)
    e.preventDefault()
    e.stopPropagation()
  }

  return (
      <div className={styles.tabList}
        data-tut="service-tabs"
      >

        {SERVICES_LINK_LIST.map((link, i) => (
          <Tab
            key={link.path}
            path={link.path}
            width = {link.width}
            icon = {link.icon}
            label={link.label}
            title={link.title}
            tier={link.tier}
            isActive={activeTab === link.path}
            onClick={handleClick}
          />
        ))}
      </div>

 
  )
}
 
export default ServiceTabs;