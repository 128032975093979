import React, { useState } from 'react'
import { Wizard, UploadFile } from '../../../../../components';
import { processCSV } from '../../../../../utils/dataset';

import type { FairlearnMLInput, FeatureDetails } from 'machine-trust-platform'
import { Button } from '../../../../../components'

import styles from './wizard.module.scss';
import DependantFeature from './DependentFeature';
import { useTranslation } from 'react-i18next';
import { fairlearnMLRequest } from '../../../tools.api';
import { useHistory } from 'react-router';

const initialFairlearnMLInput: FairlearnMLInput = {
  dependentFeature: undefined,

  // sensitiveFeatures
  protectedFeatures: undefined,

  // dropFeatures
  dropFeatures: [], // These are the features that that the user may want removed for the table 
  // For example maybe the ID

  datasetID: undefined,
  modelId: undefined

}

interface Props {
  userId: string;
}

const FairlearnMLWizard = ({ userId }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [datasetFile, setDatasetFile] = useState<File>()
  const [modelFile, setModelFile] = useState<File>()

  // Contains all details of the uploaded dataset
  // This is set during the UPLOAD_DATASET Stage
  const [featureDetails, setFeatureDetails] = useState<Record<string, FeatureDetails>>({})

  // Inputs required to run the AIF360 Script
  const [inputValues, setInputValues] = useState<FairlearnMLInput>(initialFairlearnMLInput)

  const handleSaveDatasetFile = (file: File) => setDatasetFile(file)
  const handleSaveModelFile = (file: File) => setModelFile(file)
  const handleUpdateInput = (update: FairlearnMLInput) => setInputValues(Object.assign({}, inputValues, update))
  const handleSelectDependantFeature = (featureName: string) => handleUpdateInput({ dependentFeature: featureName, protectedFeatures: undefined })

  const submitRequest = async () => {
    await fairlearnMLRequest(userId, inputValues, datasetFile as File, modelFile as File)
      .then(r => {
        history.push('/tools-sandbox/results')
      })
      .catch(err => {
        alert(err)
      })
  }


  const handleDataUpload = async (text: string | ArrayBuffer | null | undefined) => {
    const details = await processCSV(text as string, ',')
    setFeatureDetails(details)
    // Make sure that the Input Values are undefined
    // This is required if the user goes back to the upload dataset stage after an 
    // attempt to configure the inputs with a previous dataset
    setInputValues(initialFairlearnMLInput)
  }

  const userCanSubmitRequest: boolean = Boolean(datasetFile && modelFile &&
    inputValues.dependentFeature &&
    inputValues.protectedFeatures && inputValues.protectedFeatures.length > 0
  )


  return (
    <Wizard.Container>
      <h1>FairlearnML</h1>
      <p>
        {/* {t("fairlearn.description.label")} */}
      </p>
      <Wizard.Section
        title={''}
        description={""}
      >

        <UploadFile
          title={`${t('upload.dataset.label')}`}
          accept={".csv"}
          saveFileCallback={handleSaveDatasetFile}
          loadFileCallback={handleDataUpload}
        />

      </Wizard.Section>
      <Wizard.Section
        title={""}
        description=""
      >
        <UploadFile
          title={`${t('upload.model.label')}`}
          accept={'.joblib'}
          saveFileCallback={handleSaveModelFile}
        />

      </Wizard.Section>

      {datasetFile && modelFile && <Wizard.Section
        title={t("dependent.feature.label")}
        description={<p>
          Choose the dependent feature. The dependent feature is the column with the outcome, or the variable to be predicted.
        </p>}
      >
        <DependantFeature
          featureDetails={featureDetails}
          fairlearnInputValues={inputValues}
          selectionCallback={handleSelectDependantFeature}
        />
      </Wizard.Section>}
      {inputValues.dependentFeature && <Wizard.Section
        title={t("protected.features.label")}
        description={<p>
          Choose the protected feature(s). A protected feature is a column that partitions the population into groups whose outcome should have parity, i.e. features/characteristics that should get special consideration in preventing bias. Examples include race, gender, and religion, but protected attributes are application-specific and vary by datasets and objectives.
        </p>}
      >
        <Wizard.ProtectedFeatures

          featureDetails={featureDetails}
          inputValues={inputValues}
          onFeatureChange={handleUpdateInput}
        />
      </Wizard.Section>}
      {inputValues.protectedFeatures && <Wizard.Section
        title={t("drop.features.label")}
        description={<p>
          Optionally, choose any features that should not be included in the metrics calculations, such as any features that were not used in developing the model or features that you want the tool to ignore.
        </p>}
      >
        <Wizard.DropFeatures
          featureDetails={featureDetails}
          inputValues={inputValues}
          updateInput={handleUpdateInput}
        />
      </Wizard.Section>}



      <div className={styles.footer}>
        <Button
          disabled={!userCanSubmitRequest}
          onClick={submitRequest}
        >
          {t('submit.label')}
        </Button>
      </div>
    </Wizard.Container>
  )
}

export default FairlearnMLWizard;