import { FairlearnMLInput, FeatureDetails } from 'machine-trust-platform';
import React, { useState, useMemo } from 'react'
// import { useTranslation } from 'react-i18next';
// import ReactTooltip from 'react-tooltip';


import styles from './dependent.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  featureDetails: Record<string, FeatureDetails>;
  fairlearnInputValues: FairlearnMLInput;
  selectionCallback: (arg: string) => void;
}

const DependantFeature = ({ featureDetails, fairlearnInputValues, selectionCallback,...rest }: Props) => {
  // const { t } = useTranslation()

  const [dependantFeature, setDependantFeature] = useState<string>()


  const sortedList = useMemo(() => Object.values(featureDetails).sort((a, b) => a.feature < b.feature ? -1 : a.feature > b.feature ? 1 : 0), [featureDetails])


  /**
 * Description
 * @param {any} e
 * @returns {any}
 */
  const handleSelectDependantFeature = (e: any) => {
    const featureName = e.target?.value
    setDependantFeature(featureName)
    selectionCallback && selectionCallback(featureName)
  }

  return (
    <div
      className={styles.container}
      {...rest}
    >
      <div className={` select-features`}>
        {sortedList.map(f => (
          <label
            key={f.feature}
            className="feature-label"

            // For Tooltip
            // data-tip={`${f.feature}`}
            // data-place='top'
            // data-for="dependent-feature"
            // data-multiline={true}
          // data-delay-show={500}
          >
            <input
              type="radio"
              value={f.feature}
              checked={dependantFeature === f.feature}
              name='dependantFeature'
              onChange={handleSelectDependantFeature}

            />
            {f.feature}
          </label>))}
        {/* <ReactTooltip id="dependent-feature" effect="solid" type="dark"/> */}
      </div>

    </div>
  )
}

export default DependantFeature