import React, { useEffect } from "react";
// import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "./app/hooks";
import {
  authenticate,
  logout,
  selectAuthStatus,
  selectUser,
} from "./app/features/auth/auth.slice";
import { selectUIStatus } from "./app/features/ui/slices/uistate.slice";
import Layout from "./Layout";
import { LoadingPage } from "./app/components";
import { PrivacyAndConsent } from "./app/features/auth/components";
// import { useSpring } from '@react-spring/web'
// import Tutorials from './app/features/tutroials/Tutorials'

import "./app/assets/styles/index.scss";
import axios from 'axios';
import { store } from "./app/store";
import { clearIdTokens, getCachedTokens } from "./app/features/auth/auth.helpers";

require("./i18n");

axios.interceptors.response.use(
  function(successRes) {
    return successRes;
  }, 
  function(error) {
    const originalConfig = error.config;
    if (error.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      const headers = error.response.headers;
      const respData = error.response.data;
      let retryExisting = false;
      if (respData?.detail) {
        let errorDetail: String = respData.detail;
        if (errorDetail.startsWith('Authentication credentials were not provided')) {
          const cachedTokens = getCachedTokens()
          if (cachedTokens.id_token) {
            // We have ID token but it was not passed ... retry
            retryExisting = true;
          }
        }
      }
      if (retryExisting) {
          axios.request(originalConfig);
      } else {
        // Try to refresh
        clearIdTokens();
        store.dispatch(authenticate());
      }
    }
    return Promise.reject(error);
  }
);


function App() {
  const dispatch = useDispatch();

  // The status of both the Auth State and the UI State
  // to check if the application is loading
  const authStatus = useSelector(selectAuthStatus);
  const uiStatus = useSelector(selectUIStatus);

  // Show Loading Screen for both
  const loading = authStatus === "loading" || uiStatus === "loading";

  // After the user has been Authenticated we'll have access to the user & organization.
  // We know we have the user & organization data once the Auth Status has stopped loading
  // and the status is in 'idle'
  const user = useSelector(selectUser);

  const isConsentGiven =  user && user.consent.state === "accepted";

  useEffect(() => {
    setTimeout(() => {
      // Start up the Authentication Process
      dispatch(authenticate());
    }, 300);
  }, [dispatch]);

  // Auth Status get set to failed when the request for user and
  // organization fails
  if (authStatus === "failed") dispatch(logout())//throw Error("Authentication Failed");

  return (
    <div className="App">
      

      {loading && <LoadingPage loading />}

      {/* Show the Application while the App is not loading */}
      {!loading && (
        <>
          {!isConsentGiven && <PrivacyAndConsent />}
          {isConsentGiven && <Layout />}
        </>
      )}
    </div>
  );
}

export default App;
