import { createSlice, isAnyOf, } from "@reduxjs/toolkit";
import { User } from "machine-trust-platform";
import { RootState } from "../../../store";
import { login } from "../../auth/auth.slice";
// import { Status } from "machine-trust-platform";
// import { RootState } from "../../../store";
// import { login } from "../../auth/auth.slice";

export interface TutorialState {
  show: boolean;
}

const initialState: TutorialState = {
  show: false,
};

export const tutorialSlice = createSlice({
  name: "tutorialState",
  initialState,
  reducers: {
    turnOffTutorial: (state) => {
      state.show = false
    },
    turnOnTutorial: (state) => {
      state.show = true
    }

  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isAnyOf(login), (state, action) => {
        state.show = (action.payload.user as User).tutorial
    })

  },
});

// UI State Actions
export const { turnOffTutorial, turnOnTutorial } = tutorialSlice.actions;

// Selectors
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTutorial = (state: RootState) => state.uiState.tutorial.show

export default tutorialSlice.reducer;
