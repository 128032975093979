import SurveyForm2 from "../../../components/SurveyFormDT"
import dtJSON from "./DecisionTreeContentF.js";
import { useSelector } from "../../../hooks"
import { selectAimsVersion } from "../../../features/auth/auth.slice";
import { Aims_version } from "machine-trust-platform";
import PSPCdtJSON from "../../decisiontree/components/PSPCDecisionTreeContentF.js";

const DecisionTreeContainer = () => { 
  const organizationAimsVersion = useSelector(selectAimsVersion) as Aims_version;
  let AIGOA ;
  AIGOA = dtJSON
  if(organizationAimsVersion=='pspc')
  {
    AIGOA = PSPCdtJSON ;
  }
  return (
    <div>
      <SurveyForm2
        content={AIGOA}
        Survey_KEY={'DecisionTreeAnswers'} 
        name={"DecisionTree"}
        completeButton={true}
        currentPage={0}
    />
    </div>
  );
};
  

export default DecisionTreeContainer;