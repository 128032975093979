/*
 * File: AIMSScorecardSurvey.jsx
 * Project: mtp-webui
 * File Created: Wednesday, 5th May 2021 2:15:44 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Thursday, 16th September 2021 10:07:16 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllScorecardQuestion, selectQuestionLastFetchTime, selectQuestionStatus, selectSelectedQuestionList } from '../../features/scorecard/slices/question.slice';
import { getAllScorecardRequirement, selectRequirementLastFetchTime, selectRequirementStatus, selectSelectedRequirementList } from '../../features/scorecard/slices/requirement.slice';
import ScorecardQuestionnaire from '../../features/scorecard/components/Questionnaire';
import { selectSelectedScorecard } from '../../features/scorecard/slices/scorecard.slice';
import { useSelector, useDispatch } from '../../hooks';
import { Question, Requirement } from 'machine-trust-platform';

interface Props {
  scorecardUuid: string;
}

/**
 * Note that the scorecardUuid is passed to the Questionnaire screen through the Route
 */
const Questionnaire = ({ scorecardUuid }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const scorecard = useSelector(selectSelectedScorecard)
  const requirementList = useSelector((state) => selectSelectedRequirementList(state, scorecardUuid)) || [] as Requirement[]
  const questionList = useSelector((state) => selectSelectedQuestionList(state, scorecardUuid)) || [] as Question[]

  const requirementLastFetchTime = useSelector(state => selectRequirementLastFetchTime(state, scorecardUuid))
  const questionLastFetchTime = useSelector(state => selectQuestionLastFetchTime(state, scorecardUuid))

  const reqStatus = useSelector(state => selectRequirementStatus(state, scorecardUuid))
  const qStatus = useSelector(state => selectQuestionStatus(state, scorecardUuid))

  useEffect(() => {
    if (scorecardUuid && requirementLastFetchTime === -1) dispatch(getAllScorecardRequirement(scorecardUuid))
  }, [dispatch, scorecardUuid, requirementLastFetchTime])

  useEffect(() => {
    if (scorecardUuid && questionLastFetchTime === -1) dispatch(getAllScorecardQuestion(scorecardUuid))
  }, [dispatch, scorecardUuid, questionLastFetchTime])


  return (
    <>
      {scorecard &&
        <ScorecardQuestionnaire
          title={t(`${scorecard.type.toLowerCase()}.label`)}
          scorecard={scorecard}
          isLoading={qStatus === 'loading' || reqStatus === 'loading'}
          requirementList={requirementList}
          questionList={questionList} />
      }
    </>
  );
};

export default Questionnaire;
