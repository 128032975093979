import React from 'react';

import styles from './wizard.container.module.scss';

interface Props {

  children: React.ReactNode;

}

const WizardContainer = ({ children }: Props) => {

  return (
    <div className={`${styles.container} container-fluid tool-wizard`}>
        {children}
    </div>
  )

}

export default WizardContainer