import { useEffect, useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Survey, Model, StylesManager } from 'survey-react';
import { SurveyPDF } from 'survey-pdf';
import { getNameOfMonthFromTimeStamp } from '../../utils/time';
import { getSurveyAnswers, cacheSurveyAnswers } from './surveyform.helpers';
import { Button } from "../../components";
import 'survey-react/survey.css';
import styles from "./App.module.css";
//import axios from axios;

// import styles from "./survey.form.module.scss";

interface Props {
  content: any;
  Survey_KEY: any;
  name: string;
  completeButton: any;

  //hasRecommendation: any;

}

const SurveyForm = ({ content, Survey_KEY, name, completeButton }: Props) => {
  const { t } = useTranslation();
  const [hasRecommendation, setRecommendation] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  StylesManager.applyTheme('bootstrap');

  const onSavePDFClicked = () => {

    // Where do these values come from? A4 paper size in mm.
    const pdfWidth = surveyModel.pdfWidth || 210;
    const pdfHeight = surveyModel.pdfHeight || 297;

    const date = new Date();
    const filename = `${name}-${getNameOfMonthFromTimeStamp(date).substring(0, 3)}-${date.getDate()}.pdf`;
    const options = {
      fontSize: 10,
      margins: {
        left: 12,
        right: 12,
        top: 12,
        bot: 12,
      },
      format: [pdfWidth, pdfHeight],
    };

    let h2="<html><head><style>p {text-align: center;}</style></head><body><p>"+surveyModel.processedCompletedHtml+"</p></body></html>"
    let result={
      type: "html",
      name: "AIAnote",
      html: h2,
    }

    if(name=="preAIA"){
      content["pages"][0]["elements"].push(result)
      var surveyPDF = new SurveyPDF(content, options);
      surveyPDF.data = surveyModel.data;
      content["pages"][0]["elements"].pop()
    }else{
      var surveyPDF = new SurveyPDF(content, options);
      surveyPDF.data = surveyModel.data;
    }
    
    // Here we render the filename in the margin at the bottom. Until the license fee is
    // paid the margin at the top is taken by the commercial message.
    //
    // There are examples on the SurveyJS website showing how to render logos in that space:
    // https://surveyjs.io/Examples/Pdf-Export?id=survey-pdf-header&platform=Reactjs&theme=modern#content-js

    surveyPDF.onRenderFooter.add((_, ntop) => {
      ntop.drawText({
        text: filename,
        fontSize: 10,
      });
    });
    
    surveyPDF.save(filename);
  };

  

  const onSurveyAfterPageRender = () => surveyModel.data = getSurveyAnswers(Survey_KEY);

  const onSurveyValueChanged = useCallback((model: any) => {
    if (Object.keys(model.data).length > 0) {
      cacheSurveyAnswers(model.data, Survey_KEY);
    }
  }, [Survey_KEY]);

  const clearSurvey = () => {
    surveyModel.clear();
    window.localStorage.clear();
  }


  const surveyModel = useMemo(() => {
    const model = new Model(content);
    model.showNavigationButtons = false;
    model.isRequired = true;
    return model
  }, [content])

  useEffect(() => {
    onSurveyValueChanged(surveyModel);
  }, [surveyModel, onSurveyValueChanged]);

  return (
    <>

      {/*
         At the moment the data is only stored in localStorage and printed to PDF.
          Add handlers for 'onComplete' and 'onCurrentPageChanged' as needed.
          Also, showNavigationButtons may need to be set to true.
        */}

      <Survey model={surveyModel} onAfterRenderPage={onSurveyAfterPageRender} onValueChanged={onSurveyValueChanged} />

      <div className="panel-footer card-footer">
        <div className="svFooterRight right-survey-footer">
          <Button id="surveySavePDF" className="svFooterRight" onClick={onSavePDFClicked} data-tut="save-ntop">
            {t('pdf.label')}
          </Button>
        </div>
        <div className="svFooterRight right-survey-footer">
          <Button id="surveyClearPDF" className="svFooterRight" onClick={clearSurvey} data-tut="clear-ntop">
            {t('clear.label')}
          </Button>
        </div>
        {completeButton ?
          (<div className="svFooterRight right-survey-footer">
            <Button className="svFooterRight" onClick={() => {
              surveyModel.completeLastPage()
              setIsOpen(true);
              setRecommendation(surveyModel);
            }
              //axios.push(link, surveyModel)
            }>
              Complete
            </Button>
          </div>) : (<span></span>)
        }
        {/* {hasRecommendation && (

          {hasRecommendation && (

          <div className="svFooterRight right-survey-footer">
            <Button id="surveySavePDF" className="svFooterRight" 
            onClick={
              () => { setRecommendation(!showRecommendation) } }
               >
               Display Recommendations
            </Button>  
          </div> 
<<<<<<< HEAD
          )} */}






      </div>
      {/* <div>
        {isOpen && <div> <h5>Thank you for completing the survey!</h5> </div>}
      </div> */}
    
    </>
  );
};

export default SurveyForm;