/*
 * File: ConditionalWrapper.jsx
 * Project: mtp-webui
 * File Created: Monday, 3rd May 2021 10:38:20 am
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 22nd June 2021 9:17:09 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React from 'react';

interface IConditionalWrapProps {
  condition: boolean;
  wrapper: (c: React.ReactNode) => JSX.Element;
  children: React.ReactNode;
}

const ConditionalWrapper = ({ condition, wrapper, children }: IConditionalWrapProps): JSX.Element => (condition ? wrapper(children) : <>{children}</>);

export default ConditionalWrapper;
