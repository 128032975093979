import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router";
import ReactTooltip from 'react-tooltip'
import { Role, Aims_version, Tier } from "machine-trust-platform";
import _uniqueId from 'lodash/uniqueId';

import { AimsVersionCheckWrapper } from "../../features/auth/components";

import styles from "./drawer.module.scss";


interface Props extends React.HTMLAttributes<HTMLDivElement> {
  basePath: any;
  id?: string;
  label: string;
  title?: string; // This is what we see when we hover over the link
  path: string;
  // The Organization Aims Version allowed to Access this Link
  aims_version: Aims_version[];
  // The Organization Tier allowed to Access this Link
  // tier: Tier;

  // Roles allowed to access this link (Currently Not Used)
  roles?: Role[];

  // For the Tutorial selector
  dataTut?: string;

}

const DrawerLink_Framework = ({ basePath, id, path, label, title, aims_version, roles, dataTut, ...rest }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory()

  // Parse the location and determine if the link has been selected or not
  const selected =
    (path === location.pathname && location.pathname === basePath) ||
    (location.pathname.includes(path) && path !== basePath)

  // Used for the Tool Tip
  const tooltipId = _uniqueId('drawer-link-')
  return (

    <AimsVersionCheckWrapper aims_version={aims_version} >
      <div
        {...rest}
        id={id}
        // For Tutorials
        data-tut={dataTut}

        // For Tooltip
        data-tip={`${title ? t(title) : title}`}
        data-for={tooltipId}
        data-place='right'
        data-multiline={true}
        data-delay-show={500}

        className={`${styles.linkContainer}  ${selected ? styles.selected : ""} dla-link`}
        onClick={() => history.push(path)}
      >

        <div className={`${styles.link}`}>
          <span className="mr-auto">{t(label)}</span>
        </div>
        {title && <ReactTooltip id={tooltipId} effect="solid" type="dark" />}

      </div>
    </AimsVersionCheckWrapper>

  );
};

export default DrawerLink_Framework;
