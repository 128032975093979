import React from "react";
import { Route, Switch } from "react-router-dom";

// Routes
import AIGovernance from "./AIGovernance.route";
import Project from "./Project.route";
import Standards from "./Standards.route";
import Tools from "./Tools.route";
// import MTI from "./MTI.route";
import { MTPPath } from "../constants";
import Screens from "../app/screens";
import { useSelector } from "../app/hooks";
import { selectOrganization } from "../app/features/auth/auth.slice";

const Routes = () => {

  const organization = useSelector(selectOrganization)

  return (
    <Switch>
      <Route path={"/"} exact component={Screens.Home} />
      <Route path={MTPPath.AI_GOVERNANCE} component={AIGovernance} />
      <Route path={MTPPath.PROJECTS} component={Project} />
      <Route path={MTPPath.STANDARDS} component={Standards} />
      <Route path={MTPPath.ADDON} exact component={Screens.AddOns}/>
      <Route path={MTPPath.TOOLS}>
        <Tools contextUuid={organization?.uuid as string} />
      </Route>
    </Switch>
  );
};

export default Routes;
