import React from 'react';
import { PreAIAContainer }  from "../../../features/preAIA/components";
import { QuestionnaireWrapper } from "../../../components" ;
import { useTranslation } from 'react-i18next';

const PreAIA = () => {
    const { t } = useTranslation();

    return (
      <>
        <QuestionnaireWrapper title={t('preAIA.assessment.title')}>
          < PreAIAContainer />
        </QuestionnaireWrapper>
      </>
    );
}

export default PreAIA;