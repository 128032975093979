import React from 'react';
import variables from '../../assets/styles/variables.module.scss';
import styles from './progress.bar.module.scss';

interface Props {
  backgroundColor?: string;
  completed: number;
}

const ProgressBar = ({ backgroundColor=variables.primaryColor, completed }: Props) => {

  const fillerStyles = {
    width: `${completed}%`,
    backgroundColor: backgroundColor,
  }

  return (
    <div className={styles.container}>
      <div className={styles.filler} style={fillerStyles} >
        <span className={styles.label}>{`${completed}%`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;