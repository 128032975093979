
const QUESTIONNAIRE_KEY = 'QuestionnaireAnswers';


/**
 * Description
 * @param {string} scorecardUuid
 * @param {any} answers
 * @returns {any}
 */
export const cacheAnswers = (scorecardUuid: string, answers: any) => {
  const stringify = JSON.stringify(answers);
  window.localStorage.setItem(`${scorecardUuid}${QUESTIONNAIRE_KEY}`, stringify);
};

/**
 * Description
 * @param {string} scorecardUuid
 * @returns {any}
 */
export const flushCachedAnswers = (scorecardUuid: string) => {
  window.localStorage.removeItem(`${scorecardUuid}${QUESTIONNAIRE_KEY}`);
};

/**
 * Description
 * @param {string} scorecardUuid
 * @returns {any}
 */
export const getAnswers = (scorecardUuid?: string) => {
  const answers = window.localStorage.getItem(`${scorecardUuid}${QUESTIONNAIRE_KEY}`);
  return answers ? JSON.parse(answers) : undefined;
};
