import { Location } from 'history'

/**
 * Creates an array from the path name. 
 * @param {Location<any>} path - the path na
 * @returns {string[]} 
 * @example <caption> If the pathname is "/hello/world" </caption>
 * // the function returns ["hello", "world"]
 */
export function getArrayOfPathName(location: Location<any>) {
  let pathname = location.pathname
  if (pathname.charAt(0) === "/") pathname = pathname.substring(1);
  return pathname.split("/");
}

export function isValidUrl(url:string){
  // eslint-disable-next-line no-useless-escape
  return url.match(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm);
}

export function isValidImageUrl(url:string){
  return(url.match(/\.(jpeg|jpg|gif|png|svg)$/) != null);
}

export const isValidLogoUrl = (url?:string) => (url && isValidUrl(url) && isValidImageUrl(url));

