import axios, { Method } from "axios";
import settings from "../config.json";

interface Options {
  data?: any;
  params?: any;
  config?: {
    url?: string;
    method?: Method;
    headers?: any;
    params?: any;
  };
}

/**
 *  Works only when communicating to the django server
 * @param {string} subdir  the path for the api call
 * @param {object} user    the current user that will be used to verify the users permissions
 * @param {object} object  this contains the data sent with a post for example or the custom configurations
 */
const client = async (subdir: string, idToken: string, options?: Options) => {
  const { data, config, params } = options || {};
  return axios({
    url: `${settings.api_uri}${subdir}`,
    params: params ? params : undefined,
    method: data ? "POST" : "GET",
    data: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: `Bearer ${idToken}`,
      Accept: "application/json",
      "content-type": "application/json",
    },
    withCredentials: true,
    ...config,
  });
};



const uploadDatasetFile = async (userId: string, file: File) => {
  const form = new FormData()
  form.append('userId', userId)
  form.append('formFile', file)
  form.append('fileName', file.name)

  const res = await axios.post(`${settings.ai_tool_uri}/UploadDataSetFile`, form)
  if (res.data.faultReport.isFault) {
    throw new Error(res.data.faultReport.faultDescription)
  } else {
    return res.data.response.userFileId 
  }
}
const uploadModelFile = async (userId: string, file: File) => {
  const form = new FormData()
  form.append('userId', userId)
  form.append('formFile', file)
  form.append('fileName', file.name)

  const res = await axios.post(`${settings.ai_tool_uri}/UploadModelFile`, form)
  if (res.data.faultReport.isFault) {
    throw new Error(res.data.faultReport.faultDescription)
  } else {
    return res.data.response.userFileId
  }
}




export { client, uploadDatasetFile, uploadModelFile };
