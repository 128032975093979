import React, { useMemo, useEffect } from 'react';

import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters, Column as RTColumn,
  usePagination,
  useRowSelect,
} from 'react-table'
import GlobalFilter from './GlobalFilter';
import ColumnFilter from './ColumnFilter';
import { Column, initializeColumns } from './table.helpers'

import styles from './table.module.scss'
import { Button } from '..';
import { useTranslation } from 'react-i18next';
import { Checkbox } from './Checkbox';

// TODO: Document how to use
// The Columns are the same as in the React-Table documentation
// The only difference are the added Cell Filtering Tools

interface Props {
  columns: Column[];
  items: any[];
  handleRowSelection?: (rows: any[]) => void;
  footer?: boolean;
  columnFilter?: boolean
  globalFilter?: boolean
  sortable?: boolean
  initialState?: {
    // TODO: add more typing as needed
    sortBy : {id: string, desc: boolean}[],
  }
}


/**
 * 
 * @param {Column[]} columns - 
 * @param {any[]} items - 
 * @param {boolean?} footer - 
 * @param {boolean?} columnFilter - 
 * @param {boolean?} globalFilter - 
 * @param {boolean?} sortable - 
 * @returns 
 */
const Table = ({
  columns,
  items,
  handleRowSelection,
  columnFilter = false,
  globalFilter = false,
  sortable = false,
  footer = false,
  initialState = undefined
}: Props) => {
  const { t } = useTranslation()

  const COLUMNS = useMemo(() => initializeColumns(columns, columnFilter), [columns, columnFilter])
  const ITEMS = useMemo(() => items, [items])

  const defaultColumn = useMemo(() => (
    {
      Filter: columnFilter ? ColumnFilter.Default : undefined
    }
  ), [columnFilter])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    preGlobalFilteredRows,
    state,
    setGlobalFilter,
    selectedFlatRows,
  } = useTable({
    columns: COLUMNS as readonly RTColumn<any>[],
    data: ITEMS as readonly any[],
    defaultColumn: columnFilter ? defaultColumn : undefined,
    initialState: initialState
  },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,

    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (<Checkbox {...getToggleAllRowsSelectedProps()} />),
            Cell: ({ row }) => (<Checkbox {...row.getToggleRowSelectedProps()} />),
            width: 15,
            maxWidth: 20

          },
          ...columns
        ]
      })
    }
  )
  
  useEffect(() => {
    if (handleRowSelection) {
      const items = selectedFlatRows.map((row) => row.original)
      handleRowSelection(items)
    } 
  }, [selectedFlatRows])

  return (
    <div className={styles.container}>
      <div className={styles.optionalHeader}>
        {globalFilter &&
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            filter={state.globalFilter}
            setFilter={setGlobalFilter}
          />}
      </div>

      <table {...getTableProps()}>
        <thead>
          { // Create Header Section
            headerGroups.map(group => (

              <tr {...group.getHeaderGroupProps()}>

                { // Header for each Column
                  group.headers.map(col => (

                    <th {...col.getHeaderProps({
                      ...sortable && col.getSortByToggleProps(),
                      style: { minWidth: col.minWidth, width: col.width }
                    })}>

                      {col.render('Header')}

                      {
                        col.isSorted &&
                        <span>
                          <i className={`ri-arrow-${col.isSortedDesc ? 'down' : 'up'}-fill`} />
                        </span>}

                      {
                        columnFilter &&
                        <div>
                          {col.canFilter ? col.render('Filter') : null}
                        </div>
                      }
                    </th>
                  ))
                }

              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            page.map((row) => {
              prepareRow(row)

              return (
                <tr {...row.getRowProps()}>
                  {
                    row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    ))
                  }
                </tr>
              )
            })
          }

        </tbody>
        { // Conditionally show the footer based on the footer prop.
          footer && <tfoot>
            {
              footerGroups.map(group => (
                <tr {...group.getFooterGroupProps()}>
                  { // Footer for each Column
                    group.headers.map(col => (

                      <th {...col.getFooterProps()}>
                        {col.render('Footer')}
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </tfoot>}
      </table>
      <div className={styles.footer}>

        <Button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {t('previous.label')}
        </Button>

        <span>
          {
            `${t('page.label')}: `}
          <strong>
            {state.pageIndex + 1}/{pageOptions.length}
          </strong>
        </span>

        <Button
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {t('next.label')}
        </Button>

      </div>
    </div>
    
  )
}

export type { Column }

export { ColumnFilter }
export default Table;