import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Score } from 'machine-trust-platform';

interface Props {
  scorecardUuid?: string;
  score: Score;
  totalRequirementWeight: number;
  path: string
}

const ScoreDetailsSection = ({ scorecardUuid, score, totalRequirementWeight, path }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const generateLinkToResults = (params?: {[key: string]: any}) => {
    const httpParams = Object.entries({
      scorecard: scorecardUuid,
      scoreUuid: score.uuid,
      ...params,
    }).map(([key, value]) => `${key}=${value}`)?.join('&');
    return `${path}/questionnaire/results?${httpParams}`;
  }

  const handleReviewResultsClick = (e: any) => {
    history.push(generateLinkToResults());
    e.preventDefault();
  };

  return (
    <div className="score-table-details-section">
      <div className="container">
        <div className="row">
          {score.requirements?.map((r, i) => (
            <div key={i} className="requirement-details col-sm" onClick={() => history.push(generateLinkToResults({requirement: r.requirement.requirement_uuid}))}>
              <p className="req-name-weight">
                <span>{r.requirement.name}</span> <br />
                {`${t('weight.label')}: ${Math.round((r.weight / totalRequirementWeight) * 100)}%`}
              </p>
              <div className="req-score">
                <span>
                  {Math.round(r.score)} <br />
                </span>
                {t('score.label')}
              </div>
            </div>
          ))}
        </div>
        {path ? (
          <div className="row">
            <div className="req-btn-container">
              <button className="btn btn-primary" type="button" onClick={handleReviewResultsClick}>
                {t('review.results.label')}
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ScoreDetailsSection;