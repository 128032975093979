import { FeatureDetails, AIF360Input } from 'machine-trust-platform';
import React, { Fragment, useCallback } from 'react'
import Group from './Group/Group'

import styles from './privileged.unprivileged.groups.module.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  inputValues: AIF360Input;
  featureDetails: Record<string, FeatureDetails>;
  submitCallback?: (update: AIF360Input) => void
}
interface Groups {
  privilegedGroups?: any[];
  unprivilegedGroups?: any[];
}

const PrivilegedUnprivilegedGroups = ({ inputValues, featureDetails, submitCallback, ...rest }: Props) => {


  
  const updateGroups = useCallback((newGroup: Groups) => {
    const groups = { privilegedGroups: inputValues.privilegedGroups, unprivilegedGroups: inputValues.unprivilegedGroups}
    const _newGroups = { ...groups, ...newGroup }
    submitCallback && submitCallback(_newGroups)
  }, [inputValues.privilegedGroups, inputValues.unprivilegedGroups, submitCallback])

  return (

    <div
      className={`${styles.container}`}
      {...rest}
    >
      <div className={`${styles.content}`}>

        {['privilegedGroups', 'unprivilegedGroups'].map((g, i) => (
          <Fragment key={g}>
            <Group
              inputValues={inputValues}
              featureDetails={featureDetails}
              type={g as "privilegedGroups" | "unprivilegedGroups"}
              updateInput={updateGroups}
            />
            {
              i !== 1 && <div className={styles.divider} />
            }
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default PrivilegedUnprivilegedGroups;