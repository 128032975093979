import React from 'react';
import { useSelector } from '../../../hooks';

import { selectSelectedScorecard } from '../../../features/scorecard/slices/scorecard.slice';

import { ScoreDisplay } from '../../../features/scorecard/components';
// import { ScorecardScoresContainer } from "./Components/Container";


const Dashboard = () => {

  const scorecard = useSelector(selectSelectedScorecard)

  return (
    <>
    <div className="dashboard container-fluid" data-tut="ai-governance-dashboard">
      {scorecard && <ScoreDisplay type={"AIGS"} scorecard={scorecard} /> }
    </div>
  </>
  );
}

export default Dashboard;