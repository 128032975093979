import React from 'react';

interface ICheckBoxProps {
  indeterminate?: boolean;
  // name:string
}

const useCombinedRefs = (...refs: any[]): React.MutableRefObject<any> => {
  const targetRef = React.useRef();

  React.useEffect(() => {
    refs.forEach(ref => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

export const Checkbox = React.forwardRef<HTMLInputElement, ICheckBoxProps>(
  ({ indeterminate, ...rest }, ref: React.Ref<HTMLInputElement>) => {
  const defaultRef = React.useRef()
    const resolvedRef = useCombinedRefs(ref, defaultRef)

  React.useEffect(() => {
    if (typeof resolvedRef === 'object' && resolvedRef?.current) resolvedRef.current.indeterminate = indeterminate ?? false;
  }, [resolvedRef, indeterminate])

  return (
    <>
      <input type='checkbox' ref={resolvedRef} {...rest}/>
    </>
  )
})