import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Screens from '../../app/screens'
import SelectedProjectRoute from "./Selected.Project.route";

const ProjectRoute = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      {/* Screens */}
      <Route path={url} exact component={Screens.Project.SelectionTable} />

      {/* Nested Routes */}
      <Route path={`${url}/:name`} component={SelectedProjectRoute} />

    </Switch>
  );
};

export default ProjectRoute;
