import React from 'react';
// import { useTranslation } from 'react-i18next';
import { ToolRequestTable } from '../../../features/tools/components';
// import { getToolRequests, selectToolRequestList } from '../../../features/tools/slices/request.slice';
// import { useDispatch, useSelector } from '../../../hooks';

import styles from './requests.module.scss'


interface Props {
  userId: string;
}

const ToolRequests = ({ userId }: Props) => {
  // const dispatch = useDispatch()
  // const { t } = useTranslation()


  return (
    <>
    <div
      className={`${styles.container} container`} >
      
      <ToolRequestTable userId={userId} />
    </div>
    </>
  )
}

export default ToolRequests;