import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { batch } from "react-redux";
import { useDispatch } from "../../../../../hooks";
import { updateConsent, logout } from "../../../auth.slice";

// Assets
import logo from "../../../../../assets/images/logo-nuenergy.png";
import Button from "../../../../../components/Button";
import { agreementHTML } from "./SWAgreementDetails";

import styles from "./terms-and-conditions.module.scss";

const PrivacyAndConsent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Used to track if the user has scrolled to the bottom of the Terms & Conditions Form
  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  // The user will select a checkbox if the user is to accept the terms and conditions
  // this state tracks if the box has been checked
  const [understandChecked, setUnderstandChecked] = useState(false);

  // Tracks if the user can select the Accept Button
  const acceptEnabled = understandChecked && scrolledToBottom;

  /**
   *  Handles the check box where the user will check that they understand and agree to the terms and conditions
   * @param {React.ChangeEvent<HTMLInputElement>} e
   * @returns {any}
   */
  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUnderstandChecked(e.target.checked);
  };

  /** If the user Accepts the Terms and Conditions we will update the users consent to accepted and
   * allow them to login.
   */
  const acceptConsent = () => {
    dispatch(updateConsent("accepted"));
  };

  /** If the user declines the terms and conditions this will be logged and the user will be logged out
   * the user will be logged out
   */
  const declineConsent = () => {
    batch(() => {
      dispatch(updateConsent("declined"));
      dispatch(logout());
    });
  };

  /**
   * Used for the Scroll event to change the ScrolledToBottom state once the user has scrolled to the
   * bottom of the page.
   * @param {any} event
   * @returns {any}
   */
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const containerHeight = event.currentTarget.clientHeight;
    const scrollHeight = event.currentTarget.scrollHeight;
    const scrollTop = event.currentTarget.scrollTop;
    const progress = ((scrollTop + containerHeight) / scrollHeight) * 100

    if (progress > 99 && !acceptEnabled) {
      setScrolledToBottom(true);
    }
  };

  /**
   * Sets the agreement HTML inside the body of the terms and conditions container.
   * This is done on the first render.
   */
  useEffect(() => {
    const swAgreementDiv = document.getElementById("agreement");
    if (swAgreementDiv) swAgreementDiv.innerHTML = agreementHTML;
  }, []);

  return (
    <main id="main" className={`${styles.main}`}>
      <div className={`${styles.consent} shadow-sm`}>
        <div className={`${styles.consentHeader}`}>
          <img className="logo-nuenergy" src={logo} alt={"NuEnergy.ai"} />
          <h1>{t("terms.and.conditions.label")}</h1>
        </div>

        <div className={`${styles.consentBody}`} onScroll={handleScroll}>
          <div id="agreement" className={styles.swAgreement} />
        </div>

        <div className={styles.consentFooter}>
          <div className="form-group form-check">
            <input
              type="checkbox"
              className="form-check-input"
              defaultChecked={understandChecked}
              disabled={!scrolledToBottom}
              onChange={handleCheckbox}
              id="exampleCheck1"
            />
            <label className="form-check-label" htmlFor="exampleCheck1">
              {t("confirm.read.understand.agree.message")}
            </label>
          </div>

          <div className={styles.consentBtns}>
            <Button
              className="shadow-br"
              onClick={acceptConsent}
              disabled={!acceptEnabled}
            >
              {t("accept.label")}
            </Button>

            <Button
              className="shadow-br"
              variant="secondary"
              onClick={declineConsent}
            >
              {t("decline.label")}
            </Button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PrivacyAndConsent;
