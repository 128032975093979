import { FeatureDetails, ToolWizardInput } from 'machine-trust-platform';
import React, { useMemo, Fragment } from 'react'
import styles from './drop.features.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  inputValues: ToolWizardInput;
  featureDetails: Record<string, FeatureDetails>;
  updateInput?: (input: ToolWizardInput) => void;
  disableFeature?: (f: FeatureDetails) => boolean;
}

const DropFeatures = ({ inputValues, featureDetails, updateInput, disableFeature = (f: FeatureDetails) => false, ...rest }: Props) => {


  // Sort the Features alphabetically
  const featureList = useMemo(() =>
    Object.values(featureDetails)
      .filter(f => f.feature !== inputValues.dependentFeature && !inputValues.protectedFeatures?.includes(f.feature))
      .sort((a, b) => a.feature < b.feature ? -1 : a.feature > b.feature ? 1 : 0),
    [featureDetails, inputValues.dependentFeature, inputValues.protectedFeatures])

  /**
   * Description
   * @param {React.ChangeEvent<HTMLInputElement>} e
   * @returns {any}
   */
  const handleFeatureSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target?.value

    updateInput && updateInput({
      dropFeatures:
        (inputValues.dropFeatures as string[]).includes(value) ?
          // Remove Feature from the list.  
          (inputValues.dropFeatures as string[]).filter(feature => feature !== value)
          :
          // Add the feature to the list
          [...(inputValues.dropFeatures as string[]), value]
    })
  }

  return (
    <div
      className={`${styles.container}`}
      {...rest}
    >
      <div className={`${styles.checkBoxes} select-features`}>
        {featureList.map(f => (
          <Fragment key={`${f.feature}-checkbox`}>
            <label

              className='feature-label'
            >
              <input
                type="checkbox"
                value={f.feature}
                checked={(inputValues.dropFeatures as string[]).includes(f.feature)}
                onChange={handleFeatureSelection}
                disabled={disableFeature(f)}
              />
              {f.feature}
            </label>
          </Fragment>))}
      </div>
    </div>
  )
}

export default DropFeatures