/*
 * File: inrdex.js
 * Project: Machine Trust Platform
 * File Created: Monday, 14th June 2021 12:02:29 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Thursday, 26th August 2021 11:45:27 am
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MTPPath } from '../../../../../constants';

import AIGovernanceTutorial from './AIGovernance.tutorials';
import ProjectsTutorial from './Projects.tutorials';

const Tutorials = () => {
  return (
    <Switch>
      <Route path={`${MTPPath.AI_GOVERNANCE}`} component={AIGovernanceTutorial} />
      <Route path={`${MTPPath.PROJECTS}`} component={ProjectsTutorial} />
    </Switch>
  );
};

export default Tutorials;
