import React from "react";
import { useSpring, animated } from "@react-spring/web";
import { usePrevious, useMeasure } from "../../hooks";

// interface Props {
//   toggle: boolean;
//   children: React.ReactNode;
// }

const Collapsible = ({ toggle = false, children }) => {
  const [bind, { height: viewHeight }] = useMeasure();
  const previous = usePrevious(toggle);

  const spring = useSpring({
    from: { height: 0, opacity: 0 },
    to: { height: toggle ? viewHeight : 0, opacity: toggle ? 1 : 0 },
  });

  return (
    <animated.div
      aria-expanded={toggle}
      style={{
        position: "relative",
        opacity: spring.opacity,
        height: toggle && previous === toggle ? "auto" : spring.height,
        zIndex: toggle ? 0 : -100,
      }}
    >
      <animated.div {...bind}>
          {children}
      </animated.div>
    </animated.div>
  );
};

export default Collapsible;
