/*
 * File: indes.jsx
 * Project: Machine Trust Platform
 * File Created: Monday, 14th June 2021 3:29:49 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 27th August 2021 12:23:06 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import DashboardTutorial from './Dashboard.tutorials';
import AIMSQuestionnaireTutorial from './AIMSQuestionnaire.tutorials';
import ScoresTutorial from '../AIGovernance.tutorials/Scores.tutorial';
import AIATutorial from './AIA.tutorials';
import AIMSResultsTutorial from './AIMSResults.tutorials';

const ProjectsTutorial = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact component={DashboardTutorial} />
      <Route path={`${path}/aims/questionnaire/results`} component={AIMSResultsTutorial} />
      <Route path={`${path}/aims/questionnaire`} component={AIMSQuestionnaireTutorial} />
      <Route path={`${path}/algorithmic-impact-assessment`} component={AIATutorial} />
      <Route path={`${path}/aims/scores`} component={ScoresTutorial} />
    </Switch>
  );
};

export default ProjectsTutorial;
