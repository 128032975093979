import SurveyForm from '../../../components/SurveyForm';
import CanvasContent from "./CanvasContent.json"; 

const CanvasContainer = () => {

  // The survey json source contains links to (three at them moment) banner images that are
  // illustrative in nature. The easiest solution would be to link to public versions of
  // these images published at NuEnergy.ai production site.
  //
  // However, at the moment, we are linking to the versions of these images hosted by the
  // webserver delivering the application. These are dynamic URLs so we need to replace
  // some placeholder elements stored in the json itself. There are two options:
  //
  // 1. Add an element of type 'html' and then add a href link. This is nice is some ways
  // as it also allows us to set the title/alt attributes on the images which show up as
  // the user hovers over the images. Here is some code that does this (the placeholders
  // themselves would have to be added):
  //
  // const textTrustCategories = t('canvas.img.trust-categories');
  // const textGeneratingQuestions = t('canvas.img.generating-questions');
  // const textAppliedToolsAndMeasurements = t('canvas.img.applied-tools-and-measurements');
  // surveyJSON.elements[5].html = `<img src="${process.env.PUBLIC_URL}/images/canvas-trust-categories.png" title="${textTrustCategories}" alt="${textTrustCategories}" width="600" />`;
  // surveyJSON.elements[8].html = `<img src="${process.env.PUBLIC_URL}/images/canvas-generating-questions.png" title="${textGeneratingQuestions}" alt="${textGeneratingQuestions}" width="600" />`;
  // surveyJSON.elements[10].html = `<img src="${process.env.PUBLIC_URL}/images/canvas-applied-tools-and-measurements.png" title="${textAppliedToolsAndMeasurements}" alt="${textAppliedToolsAndMeasurements}" width="600" />`;
  //
  // By the way, the reason to add both title and alt attributes is because there's a
  // long-standing bug where alt text does not show up in WebKit-based browsers under
  // certain circumstances (not enough space allocated for it?).
  //
  // 2. But adding the images using the 'html' type does not play nice with the 'save pdf'
  // functionality. Hence we use the 'image' element type as placeholder. It works the same
  // way and seems to work well when exported to pdf. No way (that I have found) to set the
  // title/alt attributes however.
  
  CanvasContent.elements[5].imageLink = `${process.env.PUBLIC_URL}/images/canvas-trust-categories.png`;
  CanvasContent.elements[8].imageLink = `${process.env.PUBLIC_URL}/images/canvas-generating-questions.png`;
  CanvasContent.elements[10].imageLink = `${process.env.PUBLIC_URL}/images/canvas-applied-tools-and-measurements.png`;

return(
  <div>
    <SurveyForm content={CanvasContent} Survey_KEY ={'CanvasAnswers'} name= {"Canvas"} completeButton={false}/>
  </div>

);
};

export default CanvasContainer;