import { BContainer }  from "../../../features/bitrapy/components";
import { useSelector } from '../../../hooks'
import { selectAimsVersion } from '../../../features/auth/auth.slice';
import { Aims_version } from 'machine-trust-platform';

const Bitrapy = () => {
  const organizationAimsVersion = useSelector(selectAimsVersion) as Aims_version
    return (      
      <BContainer aims={organizationAimsVersion}/>
    );
}

export default Bitrapy;

