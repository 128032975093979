import React, { useEffect } from 'react';
import { Drawer } from '../../app/components';
import { ORGANIZATION, MTPPath } from '../../constants';
import { useDispatch, useSelector } from '../../app/hooks';
import { enableDrawer, selectDrawerIsDisabled } from '../../app/features/ui/slices/navigation.slice';

const { TIER } = ORGANIZATION

const StandardsDrawer = () => {
  const dispatch = useDispatch()

  const basePath = MTPPath.STANDARDS

  const drawerIsDisabled = useSelector(selectDrawerIsDisabled)

  useEffect(() => {
    if (drawerIsDisabled) dispatch(enableDrawer())

  }, [dispatch, drawerIsDisabled])

  return (
    <>
      <Drawer.Link  // Dashboard
        basePath={basePath}
        path={`${basePath}/algorithmic-impact-assessment`}
        label="aia.label"
        title="algorithmic.impact.assessment.label"
        tier={TIER.ALL}
      />

      <Drawer.Link
        basePath={basePath}
        path={`${basePath}/alt-ai`}
        label="alt.ai.label"
        title="alt.ai.label"
        tier={TIER.ALL}
      />
      <Drawer.Link
        basePath={basePath}
        path={`${basePath}/ciosc`}
        label="CIOSC"
        title="CIOSC"
        tier={TIER.ALL}
      />

      <Drawer.Link
        basePath={basePath}
        path={`${basePath}/ieee`}
        label="IEEE"
        title="IEEE"
        tier={TIER.ALL}
      />

      <Drawer.Link
        basePath={basePath}
        path={`${basePath}/waterloo`}
        label="Waterloo"
        title="Waterloo Protocol (AEAI Standards)"
        tier={TIER.ALL}
      />

    </>
  )
}

export default StandardsDrawer;