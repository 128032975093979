import React from 'react';
import { AIF360 } from '../../../features/tools/components';


import styles from './aif360.screen.module.scss';

interface Props {
  userId: string;
}

const AIF360Screen = ({userId}: Props) => {

  return (
    <>
    <div className={`${styles.container} container`}>
      <AIF360.Wizard userId={userId}/>
    </div>
    </>
  )
}

export default AIF360Screen;
