import React from 'react'
import { useRouteMatch } from 'react-router';
import AIAChart from '../../../features/aia/components/AIAChart';
import { selectSelectedProject } from '../../../features/project/project.slice';
import { ScoreDisplay } from '../../../features/scorecard/components';
import { selectScorecardStatus, selectSelectedScorecard } from '../../../features/scorecard/slices/scorecard.slice';
import { useSelector } from '../../../hooks';



const ProjectDashboard = () => {
  const { url } = useRouteMatch()
  const isLoading = useSelector(selectScorecardStatus) === "loading"
  const scorecard = useSelector(selectSelectedScorecard)
  const project = useSelector(selectSelectedProject)

  const algorithmicImpactAssessment = project?.algorithmicImpactAssessment

  return (
    <>
    <div className="dashboard container-fluid">
      {scorecard && !isLoading && <ScoreDisplay type={"AIMS"} scorecard={scorecard} />}
      {algorithmicImpactAssessment && <AIAChart assessment={algorithmicImpactAssessment} link={`${url}/algorithmic-impact-assessment`}/>}
    </div>
    </>
  )
}

export default ProjectDashboard;