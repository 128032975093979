/*
 * File: AIMSQuestionnairtRoute.jsx
 * Project: mtp-webui
 * File Created: Tuesday, 11th May 2021 4:11:07 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 27th August 2021 11:21:22 am
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import { useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "../../src/app/hooks";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { getAllScorecardScore, selectScorecardScoreLastFetchTime } from "../app/features/scorecard/slices/score.slice";
import Screens from "../app/screens";
import { differenceInMinutes } from "date-fns";

interface Props {
  scorecardUuid: string;
}

const QuestionnaireRoute = ({ scorecardUuid }: Props) => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const lastFetchTime = useSelector((state) => selectScorecardScoreLastFetchTime(state, scorecardUuid))

  useEffect (() => {
    if (scorecardUuid && (lastFetchTime === -1 || differenceInMinutes(new Date(lastFetchTime as number), new Date()) >= 5 )){
    dispatch(getAllScorecardScore(scorecardUuid));
  }
}, [dispatch, lastFetchTime, scorecardUuid]);


  return (
    <Switch>
      <Route path={`${url}`} exact>
        <Screens.Scorecard.Questionnaire scorecardUuid={scorecardUuid} />
      </Route>
      <Route path={`${url}/results`}>
        <Screens.Scorecard.QuestionnaireResults />
      </Route>
    </Switch>
  );
};

export default QuestionnaireRoute;
