import { format } from 'date-fns';
import { ToolRequest } from 'machine-trust-platform';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Collapsible } from '../../../../../components';
import { useSelector, useDispatch } from '../../../../../hooks';
import { getToolRequestResult, selectToolRequestResult } from '../../../slices/result.slice';
import { getToolNameFromID, getToolRequestStatus } from '../../../tools.helpers';
import AIF360 from '../../AIF360';
import FairlearnML from '../../FairlearnML';

interface Props {
  toolRequest: ToolRequest
}

const Row = ({ toolRequest }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [showResult, setShowResult] = useState(false)

  const toggleResult = () => setShowResult(!showResult)
  const result = useSelector(state => selectToolRequestResult(state, toolRequest.id, toolRequest.toolId))
  const status = t(`${getToolRequestStatus(toolRequest)}`)

  const toolName = getToolNameFromID(Number(toolRequest.toolId))

  const handleOnClick = () => {
    if (status === 'Complete' && result) {
      toggleResult()
    }
  }

  useEffect(() => {
    if (!result && status === 'Complete') {
      dispatch(getToolRequestResult({ toolId: toolRequest.toolId, requestId: toolRequest.id }))
    }

  }, [dispatch, result, status, toolRequest])


  return (
    <>
      <tr
        onClick={handleOnClick}
      >
        <th scope="row">
          {toolName}
        </th>
        <td>
          {
            
            
            toolRequest.userFileName
          }
        </td>
        <td>
          {format(new Date(toolRequest.created), 'dd-MM-yyyy')}
        </td>
        <td>
          {t(`${getToolRequestStatus(toolRequest)}`)}
        </td>

      </tr>

      <Collapsible
        toggle={showResult}
      >
        <div className="container-fluid">
          {toolName === 'AIF360' &&
            <AIF360.ResultDisplay
              result={result ? result['result'] : undefined}
              requestId={toolRequest.id}
            />}
          {toolName === 'FairlearnML' && <FairlearnML.ResultDisplay result={result ? result['result'] : undefined} />}

        </div>

      </Collapsible>
    </>
  )

}

export default Row;
