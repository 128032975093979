import { FairlearnMLResult } from 'machine-trust-platform';
import React from 'react';

import styles from './result.display.module.scss';

interface Props {
  result?: FairlearnMLResult
}

const ResultDisplay = ({ result }: Props) => {

  const hasResults = result && result.result !== null
  const rr = result && result.result

  return (
    <>
      {hasResults &&

        <div className={`${styles.container} container`}>
          <div className={`row`}>
            <span className={`col`}>
              Accuracy:
            </span>
            <span className={`col ${styles.toolsScoreCol}`}>
              {rr && rr.overall.accuracy !== undefined ? `${rr.overall.accuracy.toFixed(2)}` : ""}
            </span>
            <span className={`${styles.toolsDefCol}`}>
              The degree to which the result of a measurement conforms to the correct value or a standard.
            </span>
          </div>

          <div className={`row`}>
            <span className={`col`}>
              Demographic Parity Difference:
            </span>
            <span className={`col ${styles.toolsScoreCol}`}>
            {rr && rr.overall.demographicParityDifference !== undefined ? `${rr.overall.demographicParityDifference.toFixed(2)}` : ""}
            </span>
            <span className={`${styles.toolsDefCol}`}>
              The difference between the largest and smallest group-level selection rate. A demographic parity difference of 0 means that all groups have the same selection rate.
            </span>
          </div>

          <div className={`row`}>
            <span className={`col`}>
              Demographic Parity Ratio:
            </span>
            <span className={`col ${styles.toolsScoreCol}`}>
            {rr && rr.overall.demographicParityRatio !== undefined ? `${rr.overall.demographicParityRatio.toFixed(2)}` : ""}
            </span>
            <span className={`${styles.toolsDefCol}`}>
              The ratio between the smallest and the largest group-level selection rate. A value of 1 means all the groups have the same selection rate.
            </span>
          </div>

          <div className={`row`}>
            <span className={`col`}>
              Equalized Odds Difference:
            </span>
            <span className={`col ${styles.toolsScoreCol}`}>
            {rr && rr.overall.equalizedOddsDifference !== undefined ? `${rr.overall.equalizedOddsDifference.toFixed(2)}` : ""}
            </span>
          </div>

          <div className={`row`}>
            <span className={`col`}>
              Equalized Odds Ratio:
            </span>
            <span className={`col ${styles.toolsScoreCol}`}>
            {rr && rr.overall.equalizedOddsRatio !== undefined ? `${rr.overall.equalizedOddsRatio.toFixed(2)}` : ""}
            </span>
            <span className={`${styles.toolsDefCol}`}>
              Equalized odds is a statistical notion of fairness in machine learning that ensures that classification algorithms do not discriminate against protected groups.
            </span>
          </div>

          <div className={`row`}>
            <span className={`col`}>
              False Positive Rate:
            </span>
            <span className={`col ${styles.toolsScoreCol}`}>
            {rr && rr.overall.fpr !== undefined? `${rr?.overall.fpr.toFixed(2)}` : ""}
            </span>
            <span className={`${styles.toolsDefCol}`}>
              The false positive rate - the ratio between the number of false positives and the total number of negatives.
            </span>
        </div>
        
        <div className={`row`}>
          <span className={`col`}>
            False Positive Rate Difference:
          </span>
          <span className={`col ${styles.toolsScoreCol}`}>
            {rr && rr.overall.fprd !== undefined? `${rr?.overall.fprd.toFixed(2)}` : ""}
          </span>
        </div>

          <div className={`row`}>
            <span className={`col`}>
              False Negative Rate:
            </span>
            <span className={`col ${styles.toolsScoreCol}`}>
            {rr && rr.overall.fnr !== undefined? `${rr?.overall.fnr.toFixed(2)}` : ""}
            </span>
            <span className={`${styles.toolsDefCol}`}>
              The false negative rate - the ratio between the number of false negatives and the total number of positives.
            </span>
        </div>

        <div className={`row`}>
          <span className={`col`}>
            False Negative Rate Difference:
          </span>
          <span className={`col ${styles.toolsScoreCol}`}>
            {rr && rr.overall.fnrd !== undefined? `${rr?.overall.fnrd.toFixed(2)}` : ""}
          </span>
        </div>

        <div className={`row`}>
          <span className={`col`}>
            Mean Prediction:
          </span>
          <span className={`col ${styles.toolsScoreCol}`}>
            {rr && rr.overall.meanPrediction !== undefined? `${rr.overall.meanPrediction.toFixed(2)}` : ""}
          </span>
        </div>
        
          <div className={`row`}>
            <span className={`col`}>
              Selection Rate:
            </span>
            <span className={`col ${styles.toolsScoreCol}`}>
            {rr && rr.overall.selectionRate !== undefined? `${rr.overall.selectionRate.toFixed(2)}` : ""}
            </span>
            <span className={`${styles.toolsDefCol}`}>
              The fraction of data points in each group classified as the positive outcome (e.g. the in the loan status example, the fraction of women approved for the loan)
            </span>
        </div>
        
        <div className={`row`}>
          <span className={`col`}>
            True Positive Rate:
          </span>
          <span className={`col ${styles.toolsScoreCol}`}>
            {rr && rr.overall.tpr !== undefined? `${rr.overall.tpr.toFixed(2)}` : ""}
          </span>
        </div>

        <div className={`row`}>
          <span className={`col`}>
            True Negative Rate:
          </span>
          <span className={`col ${styles.toolsScoreCol}`}>
            {rr && rr.overall.tnr !== undefined ? `${rr.overall.tnr.toFixed(2)}` : ""}
          </span>
        </div>

        </div>}

      {!hasResults && <div className={styles.error}>{result && result.error ? result.error : "An Error Occurred"}</div>}
    </>

  )
}

export default ResultDisplay;
