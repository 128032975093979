import { useRef, useEffect } from "react";


/**
 * Returns the Previous state
 * @param state 
 * @returns 
 */
function usePrevious<T>(state: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = state;
  }, [state]);
  return ref.current;
}

export default usePrevious;
