import { AIF360Input, FeatureDetails } from 'machine-trust-platform';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadFile, Wizard, Button } from '../../../../../components';
import { processCSV } from '../../../../../utils/dataset';
import DependantFeature from './DependentFeature';
import styles from './wiz.module.scss';
import FavorableUnfavorable from './FavorableUnfaovorable';
import PrivilegedUnprivilegedGroups from './PrivilegedUnprivilegedGroups';
import ProtectedFeatures from '../../../../../components/Wizard/ProtectedFeatures';
import DropFeatures from '../../../../../components/Wizard/DropFeatures';
// import { useDispatch } from 'react-redux';
import { aif360Request } from '../../../tools.api';
import { useHistory } from 'react-router';

/**
 * Note: When developing the backend for the tools some of the language implemented was not correct
 *  example dependantAttributes should be dependantFeatures. You will see that when communicating with the
 *  backend service this language is used but else where in the code the correct language will be used. In other
 *  words when talking about the dataset columns, features and attributes mean the same thing.
 * 
 */

const initialAIF360Input: AIF360Input = {
  dependentFeature: undefined,
  unfavorableLabel: undefined,
  favorableLabel: undefined,
  protectedFeatures: undefined,
  privilegedGroups: undefined,
  unprivilegedGroups: undefined,
  dropFeatures: [],
  datasetID: undefined,
}

interface Props {
  userId: string
}

const AIF360Wizard = ({ userId }: Props) => {
  const { t } = useTranslation()
  // const dispatch = useDispatch()
  const history = useHistory()

  // Track the current stage of the Wizard
  const [file, setFile] = useState<File>()

  // Contains all details of the uploaded dataset
  // This is set during the UPLOAD_DATASET Stage
  const [featureDetails, setFeatureDetails] = useState<Record<string, FeatureDetails>>({})

  // Here we have the collection of inputs required to run the AIF360 Script
  const [inputValues, setInputValues] = useState<AIF360Input>(initialAIF360Input)

  // const [error, setError] = useState<any>()

  const userCanSubmitRequest: boolean = Boolean(file &&
    inputValues.dependentFeature &&
    inputValues.favorableLabel !== undefined &&
    inputValues.unfavorableLabel !== undefined &&
    inputValues.protectedFeatures && inputValues.protectedFeatures.length > 0 &&
    inputValues.privilegedGroups && inputValues.privilegedGroups.length > 0 &&
    inputValues.unprivilegedGroups && inputValues.unprivilegedGroups.length > 0
  )
  /**
   * Description
   * @param {File} file
   * @returns {any}
   */
  const handleSaveFile = (file: File) => setFile(file)

  /**
   * Description
   * @param {Record<string, FeatureDetails>} details
   * @returns {void}
   */
  const handleDataUpload = useCallback(async (text: string | ArrayBuffer | null | undefined) => {
    const details = await processCSV(text as string, ',')
    setFeatureDetails(details)

    // Automatically as the Features that must be dropped (i.e non-numeric features)
    const drop = Object.values(details).filter(f => !f.isNumeric).map(f => f.feature)
    // Make sure that the Input Values are undefined
    // This is required if the user goes back to the upload dataset stage after an 
    // attempt to configure the inputs with a previous dataset
    setInputValues({ ...initialAIF360Input, dropFeatures: drop})
  }, [])

  /**
 * Update the input values
 * @param {AIF360Input} update - the partial Input object with the values to be replaces
 * @returns {void}
 */
  const handleUpdateInput = useCallback((update: AIF360Input) => setInputValues(Object.assign({}, inputValues, update)), [inputValues])


  
/**
 * Submits the Tool Request
 * @returns {any}
 */
  const submitRequest = async () => {
    if (file) {
      aif360Request(userId, inputValues, file as File)
        .then(r => {
          history.push('/tools-sandbox/results')
        })
        .catch(err => {
          alert(err)
        })
    }
  }

  return (
    <Wizard.Container>
      <h1>AIF360</h1>
      <p>
        <strong>Note:</strong> AIF360 will only work with numeric columns. If your dataset contains columns that are not numeric or columns that can not be dropped, please return to pre-processing your dataset.<p>Currently, AIF360 can only handle a binary classification dataset (i.e. the dependent variable, or the outcome of the prediction task, must be a binary variable with only two values).</p>
        {/* {t("aif360.description.label")} */}
      </p>

      <Wizard.Section
        title={''}
        description={
          <p>
            {/* <strong>{t("warning.label").toUpperCase()}: </strong>
            {t("aif360.upload.dataset.description.label")} */}
          </p>
        }
      >
        <UploadFile
          title={t('upload.dataset.label')}
          accept={".csv"}
          saveFileCallback={handleSaveFile}
          loadFileCallback={handleDataUpload}
        />
      </Wizard.Section>

      {file && featureDetails && <Wizard.Section
        title={t("dependent.feature.label")}
        description={
          <p>
            Choose the dependent feature. The dependent feature is the column with the outcome, or the variable to be predicted.
            <br /><br />
            <strong>Note:</strong> The dependent feature must be binary, so any column with non-binary values is automatically filtered out of these options.
          </p>
        }
      >
        <DependantFeature
          featureDetails={featureDetails}
          aif360InputValues={inputValues}
          selectionCallback={(featureName: string) => setInputValues({
            ...inputValues,
            dependentFeature: featureName,
            protectedFeatures: undefined,
            privilegedGroups: undefined,
            unprivilegedGroups: undefined,
            unfavorableLabel: undefined,
            favorableLabel: undefined
          })}
        />
      </Wizard.Section>}


      {inputValues.dependentFeature && featureDetails[inputValues.dependentFeature] && <Wizard.Section
        title={t('select.favorable.unfavorable.labels.label')}
        description={
          <p>
            Within the binary dependent feature, the favorable label is the value/outcome that provides an advantage to the recipient. The unfavorable label is the opposite value/outcome, that does not provide an advantage.
            <br /><br />
            <strong>Note:</strong>The numeric values are used, so the number (0 or 1) corresponding to the advantageous outcome should be the favorable label, and the number corresponding to the other non-advantageous outcome should be the unfavorable label.
          </p>
        }
      >
        <FavorableUnfavorable
          dependantFeature={inputValues.dependentFeature}
          labels={Array.from(featureDetails[inputValues.dependentFeature].labels)}
          labelSelectCallback={(labels: {
            favorable?: number;
            unfavorable?: number;
          }) => setInputValues({
            ...inputValues,
            unfavorableLabel: labels.favorable,
            favorableLabel: labels.unfavorable
          })}
        />


      </Wizard.Section>}

      {inputValues.dependentFeature && inputValues.favorableLabel !== undefined && inputValues.unfavorableLabel !== undefined && <Wizard.Section
        title={t('protected.features.label')}
        description={
          <p>Choose the protected feature(s). A protected feature is a column that partitions the population into groups whose outcome should have parity, i.e. features/characteristics that should get special consideration in preventing bias. Examples include race, gender, and religion, but protected attributes are application-specific and vary by datasets and objectives.</p>
        }
      >
        <ProtectedFeatures
          featureDetails={featureDetails}
          inputValues={inputValues}
          disableFeature={(f) => !f.isNumeric}
          onFeatureChange={handleUpdateInput}
        />
      </Wizard.Section>}

      {inputValues.protectedFeatures &&

        <Wizard.Section
          title={t('protected.features.label')}
          description={
            <p>
              Within the protected features, there are values and groups of the population that have historically been at a systematic advantage. These are privileged groups. The opposite are unprivileged groups that do not have a systematic advantage. For example, with Gender as a protected attribute, the privileged group might historically be males while the unprivileged group might be females.
              <br /><br />
              These groups can be combinations of values of different protected features (for example, with Gender and Marriage as twoprotected features, Privileged Group 1 could be males for Gender and married for Marriage, indicating married males have an advantage, and the opposite would be an unprivileged group).
            </p>
          }
        >
          <PrivilegedUnprivilegedGroups
            inputValues={inputValues}
            featureDetails={featureDetails}
            submitCallback={handleUpdateInput}
          />

        </Wizard.Section>}

      {inputValues.unprivilegedGroups && inputValues.privilegedGroups &&
        <Wizard.Section
          title={`${t('drop.features.label')} (${t('optional.label')})`}
          description={
            <p>
              Optionally, choose any features that should not be included in the metrics calculations, such as any features that were not used in developing the model or features that you want the tool to ignore.
            </p>
          }
        >
          <DropFeatures
            inputValues={inputValues}
            featureDetails={featureDetails}
            updateInput={handleUpdateInput}
            disableFeature={(f: FeatureDetails) => !f.isNumeric}
          />
        </Wizard.Section>}

      <div className={styles.footer}>
        <Button
          disabled={!userCanSubmitRequest}
          onClick={submitRequest}
        >
          {t('submit.label')}
        </Button>
      </div>
    </Wizard.Container>
  )
}

export default AIF360Wizard