/*
 * File: AIMSResults
 * Project: Machine Trust Platform
 * File Created: Thursday, 26th August 2021 4:11:15 pm
 * Author: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Last Modified: Monday, 30th August 2021 3:12:39 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
// import { useTranslation } from 'react-i18next';
import {  Step } from 'react-joyride';
import CustomJoyRide from '../CustomJoyRide';

const AIMSResultsTutorial = () => {
  // const { t } = useTranslation();
  
  const STEPS: Step[] = [
    // {
    //   target: '[data-tut="questionnaire-results"]',
    //   content: <Tutorial header="AI Model Scorecard (AIMS) Questionnaire Results ">{t('aims.questionnaire.results.tutorial.step.1')}</Tutorial>,
    // },
    // {
    //   target: '[data-tut="questionnaire-results-score"]',
    //   content: <Tutorial header="AIMS Score">This is the overall score obtained from the AIMS questionnaire</Tutorial>,

    // },
    // {
    //   target: '[data-tut="questionnaire-results-requirement-score"]',
    //   content: <Tutorial header="Score breakdown">Here you'll be able to view a breakdown of the result for the Scorecard based off each section of the AIMS questionnaire</Tutorial>,

    // },
    // {
    //   target: '[data-tut="questionnaire-results-requirement-question-score"]',
    //   content: <Tutorial header="Recommendations">This is where you can find a detail breakdown of each question from the AIMS questionnaire, along with recommendations to improve your score</Tutorial>,
    // },
  ];
  return <CustomJoyRide steps={STEPS} />;
};

export default AIMSResultsTutorial;