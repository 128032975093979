import React from 'react';
import { ConfigurableOnRequest } from '../../../components'

const IEEEScreen = () => {

  return (
  <>
    <ConfigurableOnRequest />
  </>
  )
}

export default IEEEScreen