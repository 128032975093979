import React from "react";
import styles from "./tool.selection.screen.module.scss";
// import { Tool } from 'machine-trust-platform';
import { useHistory, useRouteMatch } from 'react-router';

const Tools = () => {
  const history = useHistory()
  const { path } = useRouteMatch()

  return (
    <>
      <div className={`${styles.container} container`}>
        <div className={`${styles.toolsText}`}>
          <h1 className={`${styles.toolsHeader}`}>Tools sandbox</h1>
          <p className={`${styles.toolsParagraph}`}>
            An offline area for you to test data outside of a project. Choose
            one of the following tools by clicking on the appropriate tile.
          </p>
        </div>

        <div className={`${styles.toolsContent}`}>
          <div className={`${styles.toolsContentLeft}`}>
            <button className={`${styles.toolsAif360}`} onClick={()=> history.push(`${path}/AIF360`)}>
              AIF360
              <p className={`${styles.toolsAif360Paragraph}`}>AIF360 is an extensible open source toolkit developed by IBM that can help examine, report and mitigate discrimination and bias in machine learning models throughout the AI application lifecycle.</p>
              <a target="_blank" rel="noopener noreferrer" href="https://aif360.mybluemix.net/"><button className={`${styles.toolsAif360Infobutton}`}>More Info</button></a> 
            </button>
          </div>
          <div className={`${styles.toolsContentRight}`}>
            <button className={`${styles.toolsFairlearn}`} onClick={()=> history.push(`${path}/FairLearnML`)}>
              FairLearn
              <p className={`${styles.toolsFairlearnParagraph}`}>Fairlearn is a Python package by Microsoft that empowers developers of artificial intelligence (AI) systems to assess their system's fairness and mitigate any observed unfairness issues. Fairlearn contains mitigation algorithms as well as metrics for model assessment.</p>
              <a target="_blank" rel="noopener noreferrer" href="https://fairlearn.org/"><button className={`${styles.toolsFairlearnInfobutton}`}>More Info</button></a> 
              </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tools;
