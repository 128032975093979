import React from "react";
import Routes from "../routes";
import {
  NavBar,
  Breadcrumbs,
  NavDrawer,
  ServiceTabs
} from "../app/features/ui/components";
import { Tutorials } from '../app/features/ui/components'
import { selectOrganization } from "../app/features/auth/auth.slice";
import { useSelector } from "../app/hooks";
// import { PoweredBy } from "../app/components";
import styles from "./layout.module.scss";

const Layout = () => {

  // Get the users organization
  const organization = useSelector(selectOrganization)
  if (!organization) throw Error("No Organization found. Please contact your NuEnergy Representative.")

  return (
    <>
      <NavBar />
      <ServiceTabs />
      <div className={`${styles.container} container-fluid`}>
        <NavDrawer />
        <main role="main" id="main" className={`${styles.main}`}>
          <div className={styles.header}>
            <Breadcrumbs />
          </div>
          <section className={styles.content}>
            <Routes />
          </section>
        </main>
      </div>
      <Tutorials />
    </>
  );
};

export default Layout;
