import React, { useEffect } from 'react';
import { useDispatch, useSelector } from '../../../../hooks';
import { useTranslation } from 'react-i18next';
import { selectBenchmarkLastFetchTime, selectBenchmarkScore, selectBenchmarkStatus, clearBenchmark, getBenchmark } from '../../slices/benchmark.slice';
import { ScorecardType } from "machine-trust-platform";
import { differenceInMinutes } from 'date-fns';
import { Spinner } from 'react-bootstrap';
import styles from "./benchmark.display.module.scss"

interface Prop {
  scorecardType: ScorecardType;
  score?: number;
}

const BenchmarkDisplay = ({ scorecardType, score }:Prop) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const benchmarkScore = useSelector(selectBenchmarkScore);
    const lastFetchTime = useSelector(selectBenchmarkLastFetchTime);
    const loading = useSelector(selectBenchmarkStatus) === "loading";
  



    const hasBenchmarkScore = lastFetchTime !== -1 && benchmarkScore;

    useEffect(()=>{
        if((!benchmarkScore && lastFetchTime === -1) || (benchmarkScore && differenceInMinutes(lastFetchTime, new Date()) >= 5)){
            dispatch(getBenchmark(scorecardType));
        }
    }, [benchmarkScore, dispatch, lastFetchTime, scorecardType])
  
  
  useEffect(() => {

    return () => {
        dispatch(clearBenchmark())
    }
    }, [dispatch])
  
    
    return (
        <>

        {/* loader shows when requesting for benchmark score once the request is successful then scores are displayed */}
      {loading && <Spinner className={styles.benchmarkSpinner} animation="border" />}
      {!loading && (
        <>
          {/* once loading is done and there are no scores then the no scores message is displayed */}
          {!hasBenchmarkScore && (
            <div className="container">
              <h3 className={styles.header}>{t('benchmark.header.label')}</h3>
                <div className={styles.noBenchmarkScore}>{t('benchmark.none.available.label')}</div>
            </div>
          )}
          {/* once loading is done and there is a score then the benchmark score is displayed */}
          {hasBenchmarkScore && (
            <div className="container">
              <h3 className={styles.header}>{t('benchmark.header.label')}</h3>
              <div className="row">
                <div className="col-6">
                  <div className={styles.organizationScore}>{score + '%'}</div>
                  {/* <span className="organization-name">{organization.name}</span> */}
                </div>
                <div className="col-6">
                  <div className={styles.organizationScore}>{benchmarkScore + '%'}</div>
                  <div>
                    <span className={styles.organizationName}>{t('benchmark.label')}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    
        </>
    )
}
export default BenchmarkDisplay;