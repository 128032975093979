import SurveyForm from "../../../components/SurveyForm"
import preAiaJSON from "./preAIAcontent.js";

const PreAIAContainer = () => { 

  return (
    <div>
      <SurveyForm 
        content={preAiaJSON} 
        Survey_KEY={'preAIAAnswers'} 
        name={"preAIA"}
        completeButton={true}
    />
    </div>
  );
};
  

export default PreAIAContainer;