/**
 * Creates a new Date with the added number of hours
 * @param {Date} date - the original date
 * @param {number} hours - the number of hours
 * @returns {Date} - the new data object with the added hours
 */
export function addHoursToDate(date: Date, hours: number): Date {
  return new Date(new Date(date).setHours(date.getHours() + hours));
}



/**
 * Gets the name of the month form the Date
 * @param {Date} date
 * @returns {any}
 */
export const getNameOfMonthFromTimeStamp = (date: Date) => {
  const month = date.getMonth();
  switch (month) {
    case 0:
      return 'January';
    case 1:
      return 'February';
    case 2:
      return 'March';
    case 3:
      return 'April';
    case 4:
      return 'May';
    case 5:
      return 'June';
    case 6:
      return 'July';
    case 7:
      return 'August';
    case 8:
      return 'September';
    case 9:
      return 'October';
    case 10:
      return 'November';
    default:
      return 'December';
  }
};

/**
 * Creates a readable label from the Date Object
 * @param {Date} date
 * @returns {any}
 */
export const createDateLabel = (date: Date) => {
  return `${date.getFullYear()}-${getNameOfMonthFromTimeStamp(date).substring(0, 3)}-${date.getDate()}`;
};

/**
 * Creates a readable Time Label from a Date object
 * @param {Date} date
 * @returns {any}
 */
export const createDateTimeLabel = (date: Date) => {
  // date = date - date.getTimezoneOffset();
  return `${getNameOfMonthFromTimeStamp(date).substring(0, 3)} ${date.getDate()} ${date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
};


export const timePast = (date: Date, ) => {

}