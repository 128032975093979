/*
 * File: Dahsboard.tutorial.jsx
 * Project: Machine Trust Platform
 * File Created: Monday, 14th June 2021 3:23:14 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 22nd June 2021 3:32:32 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React, { useCallback } from 'react';
import { Step, CallBackProps, STATUS } from 'react-joyride';
import { useHistory, useRouteMatch } from 'react-router';
import CustomJoyRide from '../CustomJoyRide';

const DashboardTutorial = () => {
  const history = useHistory()
  const { url } = useRouteMatch()

  const STEPS: Step[] = [

    {
      target: '[data-tut="service-tabs"]',
      title: "Navigating The Machine Trust Platform",
      content: <p>Don't see the content looking for? Services is where you'll find all the Machine Trust Platform has to offer. <strong>With More to come!</strong> </p>,
      disableBeacon: true,
      placement: 'bottom',
      disableScrolling: true,
    },
    // TODO: Add Tutorial Button
    {
      target: '[data-tut="ai-governance-dashboard"]',
      title: "Where are you currently?",
      content: <p>You're looking at the AI Governance Dashboard. <strong>AI Governance</strong> is one of the many services we provide on the Machine Trust Platform. This Dashboard is where you and your Organization will keep track of your AI Governance Progress.</p>,
      disableBeacon: true,
      placement: 'center',
      disableScrolling: true,
    },

    {
      target: '[data-tut="nav-drawer"]',
      title: "Navigation Drawer",
      content: 'Service Specific Navigation.',
      disableBeacon: true,
      placement: 'right',
      disableScrolling: true,
    },

    {
      target: '[data-tut="aigs-dropdown"]',
      title: "AI Governance Scorecard (AIGS).",
      content: 'Here is where you\'ll find everything AIGS Related.',
      disableBeacon: true,
      placement: 'right',
      disableScrolling: true,
    },
    {
      target: '[data-tut="aigs-questionnaire-link"]',
      title: "AI Governance Scorecard (AIGS).",
      content: "The Questionnaire",
      // disableBeacon: true,
      placement: 'right',
      disableScrolling: true,
    },
    {
      target: '[data-tut="aigs-scores-link"]',
      title: "AI Governance Scorecard (AIGS).",
      content: <p>This is where you'll manage and <strong>approve</strong> all of your AIGS results and scores.</p>,
      placement: 'right',
      disableScrolling: true,
    },
    {
      target: '[data-tut="aigs-questionnaire-link"]',
      title: "AI Governance Scorecard (AIGS).",
      content: "Lets Complete Our First AI Governance Scorecard (AIGS)",
      event: "Hover",
      // disableBeacon: true,
      placement: 'right',
      disableScrolling: true,
    },
  ];

  const joyrideCallBack = useCallback((data: CallBackProps) => {
    const { lifecycle, step, status } = data;

    // This opens the AI Governance Drop down 
    if (step.target === '[data-tut="aigs-dropdown"]' && lifecycle === 'complete') {
      const element = document.getElementById('aigs-dropdown')
      
      // Check if the drawer should be opened or not
      const shouldOpenDrawerDropdown: boolean = !(element?.getAttribute('aria-expanded') === 'true')
      if (shouldOpenDrawerDropdown) {
        element?.click()
      }
      
    } else if (step.target === '[data-tut="aigs-questionnaire-link"]' && lifecycle === 'beacon') {

    } else if (STATUS.FINISHED === status) {
      history.push(`${url}/questionnaire`)
    }

  }, [history, url])


  return (
    <CustomJoyRide
      callback={joyrideCallBack}
      steps={STEPS}
      // run={run}
      continuous
    />
  );
};

export default DashboardTutorial;