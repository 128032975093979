import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../../store";

import config from '../../../config.json';
import { getToolAPIFromID, getToolNameFromID } from "../tools.helpers";
import { FaultReport, ToolResult } from "machine-trust-platform";

export interface ToolRequestResultState {
  list: any[];
  selected?: any;
  lastFetchTime: number
  status: "idle" | "loading" | "failed";
}

// const initialUserRequestState: ToolRequestResultState = {
//   list: [] as any[],
//   selected: undefined,
//   lastFetchTime: -1,
//   status: "idle",
// }

export type RequestResultState = Record<number, ToolResult>;

const initialState: RequestResultState = {};

/* -------------------------- Async Thunk --------------------------*/


const getToolRequestResult = createAsyncThunk(
  'get/tool/request/result',
  async ({ toolId, requestId }: { toolId: number, requestId: number }, { rejectWithValue }) => {

    try {
      const response = await axios({
        url: `${config.ai_tool_uri}/${getToolAPIFromID(toolId)}/GetUserResult/${requestId}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
      })

      if (response.data.faultReport.isFault) return rejectWithValue(response.data.faultReport as FaultReport)

      const toolResult: ToolResult = { result: response.data.response, tool: getToolNameFromID(toolId) }

      return { toolResult, requestId, toolId } as {toolResult: ToolResult, requestId: number, toolId: number }

    } catch (err) {

      return rejectWithValue(err)
    
    }
  }
)


/* -------------------------- App Thunk --------------------------*/
// ....

/* -------------------------- Slice --------------------------*/
/* ********* MAJOR HACK ******************************* */
/* Assume that there will never be more than 10 toolIds */
/* ********* MAJOR HACK ******************************* */
export const toolRequestResultsSlice = createSlice({
  name: 'toolRequestResults',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getToolRequestResult.fulfilled, (state, action) => {
        state[action.payload.requestId*10+action.payload.toolId] = action.payload.toolResult
      })
  }
})


// Tool Request State Actions
// export const { } = toolRequestResultsSlice.actions;
export { getToolRequestResult };


// Selectors
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// ...
export const selectToolRequestResult = (state: RootState, requestId: number, toolId: number) => state.tools.result[requestId*10+toolId]

export default toolRequestResultsSlice.reducer;
