// import { differenceInMinutes } from 'date-fns';
import React, { useEffect } from 'react';
import Row from './Row'
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from '../../../../hooks';
import { Button } from '../../../../components';
import { getToolRequests, selectToolRequestList } from '../../slices/request.slice';

import styles from './tool.request.table.module.scss';
import { ToolRequest } from 'machine-trust-platform';
import { compareDesc } from 'date-fns';

interface Props {
  userId: string;
}

const ToolRequestTable = ({ userId }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const toolRequestList = useSelector(state => selectToolRequestList(state, userId)) || []

  const handleRefreshButton = () => dispatch(getToolRequests(userId))

  const sortRowFunc = (a: ToolRequest, b: ToolRequest) => {
    return compareDesc(new Date(a.created), new Date(b.created))
  };

  useEffect(() => {
    dispatch(getToolRequests(userId))
  }, [dispatch, userId])

  return (


    <div className={`${styles.container} container`}>

      <div className={`${styles.header}`}>

        <h4 className={styles.title}>
          {t('ai.tool.requests.label')}
        </h4>

        <div className={`${styles.controller}`}>

          <Button
            width={30}
            height={30}
            variant={'white'}
            className={styles.button}
            onClick={handleRefreshButton}
          >
            <i className="ri-refresh-line"></i>
          </Button>

        </div>

      </div>

      <div className={styles.tableContainer}>
        <table className={`${styles.table} table table-striped`}>
          <thead>
            <tr>
              <th scope='col'>
                {t('tool.label')}
              </th>
              <th scope='col'>
                {t('dataset.label')}
              </th>
              <th scope='col'>
                {t('created.label')}
              </th>
              <th scope='col'>
                {t('status.label')}
              </th>
            </tr>
          </thead>

          <tbody>
            {[...toolRequestList].sort(sortRowFunc).map(tool => (
              <Row
                key={`${tool.datasetId}-${tool.id}-${tool.processingStarted}`}
                toolRequest={tool} />

            ))}
          </tbody>
        </table>
      </div>
    </div>

  )
}

export default ToolRequestTable;