const preAiaJSON = {
	//"title": "Pre-AIA Questionnaire",
	"logoPosition": "right",
	"completedHtml": "<h3>Thank you for completing the survey!</h3>",
	"completedHtmlOnCondition": [
		{
			"expression": "{question2} = false",
			"html": "<p style='font-family:verdana'> You might not be the right person to conduct the assessment. Contact the right person within your organization to conduct the assessment.</p>"
		},
		{
			"expression": "{question3} = false",
			"html": "<p style='font-family:verdana'> You might not be the right person to conduct the assessment. Contact the right person within your organization to conduct the assessment.</p>"
		},
		{
			"expression": "{question4} = false",
			"html": "<p style='font-family:verdana'> Contact the relevant person(s) within your organization and collaborate in answering the questions.</p>"
		},
		{
			"expression": "{question4} = true",
			"html": "<p style='font-family:verdana'> You can now launch the AIA. Thank you for using AIA on our Machine Trust Platform.</p>"
		}
	],
	"pages": [
		{
			"name": "page1", 
			"elements": [
				{ 
					"type": "boolean", 
					"name": "question1", 
					"title": "Is this your first time answering the Algorithmic Impact Assessment (AIA)?" 
				}, 
				{ 
					"type": "boolean", 
					"name": "question2", 
					"isRequired": true,
					"title": "Are you directly involved or familiar with the use case (AI-related technology) that you are going to apply the AIA to?" 
				}, 
				{ 
					"type": "boolean", 
					"name": "question3", 
					"isRequired": true,
					"visibleIf": "{question2} = true", 
					"title": "Are you the team lead, or responsible person assigned by your organization to conduct the AIA for the use case you are working on?" 
				}, 
				{ 
					"type": "boolean", 
					"name": "question4", 
					"isRequired": true,
					"visibleIf": "{question3} = true", 
					"title": "Do you have access to resources and/or people with expertise in the governance directives of your organization and with knowledge of bias, explainability, privacy, transparency, procedural fairness, data governance, etc. that the AIA addresses?" 
				}]
		}]
};


export default preAiaJSON;