import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { Button } from '../../../../components'

import * as visualizations from '../../../../utils/visualizations'
import { AsterChart } from '../../../../components';

interface Props {
  data: any;
  overallScore?: number;
  requirements?: any;
  type?: 'AIGS' | 'AIMS' | 'BITRAPY';
  loading: boolean;
  scorecardUuid?: string;
  scoreUuid?: string;
}


const SelfAssessmentContent = ({ data, overallScore, requirements, type, loading, scorecardUuid, scoreUuid }: Props) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const history = useHistory()

  const colorMap = requirements ? visualizations.getColorMap(requirements) : undefined;

  const generateLinkToResults = (params?: {[key: string]: any}) => {
    const httpParams =     Object.entries({
      scorecard: scorecardUuid,
      scoreUuid: scoreUuid,
      ...params,
    }).map(([key, value]) => `${key}=${value}`)?.join('&');
    return `${path}${type === 'AIMS' ? '/' + (type as string).toLocaleLowerCase() : ''}/questionnaire/results?${httpParams}`;
  }

  return (
    <>
      <div className="scorecard-self-assessment">
        <span className="score-header">{`${type} ${t('score.label')}`}</span>
        {/* eslint-disable no-nested-ternary  */}
        {loading && <Spinner animation={'border'} />}

        {
          !loading && <>
            {data && overallScore && requirements ? (
              <>
                <div className="scorecard-self-assessment__chart">
                  <AsterChart width={200} height={200} dataset={data} overallScore={overallScore} categories={requirements} handleClick={(item: any) => history.push(generateLinkToResults({requirement: item.uuid}))} />
                </div>
                <div className="scorecard-self-assessment__legend">
                  <ul>
                    {data.map((r: any) => (
                      <li key={r.name}
                        className={`${colorMap ? colorMap[r.name][1] : ''}`}
                      >
                        <div className="r-label">
                          <div className="r-header">
                            <span onClick={() => history.push(generateLinkToResults({requirement: r.uuid}))}>{r.name}</span>
                            {
                              r.description ? 
                                <span>
                                  <OverlayTrigger
                    
                                    overlay={
                                      <Tooltip className="r-header-tooltip" id={`${r.uuid}-tooltip`}>
                                        {r.description}
                                      </Tooltip>
                                    }
                                  >
                                    {({ref, ...triggerHandler}) => <i ref={ref} {...triggerHandler} className="ri-information-fill" />}
                                  </OverlayTrigger>
                                </span>
                              : null
                            }
                          </div>
                          <span>{`${Math.round(r.score)}%`}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="scorecard-self-assessment__footer">
                    <Button onClick={() => history.push(generateLinkToResults())}>
                      {t('review.results.label')}
                    </Button>
                    {/* <Link className="btn btn-primary" to={linkToResults}>
                      {t('review.results.label')}
                    </Link> */}
                  </div>
                </div>
              </>
            ) : (
              <div className="scorecard-self-assessment__empty">{t(`please.complete.${(type as string).toLowerCase()}.message`)}</div>
            )}

          </>
        }
      </div>
    </>
  );
};


export default SelfAssessmentContent;