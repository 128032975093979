import Dashboard from "./Dashboard.screen";
import Bit from "./Bit.screen"
import Canvas from "./Canvas.screen";

const AIGovernanceScreens = {
  Dashboard,
  Bit,

  Canvas
}

export default AIGovernanceScreens;