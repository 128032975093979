import React, { useEffect } from 'react';
import { ProjectTable } from '../../../features/project/Components';
import { selectProjectListFetchTime, getAllProjects } from '../../../features/project/project.slice';
import { useSelector, useDispatch } from '../../../hooks';


const ProjectSelectionTable = () => {
  const dispatch = useDispatch()
  const lastListFetchTime = useSelector(selectProjectListFetchTime)


  useEffect(() => {
    if (lastListFetchTime === -1
      // || time since last fetch was greater than 1 minute
    ) {
      dispatch(getAllProjects())
    }
  }, [dispatch, lastListFetchTime])




  return (
    <>
      <div>
        <ProjectTable />
      </div>
    </>
  )

}

export default ProjectSelectionTable;