// import Wizard from "./Wizard";
import ResultDisplay from "./ResultDisplay";
import Wizard from './Wiz'

const AIF360 = {
  Wizard,
  ResultDisplay,
  // Wizard
}

export default AIF360