import { v1 as uuidv1 } from "uuid";
import jwt, { JwtPayload } from "jsonwebtoken";
import * as cookie from "../../utils/cookies";
import * as time from "../../utils/time";
import config from "../../config.json";

import type { AuthTokens } from "./types";

/**
 * Description
 * @returns {any}
 */
export const generateAuthState = () => {
  const uuid = uuidv1();
  const location = window.location.href;
  const authState = btoa(JSON.stringify({ uuid, location }));
  window.localStorage.authState = authState;
  return authState;
};

/**
 * Gets the cached Auth state (this is not the same as Redux state).
 * This is used during the Login process.
 * @returns {string}
 */
export const getCachedAuthState = () => {
  const { authState } = window.localStorage;
  return authState || undefined;
};

/** Cache OAuth.2.0 Token in cookies
 * @param {AuthTokens} authTokens - JWT Auth Tokens (Refresh Token is not necessary)
 */
export const cacheTokens = (authTokens: Required<AuthTokens>) => {
  const { id_token, access_token } = authTokens;
  // Get the expiry from the ID & Access Tokens
  const idTokenExp =
    ((jwt.decode(id_token) as JwtPayload).exp as number) * 1000;

  cookie.setCookie("id_token", id_token, new Date(idTokenExp));
  cookie.setCookie("access_token", access_token, new Date(idTokenExp));
};

const REFRESH_TOKEN_EXPIRY_HOURS = 24;
/**
 * Caches the refresh token for 24 hours.
 * Be aware that if there are any changes to the user pools App client settings and the expiry
 * is less than 24hours this will cause an error.
 * @param {string} refresh_token
 */
export const cacheRefreshToken = (refresh_token: string) => {
  const refreshTokenExp = time.addHoursToDate(
    new Date(Date.now()),
    REFRESH_TOKEN_EXPIRY_HOURS
  );
  cookie.setCookie("refresh_token", refresh_token, refreshTokenExp);
};

/**
 * Retrieves the tokens that have been stored in the cookies
 * @returns {AuthTokens} - These are the AuthTokens but the may be undefined
 */
export const getCachedTokens = () => {
  const { id_token, access_token, refresh_token } = cookie.getCookies();
  return { id_token, access_token, refresh_token } as AuthTokens;
};

export const clearCachedTokens = () => {
  cookie.removeCookie("refresh_token");
  cookie.removeCookie("id_token");
  cookie.removeCookie("access_token");
  cookie.removeCookie("cognito")
  cookie.removeCookie("XSRF-TOKEN")

  window.localStorage.removeItem('authState')
  delete window.localStorage.authTokens;

};

export const clearIdTokens = () => {
  // Clear out ID tokens to force refresh
  cookie.removeCookie("id_token");
  cookie.removeCookie("access_token");
};

/** _redirectToIDP
 * Redirects the user to the Identity Provider.
 * (The login page)
 */
export const _redirectToIDP = () => {
  let loc: any =
    `https://${config.auth_domain}/oauth2/authorize?` +
    `client_id=${process.env.REACT_APP_CLIENT_ID}&` +
    `redirect_uri=${window.location.protocol}//${window.location.host}&` +
    `response_type=${process.env.REACT_APP_RESPONSE_TYPE}&` +
    `scope=${process.env.REACT_APP_SCOPE}&` +
    `state=${generateAuthState()}`;
  window.location = loc;
};

export const _redirectLogoutToIDP = () => {
  window.location.href = `https://${config.auth_domain}/logout?` +
    `response_type=code&` +
    `client_id=${process.env.REACT_APP_CLIENT_ID}&` +
    `redirect_uri=${window.location.protocol}//${window.location.host}&` +
    `scope=${process.env.REACT_APP_SCOPE}&` +
    `state=${generateAuthState()}`;
};

/**
 * Validate the Json Web Token
 * @param {any} token
 * @returns {any}
 */
export const _validateToken = async (token: string) => {
  const jwksClient = require("jwks-client");
  const client = jwksClient({
    strictSsl: false,
    cache: true,
    rateLimit: true,
    jwksUri: process.env.REACT_APP_JWKS_URI,
  });

  return new Promise((resolve, reject) => {
    if (token) {
      jwt.verify(
        token,
        (header, callback) =>
          client.getSigningKey(header.kid, (_: any, key: any) =>
            callback(null, key.publicKey || key.rsaPublicKey)
          ),
        (error, decoded) => {
          if (error) {
            reject(error);
          } else {
            resolve(decoded);
          }
        }
      );
    } else {
      reject("Null Token");
    }
  });
};

/**
 * Validates both Id & Access Token
 * @param authTokens
 */
export const _validateTokens = async (authTokens: Required<AuthTokens>) => {
  await _validateToken(authTokens.id_token);
  await _validateToken(authTokens.access_token);
};
