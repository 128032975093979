import React from "react";

import { useDispatch, useSelector } from "../../../../hooks";
import { logout, selectOrganization, selectUser } from "../../../auth/auth.slice";

import logo from "../../../../assets/images/logo-nuenergy.png";
import MTPlogo from "../../../../assets/images/MTPlogo.png";

import styles from "./navbar.module.scss";
import { isValidLogoUrl } from "../../../../utils/urlUtils";
import { turnOnTutorial } from "../../slices/tutorial.slice";

const NavBar = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const organization = useSelector(selectOrganization)
  const hasLogo = isValidLogoUrl(organization?.logo);

  return (
    <nav className={styles.navbar}>
      <header className={styles.container}>
        <a href="/" className={`navbar-brand ${styles.nuEnergyBrand}`}>
          {<img src={logo} alt="NuEnergy" />}
        </a>

        <div className={`${styles.middle}`}>
          <span>
            {<img src={MTPlogo} alt= "Machine Trust Platform"/>}
          </span>
        </div>
        <div className={` ${styles.navItems}`}>
          <div className={`nav-item ${styles.btnContainer}`} >
            <button
              onClick={() => dispatch(turnOnTutorial())}
            >
              <i className={`ri-question-line ${styles.navHelpBtn}`} />
            </button>
            <button onClick={() => dispatch(logout())}>
              <i className={`ri-logout-box-r-line ${styles.navLogoutBtn}`} />
            </button>

          </div>

          <div className={styles.userContainer}>
            <div className={styles.userIcon}>
              <span>{`${user?.givenName.charAt(0).toUpperCase()}.${user?.familyName.charAt(0).toUpperCase()}`}</span>
            </div>
          </div>

          {hasLogo &&
            <div className={`nav-item nav-brand`}>
              <div className={`${styles.organizationLogo}`}>
                <img className={styles.logo} src={organization?.logo} alt={organization?.name} />
              </div>
            </div>
          }
        </div>
      </header>
    </nav>
  );
};

export default NavBar;
