import React, { useState } from "react";
import Button from "../../../../components/Button";
import { addProject } from "../../project.slice";
import { useDispatch } from "../../../../hooks";
import styles from "./create.form.module.scss";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
const CreateForm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const [projectData, setProjectData] = useState({ name: "", description: "" });

  const handleSubmit = () => dispatch(addProject(projectData))

  const createDisabled = (!projectData.name) as boolean || projectData.name.trim() === ""
  // const [isCreated, setIsCreated] = useState(false);

  // TODO: Change this to a popup form and that uses a text field
  // and also properly handles the error of duplicate project names.

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <input
          id="projectName"
          className={styles.nameInput}
          value={projectData.name}
          placeholder="New project 1"
          aria-label="Project Name"
          onChange={(e) => setProjectData({ ...projectData, name: e.target.value })}
        />
        <Form.Control
          id="projectDescription"
          as="textarea"
          rows={2}
          value={projectData.description}
          maxLength={512}
          placeholder="Description"
          aria-label="Project Description"
          onChange={(e) => setProjectData({ ...projectData, description: e.target.value })}
        />
      </div>
      
      <Button
        variant='green'
        className={`col ${styles.submitButton}`}
        disabled={createDisabled}
        onClick={handleSubmit}
      >{t('create.label')}</Button>
      
    </div>
  );
};

export default CreateForm;
