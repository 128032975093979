import axios from "axios";
import { AIF360Input, AIF360InputRequest, FairlearnMLInput, FairlearnMLInputRequest } from "machine-trust-platform";
import { uploadDatasetFile, uploadModelFile } from "../../utils/api";
import config from '../../config.json'


export const aif360Request = async (userId: string, input: AIF360Input, datasetFile: File) => {

  const datasetId = await uploadDatasetFile(userId, datasetFile);

  const request: AIF360InputRequest = {
    userId: userId,
    datasetId: datasetId,
    dependantAttributes: [input.dependentFeature as string],
    favorableLabels: [input.favorableLabel as number],
    unfavorableLabels: [input.unfavorableLabel as number],

    protectedAttributes: input.protectedFeatures as string[],
    privilegedGroups: input.privilegedGroups as any[],
    unprivilegedGroups: input.unprivilegedGroups as any[],

    dropAttributes: input.dropFeatures as string[],
    mitigate: false

  }

  const response = await axios({
    url: `${config.ai_tool_uri}/AIF360/AddUserRequest`,
    data: request,
    method: "POST",
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
    },
  })

  if (response.data.isFault) throw new Error(response.data.faultReport.faultDescription)

  return response.data.response
  
}

export const fairlearnMLRequest = async (userId: string, input: FairlearnMLInput, datasetFile: File, modelFile: File) => {
  // Upload the Dataset and Model Files to get their Ids
  const res = await Promise.all([uploadDatasetFile(userId, datasetFile as File), uploadModelFile(userId, modelFile as File)])
  const [datasetFileId, modelFileId] = res

  const req: FairlearnMLInputRequest = {
    userId: userId,
    datasetId: datasetFileId,
    modelId: modelFileId,
    dependantFeature: input.dependentFeature as string,
    sensitiveFeatures: input.protectedFeatures as string[],
    dropAttributes: input.dropFeatures as string[]
  }

  const response = await axios({
    url: `${config.ai_tool_uri}/ML/AddUserRequest`,
    data: req,
    method: "POST",
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
    }
  })

  if (response.data.isFault) throw new Error(response.data.faultReport.faultDescription)

  return response.data.response
}