import { Tier } from "machine-trust-platform";
import { ORGANIZATION, MTPPath } from "../../../../../constants";

const { TIER } = ORGANIZATION

// Records for all The Service menu Links
interface ServiceLink{
  icon?:string;
  label?:string;
  title?:string;
  path: MTPPath;
  tier: Tier;
  width?: number | string;
}

export const SERVICES_LINK_LIST :ServiceLink[]= [
  {
    icon:"ri-home-2-fill",
    title: "home.title.label",
    path: MTPPath.HOME,
    tier: TIER.ALL,
    width: "7.5vw", 
  },
  {
    label: "organization.label",
    title: "service.drawer.aigs.title.label",
    path: MTPPath.AI_GOVERNANCE,
    tier: TIER.ALL,
  },
  {
    label: "projects.label",
    title: "service.drawer.projects.title.label",
    path: MTPPath.PROJECTS,
    tier: TIER.ALL,
  },
  {
    label: "tools.sandbox.label",
    title: "service.drawer.tools.title.label",
    path: MTPPath.TOOLS,
    tier: TIER.PLATINUM,
  },
  {
    label: "standards.label",
    title: "service.drawer.standards.title.label",
    path: MTPPath.STANDARDS,
    tier: TIER.ALL,
  },
  {
    label: "add.ons.label",
    // title: "service.drawer.mti.and.wikis.hover.label",
    path: MTPPath.ADDON,
    tier: TIER.ALL,
  },
];