import { Project } from 'machine-trust-platform';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';
// import { selectSelectedProject } from '../../../../app/features/project/project.slice';
// import { getScorecard, setSelectedScorecard } from '../../../../app/features/scorecard/slices/scorecard.slice';
// import { useDispatch, useSelector } from '../../../../app/hooks';
import Screens from '../../../../app/screens';

import QuestionnaireRoute from '../../../Questionnaire.route';
interface Props {
  project: Project
}

const AIMS = ({ project }: Props) => {
  const { url } = useRouteMatch();

  const scorecardUuid = project.scorecardUuid as string


  return (
    <Switch>
      <Route  exact path={url}>
        <Redirect to={`${url}/questionnaire`} />
      </Route>
      
      <Route path={`${url}/questionnaire`} component={QuestionnaireRoute} >
        <QuestionnaireRoute scorecardUuid={scorecardUuid} />
      </Route>
      <Route path={`${url}/scores`}>
        <Screens.Scorecard.Scores path={url}/>
      </Route>
    </Switch>
  );
};

export default AIMS;