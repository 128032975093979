/*
 * File: indes.tsx
 * Project: Machine Trust Platform
 * File Created: Saturday, 28th August 2021 4:43:08 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 31st August 2021 3:11:13 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from "react";
import {
  Route,
  Switch,
  // useLocation
} from "react-router-dom";
import {
  useSpring,
  a,
  // config
} from "@react-spring/web";

import {
  useSelector,
  // useDispatch
} from "../../../../hooks";
import {
  selectDrawerState,
} from "../../slices/navigation.slice";

import Drawer from '../../../../../routes/Drawers'

import styles from "./drawer.module.scss";


const NavDrawer = () => {


  // Grab the Drawer State to determine if the drawer should be open or not
  const isOpen = useSelector(selectDrawerState) === "open";

  // Controls the Animation 
  const { opacity, minWidth, display } = useSpring({
    from: { minWidth: '302px', opacity: 1, display: 'flex' },
    to: async (next) => {
      await next(isOpen ? { display: "flex" } : { opacity: 0 })
      await next(isOpen ? { minWidth: '302px' } : { minWidth: '16px' /*'60px' */});
      await next(isOpen ? { opacity: 1 } : { display: "none" });
    },
    config: {
      duration: isOpen ? 500 : 150
    }
    
  });

  return (
    <a.div
      style={{ minWidth }}
      className={`${styles.navDrawer}`}
      data-tut="nav-drawer"
    >
      <a.nav
        style={{ minWidth }}
        className={styles.content}
      >

        <a.div style={{ opacity, display }}
        /* The Drawer Buttons and Links */
        >
          <div className={styles.multiLinkContainer}>
            <Switch>
              {Object.entries(Drawer).map(([key, value]) => (
                <Route key={key} path={key} component={value} />
              ))}
            </Switch>
          </div>
        </a.div>
      </a.nav>
    </a.div>
  );
};

export default NavDrawer;
