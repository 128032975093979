import React, { useEffect } from 'react';
import { differenceInMinutes } from 'date-fns'
import { useDispatch, useSelector } from '../../../hooks';
import { getAllTools, selectToolLastFetchTime, selectToolList, selectToolStatus } from '../../../features/tools/slices/tools.slice';
import ToolCard from '../../../features/tools/components/ToolCard';

const ToolsLanding = () => {
  const dispatch = useDispatch()

  const isLoading = useSelector(selectToolStatus) === 'loading'
  const lastFetchTime = useSelector(selectToolLastFetchTime)
  const toolList = useSelector(selectToolList)

  useEffect(() => {
    if (lastFetchTime === -1 || differenceInMinutes(new Date(lastFetchTime), new Date()) >= 5) {
      dispatch(getAllTools())
    }
  }, [dispatch, lastFetchTime])

  return (
    <div className={`container-fluid`}>
      <div className="row">
        {!isLoading && toolList.map(t => <ToolCard tool={t} />)}
        {isLoading && "loading"}
      </div>


    </div>
  )
}

export default ToolsLanding;