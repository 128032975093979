import { AIF360Result } from 'machine-trust-platform';
import React from 'react';
// import ReactTooltip from 'react-tooltip';
// import { v4 } from 'uuid'

import styles from './result.display.module.scss';

interface Props {
  result?: AIF360Result;
  requestId: number;
}

const ResultDisplay = ({ result, requestId }: Props) => {

  const hasResults = result && result.result !== null
  const rr = result && result.result


  return (<>
    {hasResults && <div className={`${styles.container} container`}>
      <div className={`row ${styles.toolsCol}`}>
        <span className={`col`}>
          Number of Negatives:
        </span>
        <span className={`col ${styles.toolsScoreCol}`}>
          {rr && rr.numNegatives !== undefined ? `${rr.numNegatives}` : "Error"}
        </span>        
        <span className={`${styles.toolsDefCol}`}>
          Number of instances of dependent feature binary label 0
        </span>
      </div>

      <div className={`row`}>
        <span className={`col`}>
          Number of Positive:
        </span>
        <span className={`col ${styles.toolsScoreCol}`}>
          {rr && rr.numPositives !== undefined? `${rr.numPositives}` : "Error"}
        </span>
        <span className={` ${styles.toolsDefCol}`}>
          Number of instances of dependent feature binary label 1
        </span>
      </div>

      <div className={`row`}>
        <span className={`col`}>
          Statistical Parity Difference:
        </span>
        <span className={`col ${styles.toolsScoreCol}`}>
          {rr && rr.statisticalParityDifference !== undefined ? `${Math.round(rr.statisticalParityDifference)}%` : "Error"}
        </span>
        <span className={` ${styles.toolsDefCol}`}>
        The difference of the rate of favourable outcomes received by the unprivileged group vs the privileged group (generally a negative value indicates the unprivileged group is at a disadvantage) 
        i.e. 𝑃𝑟(𝑌̂ =1|𝐷=unprivileged)−𝑃𝑟(𝑌̂ =1|𝐷=privileged)
        </span>
      </div>

      <div className={`row`}>
        <span className={`col`}>
          Disparate Impact:
        </span>
        <span className={`col ${styles.toolsScoreCol}`}>
          {rr && rr.disparateImpact !== undefined ? `${Math.round(rr.disparateImpact)}%` : "Error"}
        </span>
        <span className={` ${styles.toolsDefCol}`}>
        The ratio of favourable outcome for the unprivileged group to that of the privileged group
        i.e. 𝑃𝑟(𝑌̂ =1|𝐷=unprivileged) / 𝑃𝑟(𝑌̂ =1|𝐷=privileged)
        </span>
      </div>

      <div className={`row`}>
        <span className={`col`}>
          Smoothed Empirical Differential Fairness:
        </span>
        <span className={`col ${styles.toolsScoreCol}`}>
          {rr && rr.smoothedEmpiricalDifferentialFairness !== undefined ? `${Math.round(rr.smoothedEmpiricalDifferentialFairness)}%` : "Error"}
        </span>
      </div>

    </div>}
    {!hasResults && <div className={styles.error}>{result && result.error ? result.error : "An Error Occurred"}</div>}
  </>
  )
}

export default ResultDisplay;
