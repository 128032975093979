import { createAsyncThunk, createSlice, isAnyOf, PayloadAction, } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { client } from "../../../utils/api";

import type { Project, Score, Status } from "machine-trust-platform";
import { Method } from "axios";
import { setOrganization, login } from "../../auth/auth.slice";
import { SCORECARD } from "../../../../constants";
import { addProject, getAllProjects, getProject } from "../../project/project.slice";

interface ScoreState {
    list?: Score[];
    selected?: Score;
    lastFetchTime: number;
    userLastModTime?: number;
    status: Status;
}
// record with key of ScorecardUUid where string is
type State = Record<string, ScoreState>;

// This is used when initializing a score state for a scorecard
const initialScorecardScoreState = {
  list: [],
  selected: undefined,
  lastFetchTime: -1,
  userLastModTime: -1,
  status: "loading" as Status
}

const initialState: State = {};

/* -------------------------- Async Thunk --------------------------*/

const getScore = createAsyncThunk( 
    "get/score",
  async (scoreUuid: string, thunkApi) => {
    const idToken = (thunkApi.getState() as RootState).auth.tokens
        ?.id_token as string;

    const options = {
        config: {
            method: "GET" as Method,
    },
  };

   try {
    const response = await client(`/score/${scoreUuid}`, idToken, options);
    return response.data;

  } catch (error) {
    thunkApi.rejectWithValue(error);
  }
});

const getAllScorecardScore = createAsyncThunk(
  "get/AllScorecardScore",
  async (scorecardUuid:string, thunkApi) => {
    const idToken = (thunkApi.getState() as RootState).auth.tokens
      ?.id_token as string;
       //await thunkApi.dispatch(prepScoreState(scorecardUuid))
    try{
        //using scorecardUuid get list of scores  
      const response = await client(`/scorecard/${scorecardUuid}/scores/`, idToken);
      // This is the payload
      return { scorecardUuid: scorecardUuid, list: response.data } as { scorecardUuid: string, list: Score[] };
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  }
);

interface UpdateScoreArg{
  score: Score;
  scorecardUuid?: string;
}

const updateScorecardScore = createAsyncThunk(
  "update/ScorecardScore",
  async ({ scorecardUuid, score }:UpdateScoreArg, thunkApi) => {
    const idToken = (thunkApi.getState() as RootState).auth.tokens
      ?.id_token as string;
    try{
      const options  = { 
        data: score

      }
      const response = await client(`/scorecard/${scorecardUuid}/scores/${score.uuid}`, idToken, options);
      // This is the payload
      return { scorecardUuid: scorecardUuid, score: response.data } as { scorecardUuid: string, score: Score };
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  }
);

interface SubmitScoreArg{
  scorecardUuid: string;
  answers: any;
  isDraft: boolean;
}

const submitScorecardScore = createAsyncThunk(
  "submit/ScorecardScore",
  async ({scorecardUuid, answers, isDraft=false}:SubmitScoreArg, thunkApi) => {
    const idToken = (thunkApi.getState() as RootState).auth.tokens
      ?.id_token as string;
    
    const email = (thunkApi.getState() as RootState).auth.user?.email;

    try{
      const options  = { 
        data: { answers, draft: isDraft, answeredBy: email }, //or can be data:{score:newScore}
        method: 'POST'
      }
        //using scorecardUuid submit list of scores  
      const response = await client(`/scorecard/${scorecardUuid}/scores/`, idToken, options);
      // This is the payload
      return { scorecardUuid: scorecardUuid, score: response.data } as { scorecardUuid: string, score: Score };
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  }
);

interface DeleteScoreArg{
  scoreUuid: string;
  scorecardUuid: string;

}
const deleteScorecardScore = createAsyncThunk(
  "delete/ScorecardScore",
  async ({ scorecardUuid, scoreUuid }: DeleteScoreArg, thunkApi) => { // TODO: Archit Do not copy and paste everything
    const idToken = (thunkApi.getState() as RootState).auth.tokens
      ?.id_token as string;
      try{ 
        const options  = { 
          config: {
            method: 'delete' as Method
          }
        }
        await client(`/scorecard/${scorecardUuid}/scores/${scoreUuid}`, idToken, options);
        // This is the payload
        return { scorecardUuid, scoreUuid } as { scorecardUuid: string, scoreUuid: string };
        
      } catch (error) {
        thunkApi.rejectWithValue(error);
      }
    }
);

/* -------------------------- App Thunk --------------------------*/

/* -------------------------- Slice --------------------------*/
export const scoreSlice = createSlice({
    name: "ScoreState",
    initialState,
    reducers:{
        updateStatus:(state, action)=>{
        state.status = action.payload
      },
        setSelectedScorecardScore: (state, action: PayloadAction<{scorecardUuid: string,  score?: Score}>) =>{
          const { scorecardUuid, score } = action.payload;
          state[scorecardUuid].selected = score;
      },

    },
    extraReducers: (builder) => {
      builder
        /*-------------- Get All score --------------  */
        .addCase(getAllScorecardScore.fulfilled, (state, action) => {
          const { list, scorecardUuid } = action.payload || {}
          if (list && scorecardUuid) {
            state[scorecardUuid].list = list.sort((a, b) => new Date(b.modified).getTime() - new Date(a.modified).getTime());
            state[scorecardUuid].status = "idle";
            state[scorecardUuid].lastFetchTime = (new Date()).getTime();
          }
        })
        
        /*-------------- Submit scorecard score --------------  */
        .addCase(submitScorecardScore.fulfilled, (state, action) => {
          const { score, scorecardUuid } = action.payload || {};
          if (scorecardUuid && score) {
            state[scorecardUuid].list =
              state[scorecardUuid].list && (state[scorecardUuid].list as Score[]).length > 0
                ?
                [score as Score, ...(state[scorecardUuid].list as Score[])]
                :
                [score as Score];
            state[scorecardUuid].lastFetchTime = (new Date()).getTime();
            state[scorecardUuid].status = "idle";
            state[scorecardUuid].selected = score;
          }
        })

        .addCase(deleteScorecardScore.fulfilled,(state, action)=>{
          if(action.payload?.scorecardUuid){
            const { scorecardUuid, scoreUuid } = action.payload || {};
              state[scorecardUuid].list = state[scorecardUuid].list?.filter((s) => s.uuid !== (scoreUuid as string));
              state[scorecardUuid].lastFetchTime = (new Date()).getTime(); 
              state[scorecardUuid].status = "idle";
              state[scorecardUuid].selected = undefined;
            } 
      })

      .addCase(updateScorecardScore.fulfilled,(state, action)=>{
        if(action.payload?.scorecardUuid){
          const { score } = action.payload;
            state[action.payload?.scorecardUuid].list = state[action.payload?.scorecardUuid].list ? state[action.payload?.scorecardUuid].list?.map((s) => (s.uuid === (score as Score).uuid ? (score as Score) : s)).sort((a, b) => new Date(b.modified).getTime() - new Date(a.modified).getTime()) : [score as Score];
            state[action.payload?.scorecardUuid].lastFetchTime = (new Date()).getTime(); 
            state[action.payload?.scorecardUuid].status = "idle";
            state[action.payload?.scorecardUuid].selected = score;
          } 
      })

        .addMatcher(isAnyOf(getProject.fulfilled, addProject.fulfilled), (state, action: PayloadAction<Project>) => {
          const project = action.payload
          state[project.scorecardUuid as string] = initialScorecardScoreState
        })
        
        .addMatcher(isAnyOf(getAllProjects.fulfilled), (state, action: PayloadAction<Project[]>) => {
          const projectList = action.payload || []
          if (projectList.length > 0) {
            for (let project of projectList) {
                state[project.scorecardUuid as string] = initialScorecardScoreState
            }
          }
        })
        .addMatcher(isAnyOf(setOrganization, login), (state, action) =>{
          const { scorecardUuid } = action.payload.organization
          if(!state[scorecardUuid] || (state[scorecardUuid] && !state[scorecardUuid].status)){
            state[scorecardUuid] = initialScorecardScoreState
          }
          
        })
        
    },
})


// Scorecard Score State Actions
export const { updateStatus, setSelectedScorecardScore } = scoreSlice.actions;
export { getScore, getAllScorecardScore, submitScorecardScore, updateScorecardScore, deleteScorecardScore };
  

// Selectors
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSelectedScorecard = (state: RootState, scorecardUuid: string) => state.scorecard.score[scorecardUuid]
export const selectScorecardScoreList = (state: RootState, scorecardUuid?: string) => scorecardUuid? state.scorecard.score[scorecardUuid].list : undefined
export const selectScorecardScoreLastFetchTime = (state: RootState, scorecardUuid?: string) => scorecardUuid? state.scorecard.score[scorecardUuid].lastFetchTime : -1
export const selectScorecardScoreStatus = (state: RootState, scorecardUuid: string) => scorecardUuid? state.scorecard.score[scorecardUuid].status : 'loading'

// Selectors for Draft Scores Only
export const selectSelectedDraft = (state: RootState, scorecardUuid: string) => state.scorecard.score[scorecardUuid].selected?.state === SCORECARD.SCORE.STATE.DRAFT ? state.scorecard.score[scorecardUuid].selected : undefined
export const selectScorecardScoreDraftList = (state: RootState, scorecardUuid: string) => state.scorecard.score[scorecardUuid].list?.filter((s) => s.state === SCORECARD.SCORE.STATE.DRAFT);

export default scoreSlice.reducer;









