/*
 * File: Questionnaire.tutorial.tsx
 * Project: Machine Trust Platform
 * File Created: Thursday, 22nd July 2021 11:33:10 am
 * Author: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Last Modified: Thursday, 26th August 2021 3:53:15 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React from 'react';
// import { useTranslation } from 'react-i18next';
import { Step }  from 'react-joyride';
import CustomJoyRide from '../CustomJoyRide';

const CanvasTutorial = () => {
  // const { t } = useTranslation();
  const STEPS: Step[] = [
    // {
    //   target: '[data-tut="canvas"]',
    //   title: `${t('canvas.questionnaire.tutorial.label')}`,
    //   content: <p>{t('canvas.questionnaire.tutorial.step.1')}</p>,
    // },
    // {
    //   target: '[data-tut="save-canvas"]',
    //   title: "Save PDF",
    //   content: <p>Once all required questions have been filled out you may save the Canvas as a PDF </p>,
    // },
  ];
  return <CustomJoyRide steps={STEPS} />;
};

export default CanvasTutorial;