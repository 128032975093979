import * as d3 from "d3";

export const drawChart = (height: number, width: number, data: { value: number; color: string; name: string }[]) => {
  d3.select('#aia-bar-chart').selectAll('svg').remove();
  const svg = d3.select('#aia-bar-chart').append('svg').attr('width', width).attr('height', height);

  const defs = svg.append('defs');

  // black drop shadow
  const filter = defs.append('filter').attr('width', '200%').attr('height', '140%').attr('id', 'drop-shadow');

  filter.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', 2).attr('result', 'blur');
  filter.append('feOffset').attr('in', 'blur').attr('dx', 2).attr('dy', 2).attr('result', 'offsetBlur');
  filter.append('feFlood').attr('in', 'offsetBlur').attr('flood-color', '#3d3d3d').attr('flood-opacity', '0.5').attr('result', 'offsetColor');
  filter.append('feComposite').attr('in', 'offsetColor').attr('in2', 'offsetBlur').attr('operator', 'in').attr('result', 'offsetBlur');

  const feMerge = filter.append('feMerge');

  feMerge.append('feMergeNode').attr('in', 'offsetBlur');
  feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

  const yScale = d3.scaleLinear().range([height, 3]).domain([0, 100]);

  const selection = svg.selectAll('rect').data(data);

  selection
    .enter()
    .append('rect')
    .attr('class', 'aia-bar-chart-bar')
    .attr('rx', 5) // border radius
    .attr('ry', 5)
    .attr('x', (d: any, i: number) => i * (width / 3 + 1))
    .attr('y', (d: { value: number }) => yScale(d.value + 1))
    .attr('width', width / 4)
    .attr('height', (d: { value: number }) => height - yScale(d.value + 1))
    .attr('fill', (d: { color: any }) => d.color) // color
    .style('filter', 'url(#drop-shadow)');
  // .attr("filter", "url(#md-shadow)")
  // .style("filter", "url(#md-shadow)");

  svg
    .selectAll('text')
    .data(data)
    .enter()
    .append('text')
    .attr('x', (d: any, i: number) => i * (width / 3 + 1) + 10)
    .attr('y', (d: { value: any }) => yScale(d.value) - 5)
    .text((d: { value: any }) => String(d.value))
    .attr('text-anchor', 'middle')
    .attr('font-size', '15px')
    .style('fill', (d: { color: any }) => d.color);

  // selection
  //   .exit()
  //   .attr("y", (d) => height)
  //   .attr("height", 0)
  //   .transition().duration(300)
  // .remove()
};


export const levelAlert = (lvl: any) => {
  switch (lvl) {
    case 1: // alert-success
      return 'aia-level-one';

    case 2: // alert-info
      return 'aia-level-two';

    case 3: // alert-warning
      return 'aia-level-three';

    case 4: // alert-danger
      return 'aia-level-four';

    default:
      return '';
  }
};