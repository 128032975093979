import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { a, useSpring } from "@react-spring/web";
import ReactTooltip from 'react-tooltip'
import Collapsible from "../Collapsible";
import _uniqueId from 'lodash/uniqueId';
import type { Tier } from "machine-trust-platform";

import styles from "./drawer.module.scss";
import { TierCheckWrapper } from "../../features/auth/components";

interface Props {
  tier: Tier;
  id?: string;
  label: string;
  title?: string;
  dataTut?: string;
  children: React.ReactNode
}

const DrawerDropdown = ({ id, tier, label, title, dataTut, children }: Props) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);


  // Rotates the icon when the open boolean changes
  const spring = useSpring<any>({
    display: "inline-block",
    backfaceVisibility: "hidden",
    transform: toggle ? `rotate(180deg)` : `rotate(0deg)`,
  });

  const tooltipId = _uniqueId('drawer-dropdown-')

  return (
    <TierCheckWrapper tier={tier}>
      <div className={
        `${styles.linkDropdownContainer} ${toggle? styles.selected : ""} ldc-dropdown`}
        // onClick={() => setToggle(!toggle)}
      >
        <div
          id={id}
          // Tutorial
          data-tut={dataTut}

          aria-expanded={toggle}

          // Tooltip
          data-tip={`${title ? t(title) : title}`}
          data-for={tooltipId}
          data-place='right'
          data-multiline={true}
          data-delay-show={500}
          
          className={`${styles.linkDropdownLabel} ${toggle ? styles.selected : ""} `}
          onClick={() => setToggle(!toggle)}
          
        >
          <span>{t(label)}</span>
          <a.div style={spring}>
            <i className="ri-arrow-down-s-fill ri-lg" />
          </a.div>
        </div>

        <Collapsible toggle={toggle}>
          {children}
        </Collapsible>
      </div>
      {
        // Only Show the Tooltip if there is a title set
        title && <ReactTooltip id={tooltipId} effect="solid" type="dark" />
      }
    </TierCheckWrapper>

  );
};

export default DrawerDropdown;
