import { TFunctionKeys } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation, } from 'react-i18next';
import { Button } from '..';

import styles from './popup.module.scss';


// TODO: Document how to use

type MessageCodes = TFunctionKeys | TFunctionKeys[]
export interface ConfirmActionConfig {
  messageCodes?: MessageCodes[],
  actionPrincipal?: string,
  actionButtonLabelCode?: string,
  actionButtonClass?: string,
  action: () => void,
}


interface Props {
  config?: ConfirmActionConfig,
  hideCancel?: boolean,
}


const ConfirmAction = (props: Props) => {
  const { config, hideCancel = false } = props;
  const { t } = useTranslation();
  const [configState, setConfigState] = useState<ConfirmActionConfig | undefined>(config);

  useEffect(() => {
    setConfigState(config);
  }, [config]);


  return (
    <div className={`${styles.container} ${configState ? '' : 'd-none'}`}>
      <div className={`${styles.card} card`}>
        <div className={`${styles.cardBody} card-body`}>
          {configState && configState.actionPrincipal && <span className={styles.actionPrincipal}>{t(configState.actionPrincipal)}</span>}
          {/* @ts-ignore */}
          {configState && configState.messageCodes && configState.messageCodes.map((emc: any) => <p key={JSON.stringify(emc)}>{(emc && typeof emc[Symbol.iterator] === 'function') ? t(...emc) : t(emc)}</p>)}
        </div>
        <div className={`${styles.cardFooter} card-footer`}>
          {hideCancel ? null : (
            <Button
              variant='secondary'
              className={`${configState && configState.action instanceof Function ? '' : 'd-none'}`}
              onClick={() => setConfigState(undefined)}>
              {t('cancel.label')}
            </Button>
          )}
          <Button
            
            className={`${configState && configState.actionButtonClass ? configState.actionButtonClass : ''}`}
            onClick={(e) => {
              if (configState && configState.action instanceof Function) configState.action();
              setConfigState(undefined);
              e.preventDefault();
            }}>
            {configState && configState.actionButtonLabelCode ? t(configState.actionButtonLabelCode) : t('continue.label')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAction;
