import React from 'react';


import styles from './section.module.scss';

interface Props {
  title?: string;
  description?: string | React.ReactNode;
  children: React.ReactNode;
}

const Section = ({ title, description, children }: Props) => {

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <h2>{title}</h2>
      </div>
      <div className={styles.description}>
        {description}
      </div>
      <div className={styles.content}>
        {children}
      </div>
    </section>
  )
}

export default Section