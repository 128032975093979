/**
 * File: index.ts
 * Project: Machine Trust Platform
 * File Created: Friday, 14th May 2021 11:36:40 am
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Thursday, 20th May 2021 1:24:49 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai>)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React, { useState, useEffect } from 'react';
import { Spinner } from '../../../../components';
import { useDispatch, useSelector } from '../../../../hooks';
import { flushCachedAnswers, getAnswers } from '../../scorecard.helper';
import buildScorecardSurvey from '../../../../utils/surveyBuilder';

import SurveyContainer from './SurveyContainer';

import SurveyControlButtons from './SurveyControlButton';

import type { Scorecard, Requirement, Question, Score } from 'machine-trust-platform';
import QuestionnaireWrapper from '../../../../components/QuestionnaireWrapper';
import { selectSelectedDraft, setSelectedScorecardScore } from '../../slices/score.slice';
// import DownloadPDF from '../../../../components/DownloadPDFButton';

interface Props {
  title: string;
  scorecard: Scorecard;
  isLoading?: boolean
  requirementList?: Requirement[];
  questionList?: Question[];
}

const ScorecardQuestionnaire = ({ title = '', scorecard, isLoading = false, requirementList = undefined, questionList = undefined }: Props) => {
  // const { t } = useTranslation();
  const dispatch = useDispatch();

  // Json object containing the Questionnaire Content
  const [json, setJson] = useState<any>(undefined);

  const [_isLoading, setIsLoading] = useState<boolean>(true)

  // Boolean Value to monitor weather a question has been answered or not (used for the top control buttons)
  const [hasAnswers, setHasAnswers] = useState(getAnswers(scorecard ? scorecard.uuid : undefined) !== undefined);
  const [currentPage, setCurrentPage] = useState<number>(0); // Tracks the current page of the survey so that the page does not reset on renders

  const selectedDraft: Score | undefined = useSelector((state)=> selectSelectedDraft(state, scorecard.uuid)) // ARCHIT use the useSelected with the selectedScorecardScoreDraft in score.slice

  const clearSurvey = (e: any) => {
    flushCachedAnswers(scorecard.uuid);
    setHasAnswers(false);
    setCurrentPage(0);
    dispatch(setSelectedScorecardScore({scorecardUuid:scorecard.uuid, score: undefined}));
    e.preventDefault();
  };

  useEffect(() => {
    (async () => {
      if (scorecard && requirementList && questionList) {
        const newJson = await buildScorecardSurvey(scorecard, requirementList, questionList).then((survey) => survey);
        setJson(newJson);
      }
    })();
  }, [scorecard, requirementList, questionList]);

  useEffect(() => {
    if (selectedDraft) setHasAnswers(selectedDraft !== undefined);
  }, [selectedDraft]);


  useEffect(() => {
    if (!isLoading && json !== undefined) {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  },[isLoading, json])



  return (
    <>
      <SurveyControlButtons scorecard={scorecard} hasAnswers={hasAnswers} clearSurvey={clearSurvey} />

      <QuestionnaireWrapper title={title}>
        {
          _isLoading &&
          <div className="d-flex justify-content-center">
          <Spinner />
          </div>
        }
        {
          scorecard && !_isLoading &&
          <SurveyContainer
            json={json}
            scorecard={scorecard}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            hasAnswers={hasAnswers}
            setHasAnswers={setHasAnswers} />
        }
      
      </QuestionnaireWrapper>
      {/* <DownloadPDF title={`${scorecard.title}-results`} elementID="nuenergy-questionnaire" /> */}
    </>
  );
};

export default ScorecardQuestionnaire;
