import { combineReducers } from "@reduxjs/toolkit";
import toolsReducer from "./tools.slice";
import toolRequestReducer from "./request.slice";
import toolRequestResultsReducer from "./result.slice";


export default combineReducers({
  element: toolsReducer,
  request: toolRequestReducer,
  result: toolRequestResultsReducer
});