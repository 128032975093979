import React from 'react'
import { useAsyncDebounce } from 'react-table'
interface Props {
  filter: any,
  setFilter: (filterValue: any) => void
  preGlobalFilteredRows: any[]
}

const GlobalFilter = ({ filter, setFilter, preGlobalFilteredRows, }: Props) => {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(filter)
  const onChange = useAsyncDebounce(value => {
    setFilter(value || undefined)
  }, 200)

  return (
    <span>
      <i className="ri-search-line"/>:{''}
      <input value={value || ''} onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
        placeholder={`${count} records...`}
      
      
      />
    </span>
  )
}

export default GlobalFilter;