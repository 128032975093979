import Dropdown from "./Dropdown";
import Link from "./Link";
import Link_Framework from "./Link_Framework";

const Drawer = {
  Link,
  Link_Framework,
  Dropdown,
};

export default Drawer;