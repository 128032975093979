import React from 'react'
import { useTranslation } from 'react-i18next';
import { BaseQuestionResult, RequirementScore } from 'machine-trust-platform';
import _uniqueId from 'lodash/uniqueId';

interface Props {
  question: BaseQuestionResult; // TODO: Figure out the proper type for this (its a combination of Question and Something else)
  isSubQuestion?: boolean;
  result?: RequirementScore; // TODO: Figure out the proper type for this
}

const QuestionInfo = ({ question, result, isSubQuestion = false }: Props) => {
  const { t } = useTranslation();

  return (result ?
    <>
      <div className="requirement-question-result__header">
        <h5 id={_uniqueId('question-')}>{question.question}</h5>
        {!isSubQuestion &&
          <span className="header-question-score">
          {question.answer_value != null && question.answer_value !== -1 ?
            `[${t('points.label')}: +${Math.round((question.weight * question.answer_value * 100) / result?.maximum_of_weighted_score)}]` : ''}
          </span>}
      </div>

      <div className="requirement-question-result__responses">
        <span className="header-question-score">{question.answer}</span>
        {question.explanation ? (
          <div className="question-explanation">
            <h6 className="response-header">{`${t('explanation.label')}:`}</h6>
            <p>{question.explanation}</p>
          </div>
        ) : null}

        {question.mitigations ? (
          <div className="question-mitigation__container">
            <h6 className="response-header">{`${t('recommendations.label')}:`}</h6>
            <ul className="question-mitigation__list">
              {question.mitigations.map((m) => (
                <li key={m}>
                  {m}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    </> : null)
};

export default QuestionInfo