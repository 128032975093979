import { FeatureDetails } from "machine-trust-platform";



/**
 * Description
 * @param {string} str
 * @param {any} delim='
 * @returns {any}
 */
export const processCSV = async (str: string, delim = ',') => {
  // Parse the CSV Feature Names
  const features = await str.slice(0, str.indexOf('\n')).split(delim);

  const rows = await str.slice(str.indexOf('\n') + 1).split('\n');

  let featureDetails: Record<string, FeatureDetails> = {}

  await rows.forEach(async (row) => {
    const values = row.split(delim);

    await features.forEach((feature: string, index: number) => {
      // Some Datasets my contain an index column that does not contain a name
      // Here we ignore that column entirely
      // Note: We cant remove the index column yet because if we do the feature index will 
      // be out of line with the items in each row.
      if (feature !== "") {
        // Make sure that the feature details contains the feature
        // if it doesn't initialize it
        if (!featureDetails.hasOwnProperty(feature)) {
          featureDetails[feature] = {
            feature: feature,
            labels: new Set(),
            isBinary: false,
            isNumeric: false,
          }
        }

        // Make sure that the value is not null or undefined before adding it to the feature labels
        if (values[index]) {
          if (values[index] === "0.0") values[index] = '0'

          // Try and add the value to the labels Set
          featureDetails[feature].labels.add(Number(values[index]) || values[index] === '0' ? Number(values[index]) : values[index])

          // Check if the feature isBinary or if it is Numeric
          featureDetails[feature].isBinary = featureDetails[feature].labels.size === 2
          featureDetails[feature].isNumeric = featureDetails[feature].labels.size > 0 && (typeof (featureDetails[feature].labels.values().next().value) === 'number' || typeof (featureDetails[feature].labels.values().next().value) === 'bigint')

        }
      }
    })
  })

  // Delete the index from the details object
  delete featureDetails[""]
  return featureDetails
}



export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}