import React, { useState, useRef,  useMemo } from 'react'
import Button from '../Button'
import { useTranslation } from 'react-i18next'

import styles from './upload.file.module.scss'
import { ProgressBar } from '..'
import { formatBytes } from '../../utils/dataset'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  accept: string;
  loadFileCallback?: (arg: string | ArrayBuffer | null | undefined) => Promise<any>;
  saveFileCallback?: (arg: File) => void;
  showSubmit?: boolean;

}

// TODO: Implement Drag and Drop

/**
 * Description
 * @param {any} {loadDatasetCallback
 * @param {any} saveFileCallback
 * @param {any} showSubmit=false - If this is set to true we will use the submit button. Otherwise the submit will occur when the file has loaded
 * @param {any} rest - All other HTMLDivElements Props
 * @returns {any}
 */
const UploadFile = ({ loadFileCallback, saveFileCallback, title, accept, showSubmit = false, ...rest }: Props) => {
  const { t } = useTranslation()
  const hiddenInput = useRef<HTMLInputElement>(null)
  const reader = useMemo(() => new FileReader(), [])

  const [file, setFile] = useState<File>()
  const [progress, setProgress] = useState<{ percentage: number, bytes: number }>({ percentage: 0, bytes: 0})

  // const dragOver = (e) => {
  //   e.preventDefault();
  // }

  // const dragEnter = (e) => {
  //   e.preventDefault();
  // }

  // const dragLeave = (e) => {
  //   e.preventDefault();
  // }


  const errorHandler = (evt: ProgressEvent<FileReader>) => {
    alert(reader.error)
  }

  const processFile = async (_file: File) => {;
    reader.onabort = errorHandler
    reader.onerror = errorHandler

    reader.onprogress = (e: ProgressEvent<FileReader>) => {

      let percent_complete = (e.loaded / e.total) * 100;
      percent_complete = Math.floor(percent_complete);

      setProgress({
        percentage: percent_complete,
        bytes: e.loaded
      })
    }
        // Define the onload function for the file reader
    reader.onload = async (e: ProgressEvent<FileReader>) => {
      const text = e.target?.result
      loadFileCallback && await loadFileCallback(text)
    }


    reader.readAsText(_file as File)
    // reader.readAsDataURL(_file);
    

  }

  /**
   * Description
   * @param {React.ChangeEvent<HTMLInputElement>} e
   * @returns {any}
   */
  const saveFile = async (e: any) => {
    // TODO: Check the file extension
    if (e.target.files && e.target.files.length > 0) {
      const datasetFile = e.target.files[0]
      setFile(datasetFile)

      if (!showSubmit) {
        await processFile(datasetFile);
      }
      saveFileCallback && saveFileCallback(datasetFile)
    }
  };

  const handleSubmit = async () => await processFile(file as File);

  const handleClick = () => {
    if (hiddenInput.current !== null) {
      hiddenInput.current.click()
    }
  }


  return (

    <div
      {...rest}
      className={styles.uploader}
    >
      <div className={styles.content}>
        {/* <i className="fas fa-download"></i> */}

        <div className={styles.uploadBtn}>
          <Button onClick={handleClick}
          // variant={'purple'}
          >
          {title? title : t('upload.file.label')}
          </Button>
          {file && <span className={styles.fileName}>{file.name}</span>}
        </div>


        <div className={styles.progress}>
          <ProgressBar completed={progress.percentage} />
          <div>
            <span>
              {file &&  `${formatBytes(progress.bytes)} / ${formatBytes(file?.size || 0)}`}
            </span>
          </div>
        </div>
      </div>



      {showSubmit &&
        <div className={styles.footer}>
          <Button
            // variant={'purple'}
          className={styles.submit}
          disabled={file === undefined}
          onClick={handleSubmit}
        >
          {t('submit.label')}
          </Button>
          </div>
        }

      <input
        ref={hiddenInput}
        className={styles.input}
        type='file'
        accept={accept}
        onChange={saveFile}
      />

    </div>
  )
}

export default UploadFile