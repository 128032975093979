import React from 'react';
import PropTypes from 'prop-types';
import pdfMake from 'pdfmake/build/pdfmake';
// import Roboto from '../fonts/Roboto.js'
import { getNameOfMonthFromTimeStamp } from '../../utils/time';
import { SurveyPDF } from 'survey-pdf';
import {  Model, StylesManager} from 'survey-react';
// import { SurveyPDF } from 'survey-pdf';
import styles from "./downloadpdfbutton.module.scss"
import htmlToPdfmake from "html-to-pdfmake";
const jsPDF = require('jspdf');

interface Props{
  title: string;
  elementID: string;
}

const DownloadPDF = ({title, elementID}:Props ) => {
const handleDownloadPDF = (event: any) => {
  var doc = new jsPDF();
  let height=10
  let centerPos = (doc.internal.pageSize.width / 2);
  let maxWidth =160;
  let img=new Image()
  img.src="/images/logo-nuenergy.png"
  doc.addImage(img, 'png', 10, height, 37.75, 11.15)
  
  doc.setFont('helvetica','bold');
  doc.setFontSize(20)
  const title = document.getElementById(elementID)?.children[1].children[1].textContent;
  doc.text(title, centerPos, height+=20, "center")

  doc.setFont('','bold');
  doc.setFontSize(13)
  doc.text("Baseline Scorecard Result:", 70, height+=12)
  const totalScore = document.querySelector("#nuenergy-questionnaire > div.scorecard-results__container.container > div.scorecard-results__scorecard-title > h2")?.innerHTML
  doc.text(totalScore, 70, height+=5)

  
  var temp= document.querySelector("#nuenergy-questionnaire > div.scorecard-results__container.container > div.scorecard-results__requirement-scores")!.children;
  for(let i=0; i<temp.length; i++){
    let title = temp[i].children[0].children[0].innerHTML.trim()
    let score = temp[i].children[1].children[0].children[0].children[0].innerHTML.trim()
    doc.setFont('','normal');
    doc.setFontSize(13)
    doc.text(title+":", 70, height+=5,)

    doc.setFont('','normal');
    doc.setFontSize(10)
    doc.text(score, 70, height+=4)
  }

  doc.text("Recommendations:", 20, height+=15)
  var recommendationList = document.getElementsByClassName("question-mitigation__list");
  let increment_height=5;
  for (let i=0; i<recommendationList.length; i++){
    for (let j=0; j<recommendationList.length; j++){
      if(recommendationList[i].children[j]){
        let line=recommendationList[i].children[j].innerHTML;
        let formattedLine=doc.splitTextToSize("• "+line, maxWidth);
        doc.text(formattedLine, 25, height+=increment_height);
        increment_height=5+5*Math.floor(line.length/110);
        if (height+increment_height > 270) {
          height = 10;
          doc.addPage();
        }
      }
    }
  }

  // doc.output('dataurlnewwindow');
  const date = new Date();
  doc.save(`${title}-${getNameOfMonthFromTimeStamp(date).substring(0, 3)}-${date.getDate()}.pdf`)
};

// const surveyModel = new Model();
//     surveyModel.showNavigationButtons = false;
//     surveyModel.isRequired = true;

return (
  <button type="button" className={styles.downloadpdfbutton} onClick={handleDownloadPDF}>
    <i className="ri-file-download-fill ri-lg" />
  </button>
);
};

// DownloadPDF.propTypes = {
//   title: PropTypes.string.isRequired,
//   elementID: PropTypes.string.isRequired,
// };

export default DownloadPDF;
