import React, { useEffect } from 'react';
import { ORGANIZATION, MTPPath as Path} from '../../constants';
import { Drawer} from '../../app/components';
import { useDispatch, useSelector } from '../../app/hooks';
import { enableDrawer, selectDrawerIsDisabled } from '../../app/features/ui/slices/navigation.slice';

const { TIER } = ORGANIZATION


const AIGovernanceDrawer = () => {
  const dispatch = useDispatch()

  const basePath = Path.AI_GOVERNANCE

  const drawerIsDisabled = useSelector(selectDrawerIsDisabled)

  useEffect(() => {
    if (drawerIsDisabled) dispatch(enableDrawer())
    
  }, [dispatch, drawerIsDisabled])


  return (
    <>
      <Drawer.Link  // Dashboard
        id="ai-governance-dashboard-drawer-btn"
        dataTut="ai-governance-dashboard-drawer-btn"
        basePath={basePath}
        path={basePath}
        label="dashboard.label"
        title="dashboard.label"
        tier={TIER.ALL}
      />

      <Drawer.Dropdown // AI Governance Scorecard (AIGS)
        id={"aigs-dropdown"}
        label="ai.governance.scorecard.label"
        title="ai.governance.scorecard.label"
        dataTut='aigs-dropdown'
        tier={TIER.ALL}
      >
        <Drawer.Link  // AIGS Questionnaire
          basePath={basePath}
          path={`${basePath}/questionnaire`}
          label="questionnaire.label"
          title="aigs.questionnaire.title"
          dataTut="aigs-questionnaire-link"
          tier={TIER.ALL}
        />
        <Drawer.Link  // AIGS Scores
          id="aigs-dashboard-drawer-btn"
          basePath={basePath}
          path={`${basePath}/scores`}
          label="scores.label"
          title="aigs.scores.title"
          dataTut="aigs-scores-link"
          tier={TIER.ALL}
        />

        

      </Drawer.Dropdown>

      

      <Drawer.Link // Canvas
        id="aigs-canvas-drawer-btn"
        basePath={basePath}
        path={`${basePath}/canvas`}
        label="canvas.label"
        title="canvas.title"
        dataTut="ai-governance-canvas-link"
        tier={TIER.GOLD}
      />
  
      
    </>
  )
}

export default AIGovernanceDrawer;