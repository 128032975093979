/*
 * File: Dashboard.tutorial.jsx
 * Project: Machine Trust Platform
 * File Created: Monday, 14th June 2021 3:30:29 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Thursday, 26th August 2021 12:38:51 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
// import { useTranslation } from 'react-i18next';
import { Step } from 'react-joyride';
import CustomJoyRide from '../CustomJoyRide';

const DashboardTutorial = () => {
  // const { t } = useTranslation();
  const STEPS: Step[] = [
    // {
    //   target: '#projects-aims-questionnaire-drawer-btn',
    //   content: <Tutorial header={t('AIMS.dashboard.tutorial.label')}>{t('AIMS.dashboard.tutorial.step.1')}</Tutorial>,
    // },
  ];

  return <CustomJoyRide steps={STEPS} />;
};

export default DashboardTutorial;