import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../../store";
import config from '../../../config.json'

import type { FaultReport, ToolRequest } from "machine-trust-platform";


export interface ToolRequestState {
  list: ToolRequest[];
  selected?: ToolRequest;
  lastFetchTime: number
  status: "idle" | "loading" | "failed";
}

const initialUserRequestState: ToolRequestState = {
  list: [] as ToolRequest[],
  selected: undefined,
  lastFetchTime: -1,
  status: "idle",
}

export type RequestState = Record<string, ToolRequestState>;

const initialState: RequestState = {};

/* -------------------------- Async Thunk --------------------------*/
const getToolRequests = createAsyncThunk(
  "all/requests",
  async (userId: string, { getState, dispatch, rejectWithValue }) => {


    try {
      const response = await axios({
        url: `${config.ai_tool_uri}/GetUserRequests/${userId}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
      })

      if (response.data.faultReport.isFault) return rejectWithValue(response.data.faultReport as FaultReport)

      return { requests: response.data.response, userId } as { requests: ToolRequest[], userId: string }

    } catch (err) {
      rejectWithValue(err)
    }
  }
)

/* -------------------------- App Thunk --------------------------*/
// ....

/* -------------------------- Slice --------------------------*/
export const toolRequestSlice = createSlice({
  name: 'toolRequests',
  initialState,
  reducers: {
    createToolRequestStateForUserID: (state, action: PayloadAction<string>) => {
      state[action.payload] = initialUserRequestState
    }


  },
  extraReducers: (builder) => {
    builder
    .addCase(getToolRequests.fulfilled, (state, action) => {
      const { requests, userId } = action.payload as { userId: string, requests: ToolRequest[] }
      if (!state[userId]) {
        state[userId] = { ...initialUserRequestState, list: requests, lastFetchTime: (new Date()).getTime()}
      } else {
        state[userId].list = requests
        state[userId].lastFetchTime = (new Date()).getTime()
      }
    })
  }
})

// Tool Request State Actions
export const { createToolRequestStateForUserID } = toolRequestSlice.actions;
export { getToolRequests };


// Selectors
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectToolRequestList = (state: RootState, userId: string) => state.tools.request[userId]?.list || []
export const getToolRequestStateForUserId = (state: RootState, userId: string) => state.tools.request[userId]
export const selectToolRequestLastFetchTime = (state: RootState, userId: string) => state.tools.request[userId]?.lastFetchTime || undefined  

export default toolRequestSlice.reducer;