import { createAsyncThunk, createSlice, isAnyOf, PayloadAction, } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { client } from "../../../utils/api";

import type { Project, Question, Status } from "machine-trust-platform";
import { setOrganization, login } from "../../auth/auth.slice";
import { addProject, getAllProjects, getProject } from "../../project/project.slice";

interface QuestionReducerState {
  list: Question[];
  selected?: string;
  lastFetchTime: number;
  userLastModTime: number;
  status: Status
}

// record with key of ScorecardUUid where string is
type State = Record<string, QuestionReducerState>;

// This is used when initializing a question state for a scorecard
const initialScorecardQuestionState = {
  list: [],
  selected: undefined,
  lastFetchTime: -1,
  userLastModTime: -1,
  status: "loading" as Status

}

const initialState: State = {};

/* -------------------------- Async Thunk --------------------------*/

// This action is used to get all the AIGS Scorecard Questions 
const getAllScorecardQuestion = createAsyncThunk(
  "get/AllScorecardQuestion",
  async (scorecardUuid: string, thunkApi) => {
    const idToken = (thunkApi.getState() as RootState).auth.tokens?.id_token as string; //gets the idToken for authentication 

    try {
      const response = await client(`/scorecard/${scorecardUuid}/question`, idToken); //makes the api call and stores it in response

      //this is the payload which holds the scorecardUuid and a list with all the questions pull from the api call
      return { scorecardUuid: scorecardUuid, list: response.data } as { scorecardUuid: string, list: Question[] };
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  }
);

/* -------------------------- App Thunk --------------------------*/

/* -------------------------- Slice --------------------------*/
export const questionSlice = createSlice({
  name: "ScorecardQuestionState",
  initialState,
  reducers: {
    // Reducer to update the status of the question 
    updateQuestionStatus: (state, action: PayloadAction<{ scorecardUuid: string, status: "idle" | "loading" | "failed" }>) => {
      const { scorecardUuid, status } = action.payload; // sets the action payload to being an object made of scorecardUuid and status
      state[scorecardUuid].status = status
    },

  },
  extraReducers: (builder) => {
    builder
      /*-------------- Get All questions --------------  */

      // This is the addcase for the reducer that gets all the scorecard questions
      .addCase(getAllScorecardQuestion.fulfilled, (state, action) => {
        if (action.payload?.scorecardUuid) {
          state[action.payload?.scorecardUuid].list = action.payload.list;
          state[action.payload?.scorecardUuid].status = "idle";
        }
      })

      .addMatcher(isAnyOf(getProject.fulfilled, addProject.fulfilled), (state, action: PayloadAction<Project>) => {
        const project = action.payload
        state[project.scorecardUuid as string] = initialScorecardQuestionState
      })

      .addMatcher(isAnyOf(getAllProjects.fulfilled), (state, action: PayloadAction<Project[]>) => {
        const projectList = action.payload || []
        if (projectList.length > 0) {
          for (let project of projectList) {
            if (project) {
              state[project.scorecardUuid as string] = initialScorecardQuestionState
            }
          }
        }
      })

      .addMatcher(isAnyOf(setOrganization, login), (state, action) => {
        const { scorecardUuid } = action.payload.organization
        if (!state[scorecardUuid] || (state[scorecardUuid] && !state[scorecardUuid].status)) {
          state[scorecardUuid] = initialScorecardQuestionState
        }
      })
  },
})


// Scorecard Score State Actions
export const { updateQuestionStatus } = questionSlice.actions;
export { getAllScorecardQuestion };


// Selectors
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
//export const getSelectScorecard = (state: RootState,  scorecardUuid: string) => state.scorecardScores[scorecardUuid] 
export const selectSelectedQuestionList = (state: RootState, scorecardUuid?: string) => scorecardUuid ? state.scorecard.question[scorecardUuid].list : undefined
export const selectQuestionLastFetchTime = (state: RootState, scorecardUuid?: string) => scorecardUuid ? state.scorecard.question[scorecardUuid].lastFetchTime : -1
export const selectQuestionStatus = (state: RootState, scorecardUuid?: string) => scorecardUuid ? state.scorecard.question[scorecardUuid].status : 'loading'


export default questionSlice.reducer;









