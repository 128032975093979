import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PopupMessage } from "machine-trust-platform";
import { RootState } from "../../../store";
import { login } from "../../auth/auth.slice";

type Status = "idle" | "loading" | "failed";
export interface UiState {
  status: Status;
  popup?: PopupMessage;
}

const initialState: UiState = {
  status: "idle",
  popup: undefined
};

export const uiStateSlice = createSlice({
  name: "uiState",
  initialState,
  reducers: {
    
    setStatus: (state, action: PayloadAction<Status>) => {
      state.status = action.payload;
    },

    setPopupMessage: (state, action: PayloadAction<PopupMessage | undefined>) => {
      state.popup = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login, (state, action) => {
        state.status = "idle";
      });
  },
});

// UI State Actions
export const { setStatus, setPopupMessage } = uiStateSlice.actions;

// Selectors
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUIStatus = (state: RootState) => state.uiState.main.status;
export const selectPopupMessage = (state: RootState) => state.uiState.main.popup;

export default uiStateSlice.reducer;
