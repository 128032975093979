const agreementHTML = `


<body lang=EN-CA link=blue vlink=purple style='tab-interval:36.0pt;word-wrap:
break-word'>

<div class=WordSection1>

<p class=MsoNormal align=center style='text-align:center'><span class=SpellE><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial'>NuEnergy</span></b></span><b style='mso-bidi-font-weight:
normal'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial'> SOFTWARE AS A SERVICE AGREEMENT<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>By accessing
and/or using the software provided by <span class=SpellE>NewEnergy</span>
Community Inc. (dba NuEnergy.ai) (“</span><span class=SpellE><w:Sdt
 SdtTag="goog_rdk_0" ID="1161968552"><w:Sdt SdtTag="goog_rdk_1" ID="-466348824"></w:Sdt></w:Sdt><w:Sdt
 SdtTag="goog_rdk_2" ID="1605917732"><b style='mso-bidi-font-weight:normal'><span
 style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>NuEnergy</span></b></w:Sdt></span><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>”), you
(the “<b style='mso-bidi-font-weight:normal'>Participant</b>”) agree to the
terms and conditions herein (the “<b style='mso-bidi-font-weight:normal'>Agreement</b>”)
as of the date of first access and/or use (the “<b style='mso-bidi-font-weight:
normal'>Effective Date</b>”). If you do not agree to the terms below, do not access
and/or use the software.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal align=center style='text-align:center'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>RECITALS<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial'>Participant desires access to and use of the </span><w:Sdt
 SdtTag="goog_rdk_3" ID="845754752"></w:Sdt><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial'>artificial intelligence (“AI”) governance
software (the “<b style='mso-bidi-font-weight:normal'>Software</b>”) created by
<span class=SpellE>NuEnergy</span> and provided under the terms and conditions
hereinafter recited and on a ‘Software as a Service’ basis.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial'>NOW THEREFORE</span></b><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial'>, in consideration of the mutual covenants and
promises hereinafter <span class=GramE>set</span> forth and other good and
valuable consideration, the receipt and sufficiency of which is hereby
acknowledged, the Participant and <span class=SpellE>NuEnergy</span> (together
the “<b style='mso-bidi-font-weight:normal'>Parties</b>” and each a “<b
style='mso-bidi-font-weight:normal'>Party</b>”) agree as follows:<b
style='mso-bidi-font-weight:normal'> <o:p></o:p></b></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-36.0pt;mso-list:l1 level1 lfo2;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><b style='mso-bidi-font-weight:
normal'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><span style='mso-list:Ignore'>1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>Definitions<o:p></o:p></span></b></p>

<p class=MsoNormal style='text-align:justify'><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial'>Definitions.</span></u><span style='font-family:
"Arial",sans-serif;mso-fareast-font-family:Arial'> Capitalized terms in this
Agreement will have the following meanings:<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>1.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>“<b style='mso-bidi-font-weight:
normal'>Agreement</b>” means this ‘Software as a Service’ Agreement.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>1.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>“<b style='mso-bidi-font-weight:
normal'>Documentation</b>” means the user guides, online help, release notes,
tutorials, whether video or otherwise, and other documentation provided or made
available by <span class=SpellE>NuEnergy</span> in fulfilment of this
Agreement.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>1.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>“<b style='mso-bidi-font-weight:
normal'>Intellectual Property</b>” means anything susceptible or potentially
susceptible to Intellectual Property Rights anywhere in the world that is
conceived or produced in the furtherance of <span class=SpellE>NuEnergy’s</span>
interest and business or in which <span class=SpellE>NuEnergy</span> otherwise
has a proprietary interest, and shall include, but is not limited to the
following: the Software, Documentation, documents, codes, methods, designs,
programs, techniques, processes, products, and reports, whether conceived or
produced by <span class=SpellE>NuEnergy</span>, employees or contractors of <span
class=SpellE>NuEnergy</span>, or any third party on <span class=SpellE>NuEnergy’s</span>
behalf.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>1.4<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>“<b style='mso-bidi-font-weight:
normal'>Intellectual Property Rights</b>” means all copyrights, patents,
trademarks, industrial designs, trade secrets, any applications for any of the
foregoing, and any other proprietary rights.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>1.5<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>“<b style='mso-bidi-font-weight:
normal'>Terms of Use</b>” means <span class=SpellE>NuEnergy’s</span> standard
terms of use with respect to the Software as updated from time to time, to
which Users are required to agree.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>1.6<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>“<b style='mso-bidi-font-weight:
normal'>User</b>” means a user of the Software to whom access to the Software
is provided by Participant under the Agreement, subject to the Terms of Use.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-36.0pt;mso-list:l0 level2 lfo1;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><b style='mso-bidi-font-weight:
normal'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><span style='mso-list:Ignore'>1.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>“<b style='mso-bidi-font-weight:
normal'>Force Majeure</b>” includes but is not limited to acts of God, flood,
explosion, fire, lightning, earthquake, tornado, war, riot, civil disturbance,
strike, government order, decision or administrative ruling, health crisis,
epidemic or pandemic, government inaction, technological problems including but
not limited to malfunction in satellite networks and Internet availability, and
any other circumstances that are unforeseeable, sudden, insurmountable, and/or
outside the control of <span class=SpellE>NuEnergy</span>;<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial'>Other capitalized terms have the meanings
defined in this Agreement.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level1 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>Scope
of Software, Rights Granted and Restrictions <o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>2.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Licence</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
<span class=SpellE>NuEnergy</span> hereby grants to Participant a limited,
non-exclusive, revocable, non-transferable, non-assignable, and
non-sublicensable licence for the term herein set out for Participant and Users
employed at Participant to use the Software, in accordance with the Documentation
and the Terms of Use. Such licence shall extend to Users only for so long as
they are employees, contractors, agents, interns, students, and others
occupying similar roles at Participant, and Participant represents and warrants
that it will revoke access to the Software immediately for any User that ceases
to occupy such <span class=GramE>role <span style='mso-spacerun:yes'> </span>at</span>
Participant. <span class=GramE>Any and all</span> breach of this Agreement by
any User will be imputed to Participant.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-36.0pt;mso-list:l0 level2 lfo1;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><b style='mso-bidi-font-weight:
normal'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><span style='mso-list:Ignore'>2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Participant Obligations</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
<span class=SpellE>NuEnergy’s</span> provision of the Software to Participant
is contingent upon Participant:<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(a)
providing up-to-date, complete and accurate information respecting its Users
authorized to access the <span class=GramE>Software;</span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(b)
complying at all times with the Terms of Use and with applicable laws, whereby
a failure to so comply on the part of any User will be imputed to <span
class=GramE>Participant;</span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(c) paying
to <span class=SpellE>NuEnergy</span> </span><w:Sdt SdtTag="goog_rdk_4"
 ID="697518147"><w:Sdt SdtTag="goog_rdk_5" ID="-1937206545"></w:Sdt><span
 style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>monthly </span></w:Sdt><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>fees </span><w:Sdt
 ShowingPlcHdr="t" SdtTag="goog_rdk_6" ID="1122038134"><span
 style='mso-spacerun:yes'>     </span></w:Sdt><w:Sdt SdtTag="goog_rdk_7"
 ID="-366060042"><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
 Arial'>per the “<span class=SpellE>NuEnergy</span> Engagement </span><w:Sdt
  SdtTag="goog_rdk_8" ID="-1648823365"></w:Sdt><span style='font-family:"Arial",sans-serif;
 mso-fareast-font-family:Arial'>Letter” </span></w:Sdt><span style='font-family:
"Arial",sans-serif;mso-fareast-font-family:Arial'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-36.0pt;mso-list:l0 level2 lfo1;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><b style='mso-bidi-font-weight:
normal'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><span style='mso-list:Ignore'>2.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Participant Restrictions</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
Participant shall not: <o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(a) copy,
publish, reproduce, distribute, perform, download, or otherwise interfere with
the Software licensed herein, including the code of the Software in any form,
including without limitation source code and object <span class=GramE>code;</span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(b)
access, scrape, copy, publish, reproduce, distribute, perform, download, or
otherwise interfere with any data stored for or accessible to the Software <u>except</u>
as provided in section 2.1.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(c) use
the Software in any way inconsistent with the Terms of <span class=GramE>Use;</span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(d) own or
transfer title to the Software to a third <span class=GramE>party;</span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(e)
distribute, sublicense, or otherwise provide copies of the Software or any
rights in relation to the Software to any third <span class=GramE>party;</span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(f)
pledge, hypothecate, alienate, or otherwise encumber the Software to any third <span
class=GramE>party;</span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(g) store
the Software or any part thereof for use outside the parameters and/or duration
of this Agreement or <span class=GramE>otherwise;</span> <o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(h)
prosecute or permit any Users to prosecute a patent or other form of
intellectual property that in any way derives from or incorporates the
Software.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(<span
class=SpellE>i</span>) attempt to hack the Software or any communication
initiated by the Software or to defeat, overcome, or circumvent any encryption
and/or other technical and/or data protection methods implemented by <span
class=SpellE>NuEnergy</span> with respect to the Software, data, and/or content
transmitted, processed, or stored by <span class=SpellE>NuEnergy</span> or
other users of the <span class=GramE>Software;</span> <o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(j)
collect any information or communication about the Users of the Software, other
than Users who access the Software under Participant, by monitoring,
interdicting, or intercepting any process of or communication initiated by the
Software or by developing or using any software or any other process or method
that engages or assists in engaging in any of the <span class=GramE>foregoing;</span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(k) use
any type of bot, spider, virus, clock, timer, counter, worm, software lock,
drop dead device, packet-sniffer, Trojan-horse routing, trap door, time bomb,
or any other codes or instructions that are designed to or may be used to
provide a means of surreptitious or otherwise unauthorized access to the
Software or any computer system or that are designed to or may monitor,
distort, delete, damage, or disassemble the Software;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(l) create
any derivative works from the Software, create any substantially similar or
competing works with respect to the Software, reverse engineer the Software,
the source code, or the object code thereof, or access the source code or the
object <span class=GramE>code;</span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(m) copy,
publish, reproduce, or distribute the Documentation or create any substantially
similar or competing works with respect to the Documentation; or<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(n)
authorize, permit, encourage, assist, induce, or otherwise acquiesce in any
other party, person, or entity engaging in or attempting to engage in any of
the activities set forth in (a) – (m) above, or attempt to do so.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;line-height:normal;
mso-pagination:none;mso-list:l0 level2 lfo1'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial'><span style='mso-list:Ignore'>2.4<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial'>Enforcement of Restrictions.</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'> <span
class=SpellE>NuEnergy</span> will have the right to inspect and enforce the
restrictions and covenants contained in this Agreement, and Participant hereby
agrees to promptly notify <span class=SpellE>NuEnergy</span> of any known
violations of such restrictions.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>2.5<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Submissions</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
Participant agrees that any suggestions, bug reports, or other communications
respecting the functionality of the Software that Participant transmits to <span
class=SpellE>NuEnergy</span> by any means (each a &quot;<b style='mso-bidi-font-weight:
normal'>Submission</b>&quot;) is considered confidential information of <span
class=SpellE>NuEnergy</span> and may be used by <span class=SpellE>NuEnergy</span>,
without compensation or liability to Participant, for any purpose whatsoever.
By providing a Submission <span class=SpellE>NuEnergy</span>, Participant
represents and warrants that Participant has all necessary permissions to grant
the below licence to <span class=SpellE>NuEnergy</span>. Participant hereby
grants <span class=SpellE>NuEnergy</span>, its affiliates, and its successors a
perpetual, worldwide, non-exclusive, royalty-free, sublicensable, assignable,
and transferable licence to use, copy, distribute, transmit, modify, develop,
prepare, and publish derivative works of any Submission on, through, or in
connection with the Software, including without limitation for promoting,
improving, and developing the Software.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>2.6<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Licence to Participant Data</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
Participant owns and shall maintain control of all data uploaded through the
Software or created in the Software by Participant. <span class=GramE>In order
for</span> <span class=SpellE>NuEnergy</span> to maintain Participant data so
uploaded and created for the term of this Agreement, Participant grants to <span
class=SpellE>NuEnergy</span> a non-exclusive, royalty-free, non-transferable,
and non-sublicensable licence to that data only for the purposes laid out in
this subsection for that term.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-36.0pt;mso-list:l0 level2 lfo1;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><b style='mso-bidi-font-weight:
normal'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><span style='mso-list:Ignore'>2.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Retention of Participant Data.</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>
Participant grants <span class=SpellE>NuEnergy</span> a perpetual, worldwide,
non-exclusive, royalty-free licence to retain data uploaded through the
Software, created in the Software by Participant, and/or provided to <span
class=SpellE>NuEnergy</span> in any form by Participant, provided that <span
class=SpellE>NuEnergy</span> anonymizes such data following the expiration of
the licence of subsection 2.</span><w:Sdt SdtTag="goog_rdk_9" ID="265665396"></w:Sdt><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>6.
For greater certainty, <span class=SpellE>NuEnergy</span> may analyze and
continue to analyze such data following anonymization thereof <span
class=GramE>in order to</span> develop new and better learnings and tools and
to provide benchmarking comparisons and services.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;line-height:normal;
mso-pagination:none;mso-list:l0 level2 lfo1'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial'><span style='mso-list:Ignore'>2.8<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial'>Provision of Upgrades</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>. During
the term of this Agreement, <span class=SpellE>NuEnergy</span> may develop </span><w:Sdt
 ShowingPlcHdr="t" SdtTag="goog_rdk_10" ID="-1240945290"><span
 style='mso-spacerun:yes'>     </span></w:Sdt><w:Sdt SdtTag="goog_rdk_11"
 ID="-508142465"><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
 Arial'>certain </span></w:Sdt><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial'>updates and upgrades to the Software that <span
class=SpellE>NuEnergy</span> may release to Participant, </span><w:Sdt
 SdtTag="goog_rdk_12" ID="-775098858"></w:Sdt><w:Sdt SdtTag="goog_rdk_13"
 ID="518821402"></w:Sdt><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial'>at costs to be determined at <span class=SpellE>NuEnergy’s</span> sole
discretion. Updates and upgrades will be made available by <span class=SpellE>NuEnergy</span>
at its sole discretion; <span class=SpellE>NuEnergy</span> is not required to make
available or release updates or upgrades on a fixed schedule or at all.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;line-height:normal;mso-pagination:none'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level1 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>Confidential
Information and Data Protection <o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-36.0pt;mso-list:l0 level2 lfo1;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><b style='mso-bidi-font-weight:
normal'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><span style='mso-list:Ignore'>3.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Confidential Information.</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>
Each Party acknowledges that, <span class=GramE>during the course of</span>
this Agreement, each Party may be exposed to Confidential Information of the
other Party.<span style='mso-spacerun:yes'>  </span>Each Party agrees that all
such Confidential Information shall be subject to this section. Confidential
Information further includes all trade secrets, know-how, inventions,
techniques, processes, algorithms, software programs, schematics, planned
product features, functionality, methodology, performance, and software source
documents. For greater certainty, Confidential Information further includes the
Software and any information present in the Software, whether from <span
class=SpellE>NuEnergy</span> or a third party, as well as the Documentation.
The receiving Party will use reasonable diligence and in no event less than the
degree of care that is used with respect to its own confidential and
proprietary information of like nature, to prevent the unauthorized disclosure,
reproduction, or distribution of such Confidential Information to any other
individual or <span class=GramE>entity;</span><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-indent:36.0pt'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(a)<span
style='mso-tab-count:1'>        </span>Confidential Information will exclude:<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:72.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(<span
class=SpellE>i</span>)<span style='mso-tab-count:1'>         </span>information
that is already in the public domain as of the date of the disclosure or
becomes known publicly after disclosure through no fault of the receiving <span
class=GramE>Party;</span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:72.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(ii)<span
style='mso-tab-count:1'>        </span>information already known to the
receiving Party as of the date of the disclosure and that was not obtained
either directly or indirectly from the disclosing <span class=GramE>Party;</span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:72.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(iii)<span
style='mso-tab-count:1'>       </span>information disclosed to the receiving
Party by a third party not otherwise under an obligation of confidentiality
with the disclosing Party; and<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:72.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(iv)<span
style='mso-tab-count:1'>       </span>information that the receiving Party is
obliged to produce under order of a court of competent jurisdiction, provided
that the receiving Party promptly notifies the disclosing Party of such an
event so that the disclosing Party may seek an appropriate protective <span
class=GramE>order;</span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(b)<span
style='mso-tab-count:1'>        </span><span class=SpellE>NuEnergy</span> may,
in its sole discretion, collect data on Participant use of the Software and/or
Documentation. Where <span class=SpellE>NuEnergy</span> exercises this right,
such data shall be considered Confidential Information disclosed to <span
class=SpellE>NuEnergy</span> as receiving Party for the purposes of this
section. Where <span class=SpellE>NuEnergy</span> exercises this right, any
collection of data that may be considered personally identifiable information
shall be performed in accordance with <span class=SpellE>NuEnergy’s</span>
privacy policy available at www.nuenergy.ai/privacy-policy as amended from time
to time. Nevertheless, where the provisions of this SaaS Agreement and <span
class=SpellE>NuEnergy’s</span> privacy policy conflict, the provisions of this
SaaS Agreement shall prevail to the extent of the conflict.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level1 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>4<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>Intellectual
Property<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>4.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Ownership and Reservation of Rights</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
Participant acknowledges that <span class=SpellE>NuEnergy</span> is the
exclusive owner of all Intellectual Property and Intellectual Property Rights
in the Software and Documentation and retains all right, title, and interest
therein. Participant further acknowledges that the Software and Documentation
may be protected by Canadian, U.S., and further copyright laws, and that other
intellectual property laws (including patent laws) may protect the Software
and/or the Documentation. Nothing herein shall be construed as constituting a
sale of the Software or any portion thereof to Participant. For greater
certainty, Participant shall have only those rights to the Software expressly
granted by this Agreement, and any rights not expressly granted under this
Agreement are reserved.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-36.0pt;mso-list:l0 level2 lfo1;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><b style='mso-bidi-font-weight:
normal'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><span style='mso-list:Ignore'>4.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Copyright.</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>
The Software, Documentation, media, packaging, and illustrations associated
with the Software constitute intellectual property of <span class=SpellE>NuEnergy</span>.
Data or other material provided through the Software may be subject to
third-party <span class=GramE>copyright;</span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(a)
Participant agrees that all physical and electronic manifestations of the
Software and Documentation displays <span class=SpellE>NuEnergy’s</span>
copyright notice in a conspicuous manner. The Software and Documentation are
protected under Canadian copyright <span class=GramE>law;</span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(b) For
greater certainty, the <i style='mso-bidi-font-style:normal'>Copyright Act </i>in
Canada and similar legislation in other jurisdictions protects <span
class=SpellE>NuEnergy</span> and any third-party copyright-holders and
prohibits Participant from any publication, reproduction, or performance of the
copyrighted material herein licensed and hereinabove identified as such as well
as any data or other material provided through the Software that are subject to
third-party copyright. This protection and prohibition include but are not
limited to all forms of downloading, transferring, sharing, selling, leasing,
lending, or other similar actions that may have the effect of depriving <span
class=SpellE>NuEnergy</span> or any third-party copyright-holders of the full
benefit and enjoyment of their intellectual property rights.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(c) Where <span
class=SpellE>NuEnergy</span> creates a recording of any information session,
educational session, webinar, or like program, event, or offering attended by
Participant and/or by Users on Participant’s behalf, <span class=SpellE>NuEnergy</span>
shall be the sole owner of the copyright therein, including any comments or
information provided by Participant and/or by Users. <span class=SpellE>NuEnergy</span>
may retain such recording indefinitely for the purposes of this Agreement or
otherwise and at <span class=SpellE>NuEnergy’s</span> sole discretion.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>4.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Trademarks.</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>
Certain logos, product names, and trademarks owned by <span class=SpellE>NuEnergy</span>
may be contained within the printed materials and electronic manifestations of
the Software and Documentation. Participant has no right to use or benefit from
such marks except as explicitly set out in this Agreement.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level1 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>5<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>Title<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>5.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Title.</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>
Participant acknowledges that the Software and Documentation provided under
this Agreement belong exclusively to <span class=SpellE>NuEnergy</span>.
Unencumbered title to the Software and Documentation will, <span class=GramE>at
all times</span>, remain with <span class=SpellE>NuEnergy</span>. Participant
agrees to use reasonable diligence to guard against unauthorized use,
reproduction, re-engineering, distribution, or publication of the Software in
electronic or physical form.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level1 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>6<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>Warranty
and Indemnity<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>6.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Warranty.</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>
<span class=SpellE>NuEnergy</span> warrants that it is the owner of the
Software. <span class=SpellE>NuEnergy</span> does not warrant, guarantee,
accept any condition, or make any representation that the Software or
Documentation will meet Participant’s requirements or that the use of the
Software or Documentation will be uninterrupted or error-free. The Software may
experience downtime from time to time <span class=GramE>if and when</span> <span
class=SpellE>NuEnergy</span> chooses to make updates and/or upgrades available.
The Software, Documentation, and any data, whether from <span class=SpellE>NuEnergy</span>
or any third party, are provided on an “as is” basis without representations or
warranties of any kind, either express or implied, as to accuracy,
completeness, reliability, currency, merchantability, non-infringement, fitness
for purpose, or otherwise. Furthermore, <span class=SpellE>NuEnergy</span> does
not warrant, guarantee, accept any condition, or make any representation as to
the accuracy or quality of data present in the Software or Documentation from
third-party sources. No other verbal or written information provided by <span
class=SpellE>NuEnergy</span> will create a warranty or in any way increase <span
class=SpellE>NuEnergy’s</span> liability, and Participant shall not rely on
such information.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>6.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><w:Sdt SdtTag="goog_rdk_14" ID="961538794"></w:Sdt><u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>No
Advice.</span></u><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'> Information made available by <span class=SpellE>NuEnergy</span>
on its website, through the Software, through the Documentation, and/or through
<span class=SpellE>NuEnergy’s</span> employees is not intended to constitute
advice, particularly with respect to artificial intelligence and the user
thereof, nor is it to be used as a substitute for specific advice from a
licensed professional. Participant should not act, or refrain from acting,
based upon information contained on <span class=SpellE>NuEnergy’s</span>
website or transmitted through the Software or Documentation without
independently verifying the information and, as necessary, obtaining
professional advice.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>6.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Indemnity</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
The entire risk as to the results and performance of the Software and
Documentation is assumed by Participant. Participant shall indemnify and save <span
class=SpellE>NuEnergy</span>, as well as the directors and officers,
representatives and employees, suppliers and third parties of <span
class=SpellE>NuEnergy</span> (collectively, the &quot;<b style='mso-bidi-font-weight:
normal'>Indemnitee</b>&quot;) from and against <span class=GramE>any and all</span>
liabilities, damages, costs, or expenses awarded against or incurred or
suffered by the Indemnitee arising out of any action or proceeding commenced or
maintained by any entity in respect of Participant's use of the Software or
Documentation. Participant is entirely responsible for the conduct of its Users
with respect to the Software and Documentation and will indemnify Indemnitee
accordingly. The maximum aggregate liability of Indemnitee to Participant or
any other party for any cause whatsoever related to the licensed Software or
Documentation shall not exceed $1.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>6.4<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Data</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
Participant hereby indemnifies <span class=SpellE>NuEnergy</span> to the extent
that any uploading or use of any data provided by Participant through the
Software is in violation of any third-party rights or restrictions. Even where
Participant is entitled to upload and use such data, <span class=SpellE>NuEnergy</span>
assumes no responsibility for any liability or loss arising from such uploading
and use, and Participant agrees to indemnify <span class=SpellE>NuEnergy</span>
accordingly.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>6.5<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Authority</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
The Parties hereby warrant that each has the authority to enter into this
Agreement as of the Effective Date.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>6.6<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Reliance by <span class=SpellE>NuEnergy</span>
and Indemnity by Participant</span></u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>. Participant specifically agrees
that <span class=SpellE>NuEnergy</span> may rely on the accuracy of the
information provided by Participant to <span class=SpellE>NuEnergy</span>, and
that <span class=SpellE>NuEnergy</span> will have no liability whatsoever,
whether to Participant or to any third party, for any claims or damages
resulting from inaccurate information provided to <span class=SpellE>NuEnergy</span>.
Furthermore, if <span class=SpellE>NuEnergy</span> would be liable to a third
party because of inaccuracy in information provided by Participant, Participant
hereby indemnifies <span class=SpellE>NuEnergy</span> to that extent.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level1 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>7<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>Limitation
of Liability and Remedies<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>7.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Limitation of Liability</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
In no event will <span class=SpellE>NuEnergy</span> be liable for any losses or
damages incurred by Participant, whether direct, indirect, incidental, special,
exemplary, or consequential, including lost or anticipated profits, savings,
interruption to business, loss of business opportunities, loss of business
information, the cost of recovering such lost information, the cost of
substitute intellectual property or any other pecuniary loss arising from the
use of, the inability to use, or the dissemination of information by third
parties on or through the Software or Documentation regardless of whether
Participant has advised <span class=SpellE>NuEnergy</span> or <span
class=SpellE>NuEnergy</span> has advised Participant of the possibility of such
damages. <span class=SpellE>NuEnergy’s</span> aggregate liability in respect of
<span class=GramE>any and all</span> claims will be limited to one hundred
($100) dollars. The foregoing limitations apply regardless of the cause or
circumstances giving rise to such loss, damage, or liability, even if such
loss, damage, or liability is based on negligence or other torts or breach of
contract (including fundamental breach or breach of a fundamental term).
Neither Participant nor <span class=SpellE>NuEnergy</span> may institute any
action in any form arising out of this Agreement more than one (1) year after
the cause of action has arisen. Some provinces do not allow the exclusion or
limitation of incidental or consequential damages, and so the above exclusions
may not apply.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>7.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Dispute Resolution</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
Participant acknowledges that <span class=SpellE>NuEnergy</span> possesses
valuable confidential and proprietary information, including trade secrets and
business practices, which would be damaging to <span class=SpellE>NuEnergy</span>
if revealed in open court. Participant further acknowledges and agrees that it
is preferable to resolve all disputes confidentially, individually, and in an
expeditious and inexpensive manner. Accordingly, Participant agrees that any
dispute will be resolved by private dispute resolution, rather than court action.
Before commencing any arbitration in the manner set out in section 7.3 below,
both Parties shall first attempt to resolve any dispute or differences by way
of good faith negotiation. The good faith negotiation shall commence by each of
<span class=SpellE>NuEnergy</span> and Participant communicating their
respective positions regarding the complaint, claim, dispute, or controversy to
the other and proposing how the Parties should resolve the dispute. Both
Parties shall then make good faith efforts to negotiate a resolution of the
complaint, claim, dispute, or controversy. Neither Party shall commence any
arbitral proceedings unless and until the good faith negotiation fails.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>7.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Arbitration</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
Any complaint, claim, dispute, or controversy (whether in contract, tort, or
otherwise; whether pre-existing, present, or future; and including statutory,
common law, intentional tort, and equitable claims capable in law of being
submitted to binding arbitration) against <span class=SpellE>NuEnergy</span>,
the agents, employees, officers, directors, successors, assigns, or affiliates
thereof (collectively, for purposes of this paragraph, “<span class=SpellE>NuEnergy</span>
et al.”) arising from or relating to this Agreement, its interpretation, or the
breach, termination, or validity thereof, the relationships between the
Parties, whether pre-existing, present, or future (including, to the full
extent permitted by applicable law, relationships with third parties who are
not signatories to this Agreement), <span class=SpellE>NuEnergy</span> et al.’s
advertising, or any related purchase SHALL BE RESOLVED EXCLUSIVELY AND FINALLY
BY BINDING ARBITRATION. The arbitration will be limited solely to the
complaint, claim, dispute, or controversy between Participant and <span
class=SpellE>NuEnergy</span> et al. Any award of the arbitrator(s) shall be
final and binding on Participant and <span class=SpellE>NuEnergy</span> et al.
and may be entered as a judgment in any court of competent jurisdiction. The
arbitration will take place in Ottawa, Ontario and will be conducted under the
rules of the Canadian Arbitration Association in English.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level1 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>8<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>Successors
and Assigns<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>8.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Successors and Assigns</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
Participant may not assign its rights and obligations under this Agreement to
any party at any time. This Agreement will <span class=SpellE>enure</span> to
the benefit of and will be binding on <span class=SpellE>NuEnergy</span> and
its respective successors and permitted assigns. In the event of corporate
merger, amalgamation, divestiture, or asset sale involving <span class=SpellE>NuEnergy</span>,
<span class=SpellE>NuEnergy</span> will have the right to transfer and assign
its rights and obligations hereunder to any third party (the “<b
style='mso-bidi-font-weight:normal'>Assignee</b>”), upon written notice to
Participant, provided that <span class=SpellE>NuEnergy</span> causes the
Assignee to agree in writing to all the terms and conditions contained in this
Agreement.<b style='mso-bidi-font-weight:normal'><o:p></o:p></b></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level1 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>9<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>Term
and Termination<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>9.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><w:Sdt SdtTag="goog_rdk_15" ID="-97652539"></w:Sdt><u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>Term</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
The term shall commence upon the Effective Date and shall continue until
terminated by either Party upon sixty (60) days’ written notice to the other
Party.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>9.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Termination</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
Participant acknowledges that <span class=SpellE>NuEnergy</span> is entitled,
during a period of investigation by <span class=SpellE>NuEnergy</span>, to
suspend Participant and User access to the Software where <span class=SpellE>NuEnergy</span>
suspects that Participant and/or a User through Participant have not complied
with this Agreement, including compliance with payment of fees under paragraph
2.2(c) hereof, and further that <span class=SpellE>NuEnergy</span> may
terminate access to Participant and all Users through <span class=SpellE>NuEnergy</span>
where such non-compliance is established to the sole satisfaction of <span
class=SpellE>NuEnergy</span>.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-36.0pt;mso-list:l0 level2 lfo1;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><b style='mso-bidi-font-weight:
normal'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><span style='mso-list:Ignore'>9.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Effect of Termination</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
Upon expiration of the Agreement:<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(a) <span
class=SpellE>NuEnergy</span> shall immediately cease providing access to the
Software and all usage rights granted under section 2 shall <span class=GramE>terminate;</span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(b) upon
subsequent written request by the disclosing Party, the receiving Party of
Confidential Information shall immediately return such information or destroy such
information where possible and shall provide written certification of such
destruction, provided that the receiving Party may permit its legal counsel to
retain one archival copy of such information in the event of a subsequent
dispute between the <span class=GramE>Parties;</span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial'>(c) upon
termination under subsection 9.2, all remaining obligations of <span
class=SpellE>NuEnergy</span> to Participant with respect to the Software shall
immediately and irrevocably end.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>9.4<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Price Increases</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
<span class=SpellE>NuEnergy</span> may increase the fees payable by Participant
at any time with sixty (60) days’ written notice to Participant.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>9.5<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Survival</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
Sections 2.3, 2.5, 2.7, and 3-8 shall survive the expiration or termination of
the Agreement for any reason, as well as any other sections whose survival is
necessary to <span class=GramE>effect</span> the purpose of this
agreement.<span style='mso-spacerun:yes'>  </span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level1 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>10<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>Miscellaneous<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><b style='mso-bidi-font-weight:normal'><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>10.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Section Headings</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
The section and paragraph headings used herein are for convenience only and are
not a part of this Agreement and shall not be used in construing this
Agreement.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>10.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Entire Agreement</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
This Agreement constitutes the entire agreement between the Parties, and no
amendment to the terms of this Agreement will be effective unless in writing
and signed by both Parties hereto.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>10.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Equitable Relief</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
Participant agrees that any breach of this Agreement by Participant would cause
irreparable damage, and that, in the event of such breach, in addition to <span
class=GramE>any and all</span> remedies at law, <span class=SpellE>NuEnergy</span>
will have the right to an injunction, specific performance, or other equitable
relief to prevent the continuous violations of the terms of this Agreement.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>10.4<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Force Majeure</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
Notwithstanding anything herein to the contrary, <span class=SpellE>NuEnergy</span>
shall not be liable for any delay or failure in performance caused by a Force
Majeure.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>10.5<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Relationship of the Parties</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
This Agreement does not constitute a partnership or joint venture, and nothing
herein is intended to constitute, nor will it be construed to constitute, such
a partnership or joint venture. Except as expressly provided in this Agreement,
neither Participant nor <span class=SpellE>NuEnergy</span> will have any power
or authority to act in the name or on behalf of the other Party or to bind the
other Party to any legal agreement.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>10.6<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Severability</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
The provisions of this Agreement are each to be considered separately, and if
any provision hereof should be found by any court of competent jurisdiction to
be invalid or unenforceable, this Agreement will be deemed to have effect as if
such provision were severed from this Agreement.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>10.7<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Notices</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
All notices and communications required or permitted under this Agreement will
be in writing and will be sent by registered or certified mail, postage
prepaid, return receipt requested, with confirmed answer back, or electronic
mail (email), with confirmation of receipt, to <span class=SpellE>NuEnergy</span>
or Participant at such respective addresses as <span class=SpellE>NuEnergy</span>
and Participant may from time to time specify and as acknowledged by the other
Party. A notice given in electronic form in accordance with the foregoing shall
be admissible in judicial or administrative proceedings based upon or relating
to this Agreement to the same extent and subject to the same conditions as
other business documents and records originally generated and maintained in
printed form.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>10.8<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Waiver</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
A waiver of any part of this Agreement shall only be effective if written and
signed by the Party entitled to waive that part. The waiver, of a breach or
otherwise, of any term of this Agreement by a Party shall in no way be
construed as a waiver of any other term or other breach hereof.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>10.9<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Jurisdiction</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
Subject to the dispute resolution provisions set out in sections 7.2 and 7.3 of
this Agreement, the Parties hereby irrevocably attorn to the exclusive
jurisdiction of the courts of the Province of Ontario with respect to any
dispute arising hereunder.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>10.10<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></b><![endif]><u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>Governing
Law</span></u><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'>. This Agreement and any sales hereunder shall be deemed to
have been made in the Province of Ontario and shall be construed and
interpreted according to the laws of the Province of Ontario and the applicable
laws of Canada.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;mso-list:l0 level2 lfo1;
border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><b
style='mso-bidi-font-weight:normal'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'><span style='mso-list:Ignore'>10.11<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></b><![endif]><u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>Counterparts</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
This Agreement may be executed in two (2) counterparts, both of which taken
together shall constitute one (1) single agreement between the Parties. The
Parties hereto agree that a version of this Agreement transmitted by means of
electronic message or electronic record (electronic mail or electronic data
interchange), once duly signed by the authorized representatives of each Party,
shall constitute a binding <span class=GramE>agreement</span> and shall have
the same force and effect as a document bearing original signatures.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-36.0pt;mso-list:l0 level2 lfo1;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><![if !supportLists]><b style='mso-bidi-font-weight:
normal'><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
Arial;color:black'><span style='mso-list:Ignore'>10.12<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><u><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black'>Publicity</span></u><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;color:black'>.
<span class=SpellE>NuEnergy</span> may use Participant’s name, logo, and
trademark for sales and promotional purposes.<o:p></o:p></span></p>

<span style='font-size:11.0pt;line-height:115%;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;mso-ansi-language:EN-CA;mso-fareast-language:
EN-CA;mso-bidi-language:AR-SA'><br clear=all style='page-break-before:always'>
</span>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial'><span style='mso-spacerun:yes'> </span><o:p></o:p></span></p>




</div>

</body>

`;

export { agreementHTML };
