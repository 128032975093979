import React, { useEffect } from 'react';
import { disableDrawer, enableDrawer } from '../../app/features/ui/slices/navigation.slice';
import { useDispatch } from '../../app/hooks';


const AddOnDrawer = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(disableDrawer())

    return () => {
      dispatch(enableDrawer())
    }

  }, [dispatch])
  
  return (<></>)
}

export default AddOnDrawer;