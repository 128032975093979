import React, { useEffect} from 'react';
import { Drawer } from '../../../app/components';
import { selectSelectedProject } from '../../../app/features/project/project.slice';
// import { Project } from 'machine-trust-platform';
import { ORGANIZATION, MTPPath } from '../../../constants';
import { useDispatch, useSelector } from '../../../app/hooks';
import { enableDrawer, selectDrawerIsDisabled } from '../../../app/features/ui/slices/navigation.slice';
import { Aims_version } from 'machine-trust-platform';


const { TIER, AIMS_VERSION } = ORGANIZATION


const SelectedProjectDrawer = () => {
  const dispatch = useDispatch()
  const project = useSelector(selectSelectedProject)
  const basePath = `${MTPPath.PROJECTS}/${project?.name}`



  // useEffect(() => {
  //   if (project) dispatch(setDrawerSubTitle(project.name as string))

  //   return () => {
  //     dispatch(setDrawerSubTitle(""))
  //   }
  // }, [dispatch, project])

  const drawerIsDisabled = useSelector(selectDrawerIsDisabled)

  useEffect(() => {
    if (drawerIsDisabled) dispatch(enableDrawer())

  }, [dispatch, drawerIsDisabled])

  return (
    <>
      {/* {!project && <Redirect to={MTPPath.PROJECTS} />} */}

      <Drawer.Link  // Dashboard
        basePath={basePath}
        path={`${basePath}`}
        label="dashboard.label"
        title="dashboard.label"
        tier={TIER.ALL}
      />

      <Drawer.Dropdown // AI Models Scorecard (AIMS)
        label="ai.models.scorecard.label"
        title="ai.models.scorecard.label"
        tier={TIER.ALL}
      >
        <Drawer.Link  // AIMS Questionnaire
          basePath={basePath}
          path={`${basePath}/aims/questionnaire`}
          label="questionnaire.label"
          title="aims.questionniare.title"
          tier={TIER.ALL}
        />


        <Drawer.Link  // AIMS Scorecard
          basePath={basePath}
          path={`${basePath}/aims/scores`}
          label="scores.label"
          title="aims.score.title"
          tier={TIER.ALL}
        />
      </Drawer.Dropdown>
      <Drawer.Link_Framework  // Bitrapy Questionnaire
          basePath={basePath}
          path={`${basePath}/bitrapy`}
          label="BiTraPy Tool"
          title="BiTraPy Tool"

          aims_version={["ised" as Aims_version, "rcmp" as Aims_version,  "pspc" as Aims_version]}
        />

      <Drawer.Link // pre-AIA
        basePath={basePath}
        path={`${basePath}/preAIA`}
        label="preAIA.label"
        title="preAIA.label"
        tier={TIER.DIAMOND}
      />
        

      <Drawer.Link // Algorithmic Impact Assessment
        id="projects-algorithmic-impact-assessment-drawer-btn"
        basePath={basePath}
        path={`${basePath}/algorithmic-impact-assessment`}
        label="aia.label"
        title="algorithmic.impact.assessment.label"
        tier={TIER.ALL}
      />

      <Drawer.Link_Framework // NTop
        basePath={basePath}
        path={`${basePath}/ntop`}
        label="ntop.label"
        title="ntop.label"

        aims_version={["rcmp" as Aims_version]}
      />

      <Drawer.Link // DecisionTree
        basePath={basePath}
        path={`${basePath}/decisiontree`}
        label="decisiontree.label"
        title="AGA.full.label"
        tier={TIER.DIAMOND}
      />
      <Drawer.Dropdown // AI Models Scorecard (AIMS)
        label="tools.label"
        title="tools.label"
        tier={TIER.PLATINUM}
      >
        
        <Drawer.Link  // AIMS Questionnaire
          basePath={basePath}
          path={`${basePath}/tools`}
          label="metrics.label"
          title="metrics.label"
          tier={TIER.PLATINUM}
        />
        <Drawer.Link  // AIMS Scores
          basePath={basePath}
          path={`${basePath}/tools/requests`}
          label="requests.label"
          title="requests.label"
          tier={TIER.PLATINUM}
        />
      </Drawer.Dropdown>

      <Drawer.Link_Framework // Framework
        basePath={basePath}
        path={`${basePath}/framework`}
        label="framework.label"
        title="framework.label"

        aims_version={["ised" as Aims_version, "rcmp" as Aims_version, "pspc" as Aims_version]} 
      />      

    </>
  )
}

export default SelectedProjectDrawer;