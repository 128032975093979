import React from "react";
import Spinner from "../Spinner";
import styles from "./LoadingPage.module.css";

interface Props {
  loading: boolean;
}

const LoadingPage = ({ loading = false }: Props) => {
  return (
    <div className={styles.authBackground}>
      <div
        className={styles.loadingBackground}
        style={{ display: loading ? "flex" : "none" }}
      >
        <Spinner />
      </div>
    </div>
  );
};

export default LoadingPage;
