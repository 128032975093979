import { Method } from "axios";
import config from "../../config.json";
import { Organization, User } from "machine-trust-platform";
import * as api from "../../utils/api";
import type { AuthTokens } from "./types";

/** Exchange Code For Tokens
 * @param code - Which can be parsed from the URLSearchParams after a successful login using the IdP (Identity Provider)
 * @returns {Promise<Response>}
 */
export const exchangeCodeForTokens = (code: string) => {
  return fetch(`https://${config.auth_domain}/oauth2/token`, {
    method: "POST",
    body: `grant_type=authorization_code&client_id=${process.env.REACT_APP_CLIENT_ID}&code=${code}&redirect_uri=${window.location.protocol}//${window.location.host}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then(
      (response) => response.json(),
      (error) => {
        throw error;
      }
    )
    .then((authTokens) => {
      if (authTokens.error) {
        throw authTokens.error;
      }
      return authTokens as AuthTokens;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Refresh Auth Tokens
 * @param {AuthTokens} authTokens
 * @returns {Promise} - Responds with refreshed AuthTokens
 * @throws {any} - Throws errors
 */
export const _refreshTokens = (refresh_token: string) => {

  return fetch(`https://${config.auth_domain}/oauth2/token`, {
    method: "POST",
    body:
      `grant_type=refresh_token&` +
      `client_id=${process.env.REACT_APP_CLIENT_ID}&` +
      `refresh_token=${refresh_token}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then(
      (response) => response.json(),
      (error) => {
        throw error;
      }
    )
    .then((responseAuthTokens) => {
      if (responseAuthTokens.error) {
        throw responseAuthTokens.error;
      }
      return { refresh_token, ...responseAuthTokens } as AuthTokens;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Validate the User and Organization
 * @param {string} idToken
 * @returns { User, Organization}
 */
export const _validateUserAndOrganization = async (idToken: string) => {
  try {
    const response = await api.client("/login", idToken);
    return response.data as { user: User; organization: Organization };
  } catch (err) {
    throw err;
  }
};

export const _updateUserConsent = async (
  idToken: string,
  state: "accepted" | "declined"
) => {
  const options = {
    config: {
      method: "POST" as Method,
    },
    params: { state: state },
  };
  try {
    const response = await api.client("/consent", idToken, options);
    return response.data as { state: "accepted" | "declined"; date: string };
  } catch (err) {
    throw err;
  }
};
