import {
  createAsyncThunk,
  createSlice,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { client } from "../../utils/api";

import type { Project } from "machine-trust-platform";
import { Method } from "axios";

interface ProjectState {
  list: Array<Project>;
  selected?: Project;
  userLastModTime: number;
  lastListFetchTime: number;
  status: "idle" | "loading" | "failed";
}

const initialState: ProjectState = {
  list: [],
  selected: undefined,
  userLastModTime: -1,
  lastListFetchTime: -1,
  status: "idle",
};

/* -------------------------- Async Thunk --------------------------*/

const getProject = createAsyncThunk(
  "get/project",
  async (projectUuid: string, thunkApi) => {
    const idToken = (thunkApi.getState() as RootState).auth.tokens
      ?.id_token as string;

    try {
      const response = await client(`/project/${projectUuid}`, idToken);
      return response.data;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  }
);

// This gets the data for scorecards
const getAllProjects = createAsyncThunk("all/projects", async (_, thunkApi) => {
  const idToken = (thunkApi.getState() as RootState).auth.tokens
    ?.id_token as string;

  try {
    const response = await client("/project/", idToken);
    return response.data;
  } catch (error) {
    thunkApi.rejectWithValue(error);
  }
});

interface SubmitAIAArgs {
  project?: Project;
  aia: any;
}

const submitProjectAIA = createAsyncThunk(
  "submit/algorithmic-impact-assessment",
  async ({ project, aia }: SubmitAIAArgs, thunkApi) => {
    const idToken = (thunkApi.getState() as RootState).auth.tokens
      ?.id_token as string;

    const options = { 
      data: aia, 
      config:{
        method: "POST" as Method 
      }
    };

    try {
      const response = await client(
        `/project/${project?.uuid}/aia`,
        idToken,
        options
      );
      return response.data as Project;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  }
);

type NewProject = Pick<Project, "name" | "description">;

const addProject = createAsyncThunk(
  "add/project",
  async (newProject: NewProject, thunkApi) => {
    const idToken = (thunkApi.getState() as RootState).auth.tokens
      ?.id_token as string;

    try {
      const options = {
        data: newProject,
        config:{
          method: "POST" as Method
        }
      };

      const response = await client("/project/", idToken, options);
      return response.data as Project;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  }
);

const deleteProject = createAsyncThunk(
  "delete/project",
  async (projectUuid:string, thunkApi) => {
    const idToken = (thunkApi.getState() as RootState).auth.tokens
      ?.id_token as string;

    const options = {
      config:{
        method: "DELETE" as Method
      }
    };

    try {
      const response = await client(`/project/${projectUuid}`, idToken, options);
      return response.data as string;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  }
);

const editProject = createAsyncThunk(
  "edit/project",
  async (project: Partial<Project>, thunkApi) => {
    const idToken = (thunkApi.getState() as RootState).auth.tokens
      ?.id_token as string;

    const options = {
      data: project,
      config:{
        method: "POST" as Method
      }
    };

    try {
      const response = await client(`/project/${project.uuid}`, idToken, options);
      return response.data as Project;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  }
);

/* -------------------------- App Thunk --------------------------*/

// ...

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      state.status = action.payload;
    },
    setSelectedProject: (state, action: PayloadAction<Project>) => {
      state.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      /*-------------- Get Project --------------  */

      /*-------------- Get All Projects --------------  */
      .addCase(
        getAllProjects.fulfilled,
        (state, action: PayloadAction<Project[]>) => {
          state.list = action.payload || [];
          state.lastListFetchTime = new Date().getTime();
          state.status = "idle";
        }
      )

      .addCase(submitProjectAIA.fulfilled, (state, action) => {
        const project = action.payload;
        if (project) {
          state.list = state.list.map((p) =>
            p.uuid === project.uuid ? project : p
          );
          state.selected = project;
          state.userLastModTime = new Date().getTime();
        }
      })

      .addCase(addProject.fulfilled, (state, action) => {
        const project = action.payload as Project;
        state.list = [...(state.list || []), project];
        state.selected = project;
        state.lastListFetchTime = new Date().getTime();
      })

      .addCase(deleteProject.fulfilled, (state, action) => {
        const projectUuid = action.payload as string;
        state.list = state.list.filter((p) => p.uuid !== projectUuid);
        state.selected =
          state.selected && state.selected.uuid === projectUuid
            ? undefined 
            : state.selected;
        state.lastListFetchTime = new Date().getTime();
      })

      .addCase(editProject.fulfilled, (state, action) => {
        const project = action.payload as Project;
        state.list = state.list?.map((p) => p.uuid === project.uuid ? project : p)
        state.lastListFetchTime = new Date().getTime();
      })

      .addMatcher(isPending, (state) => {
        state.status = "loading";
      })
      .addMatcher(isRejected, (state) => {
        state.status = "failed";
      });
  },
});

// Scorecard State Actions
export const { updateStatus, setSelectedProject } = projectSlice.actions;
export {
  getProject,
  getAllProjects,
  submitProjectAIA,
  addProject,
  deleteProject,
  editProject,
};

// Selectors
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectProjectStateStatus = (state: RootState) =>
  state.project.status;
export const selectSelectedProject = (state: RootState) =>
  state.project.selected;
export const selectProjectList = (state: RootState) => state.project.list;

export const selectProjectListFetchTime = (state: RootState) =>
  state.project.lastListFetchTime;

export default projectSlice.reducer;
