import { ToolRequest } from "machine-trust-platform";



/**
 * Description
 * @param {number} id
 * @returns {any}
 */
export const getToolNameFromID = (id: number) => {
  switch (id) {
    case 1:
      return "AIF360";
    case 2:
      return "FairlearnML";
    default:
      return "Unknown"
  }
}


/**
 * Description
 * @param {number} id
 * @returns {any}
 */
export const getToolAPIFromID = (id: number) => {
  switch (id) {
    case 1:
      return "AIF360";
    case 2:
      return "ML";
    default:
      return "Unknown"
  }
}



/**
 * Description
 * @param {ToolRequest} request
 * @returns {any}
 */
export const getToolRequestStatus = (request: ToolRequest) => {
  const { created, processingStarted, processingFinished } = request
  
  if (created && !processingStarted) {
    return 'pending.label'
  } else if (created && processingStarted && !processingFinished) {
    return 'processing.label'
  } else if (created && processingStarted && processingFinished) {
    return 'complete.label'
  }
}