/**
 * Gets all stored cookies as a dictionary
 * @returns {any} - the dictionary of stored cookies
 */
export const getCookies = () => {
  return document.cookie.split(";").reduce((o: { [key: string]: any }, s) => {
    const nameValue: string[] = s.split("=").map((t) => t.trim());
    o[nameValue[0]] = nameValue[1];
    return o;
  }, {});
};

/**
 * Get a specific cookie from storage and returns the value of the cookie
 * @param {string} name
 * @returns {any}
 */
export const getCookie = (name: string) => getCookies()[name];

/**
 * Stores a cookie
 * @param {string} name - the name of the cookie
 * @param {any} value - The value to be stored in cookie storage
 * @param {Date|number} expires? - the expiry of the cookie
 * @param {string='/'} path - the path of the cookie
 * @returns {any}
 */
export const setCookie = (
  name: string,
  value: any,
  expires?: Date | number,
  path: string = "/"
) => {
  // The initial cookie string
  let cookieString = `${name}=${value}; path=${path}; secure;`;

  // Add the expiry to the Cookie String
  if (expires) {
    let _expires = typeof expires === "number" ? new Date(expires) : expires;
    cookieString = cookieString.concat(`expires=${_expires.toUTCString()};`);
  }

  document.cookie = cookieString;
};

/**
 * Deletes a Cookie from storage
 * @param {string} name - the name of the cookie to be deleted
 */
export const removeCookie = (name: string) => {
  document.cookie = `${name}=; expires=${new Date(0).toUTCString()}; path=/;`;
};
