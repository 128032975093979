import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import uiStateReducer from "./features/ui/slices";
import authReducer from "./features/auth/auth.slice";
import datasetReducer from "./features/dataset/dataset.slice";
import scorecardReducer from './features/scorecard/slices'
import projectReducer from "./features/project/project.slice";
import toolsReducer from "./features/tools/slices";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    project: projectReducer,
    scorecard: scorecardReducer,
    uiState: uiStateReducer,
    tools: toolsReducer,
    dataset: datasetReducer,
    
  },
  middleware: (getDefaultMiddleware) =>
    // TODO: Clean this up
    // !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      // ? getDefaultMiddleware()
    getDefaultMiddleware(),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
