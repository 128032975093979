import React from 'react';
import { DemoContainer } from '..';


 
const contactSupport = {
  fontSize: '20px',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
};

const ConfigureOnRequest = () => {
  return (
    <DemoContainer>
      <p>Configurable on request.</p>
      <a style={contactSupport} href="https://www.nuenergy.ai/contact/" className="btn btn-md align-self-end">
        <p>Contact support@nuenergy.ai</p>
      </a>
    </DemoContainer>
  );
};

export default ConfigureOnRequest;