import AIA from './AIA.screen';
import ALTAI from './ALT.AI.screen';
import CIOSC from './CIOSC.screen';
import IEEE from './IEEE.screen';
import Waterloo from './Waterloo.screen';
import Singapore from './Singapore.screen';

const standardScreens = {
  AIA,
  ALTAI,
  CIOSC,
  IEEE,
  Waterloo,
  Singapore,
}


export default standardScreens;