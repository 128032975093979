import MTPGraphic from "../../assets/images/MTP-Graphic.png";
import styles from "./home.screen.module.scss"

const HomeScreen = () => {

  return (
    <>
    <div className ={`${styles.container}`}>
        <div className = {`${styles.text}`}>
          <h1 className = {`${styles.header}`}>Welcome to the Machine Trust Platform (MTP)</h1>
          <p className = {`${styles.content1}`}>This platform is designed to empower AI Governance oversight administrators, as well as software developers of AI solutions, to assess AI technologies against key risk-mitigating metrics, third-party frameworks, and standards to help ensure governance, ethical and regulatory guidelines are met. It offers flexibility and scope for configurations to ensure you have compatibility to enhance trust in your AI technologies. </p>
          <p className = {`${styles.content2}`}>For help and support, please contact us at: </p>
          <p className = {`${styles.content3}`}>support@nuenergy.ai</p>
        </div>
    </div>
    <div className = {`${styles.MTPimg}`}>
          <img src={MTPGraphic} alt='MTP' />
        </div>
    </>
  )
}

export default HomeScreen;