import React from 'react';

interface Props {
  iconClass: string;
  className?: string;
  reverse?: boolean;
};

const CircleIcon = ({ iconClass, className = '', reverse = false }: Props) => {
  return (
    <div className={`${reverse ? 'circle-icon-reverse' : 'circle-icon'} ${className}`}>
      <i className={iconClass} />
    </div>
  );
};


export default CircleIcon;
