import React from 'react';
import { ConfigurableOnRequest } from '../../../components'

const CIOSCScreen = () => {

  return (
    <>
      <ConfigurableOnRequest />
    </>
    )
}

export default CIOSCScreen