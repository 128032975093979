import { differenceInMinutes } from 'date-fns';
import { Scorecard } from 'machine-trust-platform';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SCORECARD } from '../../../../../constants';
import TabbedContainer from '../../../../components/TabbedContainer';
import { useDispatch, useSelector } from '../../../../hooks';
import { getAllScorecardScore, selectScorecardScoreLastFetchTime, selectScorecardScoreList, selectScorecardScoreStatus } from '../../slices/score.slice';
import BenchmarkDisplay from '../BenchmarkDisplay';
import SelfAssessmentContent from '../SelfAssessmentScore';

interface Props {
  type: 'AIGS' | 'AIMS' | 'BITRAPY';
  scorecard: Scorecard
}

const ScoreDisplay = ({ type, scorecard }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const scoreList = useSelector(state => selectScorecardScoreList(state, scorecard.uuid)) || undefined
  const lastFetchTime = useSelector(state => selectScorecardScoreLastFetchTime(state, scorecard.uuid)) || -1

  const loading = useSelector((state) => selectScorecardScoreStatus(state, scorecard.uuid)) === 'loading' || lastFetchTime === -1

  // Parse the list of Approved Scores for the most recently modified score.
  // Note: Every time the scores list is updated it will be sorted by the most recently modified. 
  const mostRecentScorecardScore =
    scorecard && scoreList
      ? scoreList.filter((s) => s.state === SCORECARD.SCORE.STATE.APPROVED)[0] || undefined // make sure the scorecard has answers (later change this to Complete)
      : undefined;

  const data =
    mostRecentScorecardScore && mostRecentScorecardScore.requirements
      ? mostRecentScorecardScore.requirements.map((requirement_score) => ({
        name: requirement_score.requirement.name,
        description: requirement_score.requirement.description,
        uuid: requirement_score.requirement.requirement_uuid,
        score: requirement_score.score >= 0 ? requirement_score.score : 0,
        weight: requirement_score.weight,
      }))
      : undefined;
  const overallScore = mostRecentScorecardScore ? mostRecentScorecardScore.score : undefined;
  const requirements = mostRecentScorecardScore && mostRecentScorecardScore.requirements ? mostRecentScorecardScore.requirements.map((r) => r.requirement.name) : null;

  useEffect(() => {
    if (lastFetchTime === -1 || differenceInMinutes(new Date(lastFetchTime), new Date()) >= 5) {
      dispatch(getAllScorecardScore(scorecard.uuid))
    }
  }, [dispatch, scorecard, lastFetchTime])

  


  return (
    <TabbedContainer
      content={[
        {
          tab: t('self.assessment.label'),
          jsx: <SelfAssessmentContent data={data} overallScore={overallScore} requirements={requirements} scorecardUuid={scorecard ? scorecard.uuid : undefined} scoreUuid={mostRecentScorecardScore ? mostRecentScorecardScore.uuid : undefined} type={type} loading={loading} />,
        },

        {
          tab: t('benchmark.label'),
          jsx: <BenchmarkDisplay scorecardType={scorecard.type} score={overallScore}/>,
        }

      ]} />
  );
};

export default ScoreDisplay;