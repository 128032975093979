import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as auth from "./auth.helpers";
import * as api from "./auth.api";
import { RootState, AppThunk } from "../../store";
import { User, Organization } from "machine-trust-platform";
import type { AuthTokens } from "./types";
/* eslint-disable @typescript-eslint/no-unused-vars */
import type { AxiosError } from "axios";

export interface AuthState {
  tokens?: AuthTokens;
  user?: User;
  organization?: Organization;
  status: "idle" | "loading" | "failed";
}

const initialState: AuthState = {
  tokens: undefined,
  user: undefined,
  organization: undefined,
  status: "loading",
};

/* -------------------------- Async Thunk --------------------------*/

const updateConsent = createAsyncThunk(
  "update/consent",
  async (state: "accepted" | "declined", thunkApi) => {
    const idToken = (thunkApi.getState() as RootState).auth.tokens
      ?.id_token as string;
    try {
      const updatedConsent = await api._updateUserConsent(idToken, state);
      return updatedConsent;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);

/* -------------------------- App Thunk --------------------------*/
const authenticate = (): AppThunk => async (dispatch, getState) => {
  /**
   *  Here we try to pull the auth tokens from the various sources
   *  1. Global state
   *  2. Cookies
   *  3. URLSearchParams
   *      - this happens right after the user has been redirected back from the
   *        login window
   *
   *  If all attempts fail then we redirect to the authentication page and request the user to login
   *
   */


  // Get the tokens from the global state
  let authTokens = (getState() as RootState).auth.tokens || {
    id_token: undefined,
    access_token: undefined,
    refresh_token: undefined,
  };

  // Used to determine if the url search params need to be cleared
  let shouldClearUrlParams = false;

  while (true) {
    // Get tokens from cached cookies
    authTokens = auth.getCachedTokens();
    if (authTokens.id_token) break;

    /**
     * If the ID and Access Tokens have expired we may still have the refresh token.
     * The ID & Access Tokens have an expiry of 60 minutes. The Refresh Token Expires
     * in 24hours (Check with the Cognito User pool to verify this hasn't been changed).
     */
    if (
      !authTokens.id_token &&
      !authTokens.access_token &&
      authTokens.refresh_token
    ) {
      try {
        authTokens = await api._refreshTokens(authTokens.refresh_token);
      } catch (error) {
        // Error on token refresh, clear all tokens
        auth.clearCachedTokens();
        auth._redirectToIDP();
        break; // This is just to make TypeScript happy
      }
    }

    /**
     * Try URL Search Params
     * This happens after we've been redirected from the login page (auth.domain).
     * ?code=<Code Value>&state=<State Value>
     */
    const params = new URLSearchParams(window.location.search);
    const code = params.has("code") ? params.get("code") : null;
    const state = params.has("state") ? params.get("state") : null;

    const authState = auth.getCachedAuthState();

    if (code && state === authState) {
      authTokens = await api.exchangeCodeForTokens(code);
    }
    if (authTokens.id_token) {
      // Cache the refresh token for 24 hours.
      if (authTokens.refresh_token)
        auth.cacheRefreshToken(authTokens.refresh_token);
      shouldClearUrlParams = true;
      break;
    }

    // No auth tokens were found
    // Note: This will break the loop
    auth._redirectToIDP();
    break; // This is just to make TypeScript happy
  }

  // Validate the tokens or request the user to re-authenticate themselves
  try {
    // If validateTokens throws the error the else is
    await auth._validateTokens(authTokens as Required<AuthTokens>);
    auth.cacheTokens(authTokens as Required<AuthTokens>);

    const { user, organization } = await api._validateUserAndOrganization(
      authTokens.id_token as string
    );
    dispatch(login({ user, organization, authTokens }));
  } catch (error: any | AxiosError) {
    if (error.isAxiosError) {
      // validateUserAndOrganization threw the error

      // This will happen if there is an error on the server side (So make sure it's running :) ).
      // Setting the Auth Status to failed will cause cause the Error Boundary to Trigger. The Exception has to be
      // thrown outside of a AppThunk, and is done in App.tsx.
      dispatch(setStatus("failed"));
    } else {
      // validateTokens threw the error
      auth._redirectToIDP();
    }
  }

  // Clear up the search parameters
  if (shouldClearUrlParams)
    window.history.pushState(null, "Machine Trust Platform", "/");
};

/**
 * Logout.
 * Clears Cookies and resets to initial state
 * @returns void
 */
const logout = (): AppThunk => async (dispatch, _) => {
  auth.clearCachedTokens();
  dispatch(clearState());

  setTimeout(() => {
    auth._redirectLogoutToIDP()
  }, 300)
};

export const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    clearState: (state) => {
      state.organization = undefined;
      state.user = undefined;
      state.tokens = undefined;
      state.status = 'loading'

    },
    setAuth: (state, action) => {
      state = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },

    login: (state, action) => {
      state.tokens = action.payload.authTokens;
      state.user = action.payload.user;
      state.organization = action.payload.organization;
      state.status = "idle";
    },

    /* ---------- Tokens ---------- */
    setAuthTokens: (state, action) => {
      state.tokens = action.payload;
      state.status = "idle";
    },

    /* ---------- User ---------- */
    setUser: (state, action) => {
      state.user = action.payload.user;
    },

    /* ---------- Organization ---------- */
    setOrganization: (state, action) => {
      state.organization = action.payload.organization;
    },
  },
  extraReducers: (builder) => {
    builder
      /* ---------- User ---------- */
      .addCase(updateConsent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateConsent.fulfilled, (state, action) => {
        state.status = "idle";
        if (state.user && action.payload) {
          state.user.consent = action.payload;
        }
      })
      .addCase(updateConsent.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// Authentication Actions
export const {
  setAuthTokens,
  setUser,
  setOrganization,
  login,
  setStatus,
  clearState
} = authSlice.actions;
export { authenticate, logout, updateConsent};

// Selectors
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuthStatus = (state: RootState) => state.auth.status;
export const selectAuthTokens = (state: RootState) => state.auth.tokens;
export const selectUser = (state: RootState) => state.auth.user;
export const selectRole = (state: RootState) => state.auth.user?.roles // TODO: change roles such that it is not an array it is just a single role
export const selectOrganization = (state: RootState) => state.auth.organization;
export const selectTier = (state: RootState) => state.auth.organization?.tier;
export const selectAimsVersion = (state: RootState) => state.auth.organization?.aims_version;
export const selectAIGSScorecardUuid = (state: RootState) => state.auth.organization?.scorecardUuid;

export default authSlice.reducer;

