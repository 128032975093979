import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import { MTPPath } from "../../../constants";
import { setDrawerSubTitle } from "../../../app/features/ui/slices/navigation.slice";
import { selectSelectedProject } from "../../../app/features/project/project.slice";
import { getScorecard, setSelectedScorecard } from "../../../app/features/scorecard/slices/scorecard.slice";
import { useDispatch, useSelector } from "../../../app/hooks";
import Screens from "../../../app/screens";
import ToolsRoute from "../../Tools.route";

import AIMS from "./AIMS.route";
import  BITRAPY from "./Bitrapy.route"

const SelectedProjectRoute = () => {
  const dispatch = useDispatch()
  const { url } = useRouteMatch();

  // Get the selected Project so we can make sure that there is
  // in bitrapy a project that has been selected
  const project = useSelector(selectSelectedProject)
  const scorecardUuid = project?.scorecardUuid


  useEffect(() => {
    // Get specific Scorecard
    // By default this sets the scorecard returned from the api call
    // to selected. Meaning you can  safely assume that the selected 
    // scorecard is AIMS in any of the routes defined below
    if(scorecardUuid) dispatch(getScorecard(scorecardUuid))

    return () => {
      // Remove the selected scorecard when the user leaves AI Governance Service
      dispatch(setSelectedScorecard(undefined))
    }

  }, [dispatch, scorecardUuid])

  //
  useEffect(() => {
    if (project) dispatch(setDrawerSubTitle(project.name as string))
    
    return () => {
      dispatch(setDrawerSubTitle(""))
    }
  }, [dispatch, project])



  return (
    <>
      { // If no project has been selected then redirect to the 
        // project management page
        !project && <Redirect to={MTPPath.PROJECTS} />
      }

      <Switch>
        {/* Screens */}
        <Route path={url} exact component={Screens.Project.Dashboard} />

        <Route path={`${url}/algorithmic-impact-assessment`} component={Screens.Project.AIA} />

        <Route path={`${url}/preAIA`} component={Screens.Project.preAIA} />
        
        <Route path={`${url}/ntop`} component={Screens.Project.NTop} />

        <Route path={`${url}/bitrapy`} component={Screens.Project.Bitrapy} /> 
        <Route path={`${url}/decisionTree`} component={Screens.Project.DecisionTree} />
        

        <Route path={`${url}/framework`} component={Screens.Project.Framework} />
        
        <Route path={`${url}/tools`}>
          <ToolsRoute contextUuid={project?.uuid as string} />
        </Route>

        

        {/* Nested Routes */}
        <Route path={`${url}/aims`} >
          {project && <AIMS project={project} />}
          {!project && <Spinner animation={"grow"} />}
        </Route>

        {/* <Route path={`${url}/bitrapy`} >
          {project && <BITRAPY project={project} />}
          {!project && <Spinner animation={"grow"} />}
        </Route> */}
      </Switch>

    </>
  );
};

export default SelectedProjectRoute;