import React from 'react'
import { AlgorithmicAssessmentImpact } from '../../../features/aia/components'
import { selectSelectedProject } from '../../../features/project/project.slice'
import { useSelector } from '../../../hooks'



const AIAScreen = () => {

  const project = useSelector(selectSelectedProject)
  const aia = project && project.algorithmicImpactAssessment ? project.algorithmicImpactAssessment : undefined;

  return (
    <>
    <div>
      <AlgorithmicAssessmentImpact project={project} assessment={aia} />
    </div>
    </>
  )
}

export default AIAScreen