/**
 * File: index.js
 * Project: Machine Trust Platform
 * File Created: Monday, 14th June 2021 3:21:55 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Monday, 14th June 2021 3:21:56 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai>)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import DashboardTutorial from './Dashboard.tutorial';
import QuestionnaireTutorial from './Questionnaire.tutorial';
import ScoresTutorial from './Scores.tutorial';
import CanvasTutorial from './Canvas.tutorial';
import QuestionnaireResultsTutorial from './QuestionnaireResults.tutorial';

const AIGovernanceTutorial = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}`} exact component={DashboardTutorial} />
      <Route path={`${url}/questionnaire/results`} component={QuestionnaireResultsTutorial} />
      <Route path={`${url}/questionnaire`} component={QuestionnaireTutorial} />
      <Route path={`${url}/scores`} component={ScoresTutorial} />
      <Route path={`${url}/canvas`} component={CanvasTutorial} />
    </Switch>
  );
};

export default AIGovernanceTutorial;