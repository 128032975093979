import React from 'react';

import { useSelector } from '../../../../hooks'
import { selectAimsVersion, selectTier } from '../../auth.slice';
import { Aims_version, Tier} from 'machine-trust-platform';

interface Props {
  aims_version: Aims_version[]
  // tier: Tier
  children: React.ReactNode
}

const AimsVersionCheckWrapper = ({ aims_version, children }: Props) => {

  // Get the Tier of the Organization to ensure the user has the rights 
  // to proceed to view/use the content
  // const organizationTier = useSelector(selectTier) as Tier
  const organizationAimsVersion = useSelector(selectAimsVersion) as Aims_version
  const canViewContent = aims_version.includes(organizationAimsVersion) 
  // const canViewContent = aims_version.includes(organizationAimsVersion) && tier <= organizationTier

  return <>{canViewContent && children}</>
}

export default AimsVersionCheckWrapper;