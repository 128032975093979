/* eslint-disable */
/*
 * File: ModernAsterChart.jsx
 * Project: mtp-webui
 * File Created: Friday, 23rd April 2021 3:52:15 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 7th July 2021 12:14:51 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as d3 from 'd3';
import { getColorMap } from '../../utils/visualizations';
import { useD3 } from '../../hooks';

// interface Props {
//   width?: number;
//   height?: number;
//   dataset: Array<DataType>;
//   overallScore: number;
//   categories: Array<string>;
// };

// type DataType = {
//   name: string;
//   score: number;
//   weight: number;
// };

const AsterChart = ({ width = 200, height = 200, dataset, overallScore, categories, handleClick }) => {
  const { t } = useTranslation();
  const data = dataset.filter((d) => d.weight > 0).map((d) => ({ ...d, id: d.name.replaceAll(' ', '-') }));

  const colorMap = getColorMap(categories);

  const ref = useD3((svg) => {
    const rotate = (d) => {
      // const radius = (width / 1.1) / 2.0;
      const rotation = 90 - ((d.startAngle + d.endAngle) / 2 / Math.PI) * 180;
      // setRotation(90 - ((d.startAngle + d.endAngle) / 2 / Math.PI) * 180);
      svg
        .transition()
        .attr('transform', `translate(${width / 2.0}, ${width / 2.0}) rotate(${rotation})`)
        .duration(1000);
      svg.selectAll('text').transition().attr('transform', `rotate(${-rotation})`).duration(1000);
    };

    const radius = width / 1.1 / 2.0;
    const innerRadius = Math.max(0.3 * radius, 46);

    const pie = d3
      .pie()
      .sort(null)
      .padAngle(0.02)
      .value((d) => +d.weight);

    // Arc used for background circle
    const arc1 = d3
      .arc()
      .innerRadius(innerRadius + 5)
      .outerRadius(radius - innerRadius + 10);

    // Arc used when expanding to show relative scores
    const arcLarge = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius((d) => (d.data.score > 0 ? ((radius - innerRadius) * d.data.score) / 100.0 + innerRadius : radius - innerRadius + 15))
      .cornerRadius(3);

    // d3.select('#aster-chart').selectAll('svg').remove();

    svg.attr('transform', `translate(${width / 2}, ${width / 2})`);

    // Setting up defs for shadowing
    const defs = svg.append('defs');

    // black drop shadow
    const filter = defs.append('filter').attr('width', '200%').attr('height', '140%').attr('id', 'drop-shadow'); // shadow id

    filter.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', 2).attr('result', 'blur');
    filter.append('feOffset').attr('in', 'blur').attr('dx', 2).attr('dy', 2).attr('result', 'offsetBlur');
    filter
      .append('feFlood')
      .attr('in', 'offsetBlur')
      .attr('flood-color', '#3d3d3d') // shadow color
      .attr('flood-opacity', '0.5')
      .attr('result', 'offsetColor');
    filter.append('feComposite').attr('in', 'offsetColor').attr('in2', 'offsetBlur').attr('operator', 'in').attr('result', 'offsetBlur');

    const feMerge = filter.append('feMerge');

    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

    // Path behind the categories
    svg
      .selectAll('.arc-background-2')
      .data(
        pie([
          {
            color: 'black',
            weight: 1,
          },
        ]),
      )
      .enter()
      .append('path')
      .attr('class', 'arc-background-2')
      .attr('id', () => 'arc-background')
      .attr('fill', (d) => d.data.color)
      .attr('d', arc1)
      .style('filter', 'url(#drop-shadow)');

    // Functions used on MouseEvents for d3 paths
    const createToolTip = () => {
      if (d3.select('#aster-tooltip').empty()) {
        d3.select('body').append('div').attr('id', 'aster-tooltip').attr('class', 'aster-tooltip').attr('style', 'position: absolute;').style('visibility', 'hidden');
      }
    };

    const setToolTipVisibility = (str, d) => {
      const asterChartTooltip = d3.select('#aster-tooltip');

      asterChartTooltip.transition().duration(200).style('visibility', str).style('');

      const tooltipLabel = d3.select('#aster-chart-tooltip-label');
      if (tooltipLabel.empty()) {
        asterChartTooltip.append('span').attr('id', 'aster-chart-tooltip-label').attr('style', 'font-weight: 550;');
      }
      d3.select('#aster-chart-tooltip-label').text(`${d.data.name}`);

      const tooltipScore = d3.select('#aster-chart-tooltip-score');
      if (tooltipScore.empty()) {
        asterChartTooltip.append('span').attr('id', 'aster-chart-tooltip-score');
      }
      d3.select('#aster-chart-tooltip-score').text(`${t('score.label')}: ${Math.round(d.data.score)}%`);
    };

    const removeToolTip = () => {
      const asterTooltip = d3.select('#aster-tooltip');
      if (!asterTooltip.empty()) {
        asterTooltip.remove();
      }
    };

    // Category Parts
    svg
      .selectAll('.aster-solid-arc-2')
      .data(pie(data))
      .enter()
      .append('path')
      .attr('fill', (d) => colorMap[d.data.name][0])
      .attr('id', (d) => d.data.id)
      .attr('class', 'aster-solid-arc-2')
      .attr('d', arcLarge)
      .style('cursor', 'pointer')
      .style('filter', 'url(#drop-shadow)')
      .on('click', (e, d) => handleClick ? (() => {
        handleClick(d.data)
        removeToolTip();
      })() : rotate(d))
      .on('mouseover', (e, d) => {
        createToolTip();
        setToolTipVisibility('visible', d);
      })
      .on('mouseleave', () => {
        removeToolTip();
        // updateArc(arc, false)
      })
      .on('mouseout', () => {
        removeToolTip();
        // updateArc(arc, false)
      })
      .on('mousemove', (e) => {
        // updateArc(arcLarge, true)

        d3.select('#aster-tooltip')
          .style('left', `${e.pageX + 20}px`)
          .style('top', `${e.pageY - 20}px`);
      });

    // Center Score and Icon
    svg
      .append('svg:text')
      .attr('dy', '-0.5em')
      .attr('class', 'aster-chart-icon far')
      .style('text-anchor', 'middle')
      .style('font-size', 28)
      .text(() => '\uf080');

    const score = svg.append('svg:text').attr('dy', '0.8em').attr('class', 'aster-score-2').style('text-anchor', 'middle').text(Math.round(overallScore));

    svg
      .append('svg:text')
      .attr('class', 'aster-score-percent')
      .attr(
        'dx',
        // (score.node() as HTMLElement)?.getBoundingClientRect().width / 2 + 10, // For TypeScript
        score.node().getBoundingClientRect().width / 2 + 10,
      )
      .style('text-anchor', 'middle')
      .attr('dy', '0.8em')
      .text(`%`);
  }, []);

  return (
    // <div className="relative">
    <div className="aster-chart-wrapper" style={{ maxWidth: width + width * 0.3 }}>
      <div id="aster-chart" className={`aster-chart ${data.length === 0 ? 'd-none' : ''}`}>
        <svg id="svgID" width={width} height={height}>
          <g ref={ref} />
        </svg>
      </div>
    </div>
    // </div>
  );
};

export default AsterChart;
