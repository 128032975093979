import React from 'react';
import { FairlearnML } from '../../../features/tools/components'

interface Props {
  userId: string;
}

const FairlearnScreen = ({ userId }: Props) => {


  return (
  <>
    <div className={` container`}>
      <FairlearnML.Wizard userId={userId}/>
    </div> 
  </>
 
  )
  
}

export default FairlearnScreen;