import React from 'react'
import Breadcrumb from './Breadcrumb'

import { useLocation } from 'react-router';
import { getArrayOfPathName } from '../../../../utils/urlUtils';

import styles from './breadcrumbs.module.scss'


const Breadcrumbs = () => {
  const location = useLocation()
  const pathArray = getArrayOfPathName(location)

  return (
    <div className={styles.container}>
      {pathArray.map((bc, i) => <Breadcrumb key={`${bc}-${i}`} pathArray={pathArray} index={i} /> )}
    </div>
  )
}

export default Breadcrumbs;