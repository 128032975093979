import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { client } from "../../../utils/api";
import type { Status } from 'machine-trust-platform' 

interface benchmarkState {
  lastFetchTime: number;
  status: Status;
  score?: number;

}

//initial state of benchmark
const initialState: benchmarkState = {
  lastFetchTime: -1,
  status: 'idle',
  score: undefined,
};


/* -------------------------- Async Thunk --------------------------*/

// This action is used to get all the AIGS Scorecard Questions 
const getBenchmark = createAsyncThunk(
  "get/Benchmark",
  async (scorcardType: string, thunkApi) => {
    const idToken = (thunkApi.getState() as RootState).auth.tokens?.id_token as string; //gets the idToken for authentication 

    const options = {
      params: {
        type: scorcardType.toLowerCase()
      }
    }


    try {
      const response = await client(`/scorecard/benchmark`, idToken, options); //makes the api call and stores it in response

      //this is the payload which holds the scorecardUuid and a list with all the questions pull from the api call
  
      return { score: response.data.benchmark } as { score: number }; 
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  }
);

/* -------------------------- App Thunk --------------------------*/
// ...



/* -------------------------- Slice --------------------------*/
export const benchmarkSlice = createSlice({
  name: "ScorecardBenchmarkState",
  initialState,
  reducers: {
        clearBenchmark: (state)=> {
          state.lastFetchTime = -1;
          state.status = 'idle'
          state.score = undefined
        }


  },
  extraReducers: (builder) => {
    builder
      .addCase(getBenchmark.fulfilled, (state, action) => {
        const { score } = action.payload || {}
        state.lastFetchTime = new Date().getTime()
        state.score = score
      })

      .addMatcher(
        isFulfilled,
        (state) => {
          state.status = 'idle'
        }
      )
      .addMatcher(
        isPending,
        (state) => {
          state.status = 'loading'
        }
      )
      .addMatcher(
        isRejected,
        (state) => {
          state.status = 'failed'
        }
      )

  }
})


// Scorecard Score State Actions
export const { clearBenchmark } = benchmarkSlice.actions;
export { getBenchmark };


// Selectors
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
//export const getSelectScorecard = (state: RootState,  scorecardUuid: string) => state.scorecardScores[scorecardUuid] 

export const selectBenchmarkScore = (state: RootState) => state.scorecard.benchmark.score;
export const selectBenchmarkLastFetchTime = (state: RootState)=> state.scorecard.benchmark.lastFetchTime;
export const selectBenchmarkStatus = (state: RootState)=> state.scorecard.benchmark.status;


export default benchmarkSlice.reducer;