
import Container from './Container';
import ProtectedFeatures from './ProtectedFeatures';
import DropFeatures from './DropFeatures';
import Section from './Section'

const Wizard = {

  Container,
  ProtectedFeatures,
  DropFeatures,
  Section
}

export default Wizard;

