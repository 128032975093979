import NtopContent from "./NtopContent.json";
import SurveyForm from "../../../components/SurveyForm"

const NtopContainer = () => { 

  return (
    <div>
      <SurveyForm content={NtopContent} Survey_KEY={'NtopAnswers'} name={"NTOP"} completeButton={false}/>
    </div>
  );
};
  

export default NtopContainer;