import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CDataTable } from '@coreui/react';
import { createDateTimeLabel } from '../../../../utils/time';
import { SCORECARD } from '../../../../../constants';

import { useDispatch, useSelector } from '../../../../hooks';
import { Collapsible } from '../../../../components';

import { Score, Scorecard, ScoreState } from 'machine-trust-platform';

import ScoreDetailsSection from './ScoreDetailsSection/ScoreDetails'
import { updateScorecardScore, deleteScorecardScore, selectScorecardScoreStatus } from '../../slices/score.slice';
import styles from "./scorestable.module.scss";

interface Props { 
  scorecardUuid?: string, 
  path: string, 
  scores?: any[], 
  tableClasses?: string
}

const ScoresTable = ({ scorecardUuid, path, scores, tableClasses = '' }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const loading = useSelector(state => selectScorecardScoreStatus(state, scorecardUuid as string)) === 'loading'

  // This list of details that are open
  const [details, setDetails] = useState<number[]>([]);


  // The users current role
  // const appRoleContext = useSelector((state) => state.uiState.appRoleContext);

  // Add up the total weight of each requirement used to calculate the percentage
  let totalRequirementWeight = scores && scores.length > 0 && scores[0] && scores[0].requirements ? scores[0].requirements.map((r: { weight: number}) => r.weight).reduce((a: number, b: number) => a + b, 0) : 0;

  // This is used to toggle open or closed the details of a specific score
  // It allows for multiple details to be opened.
  const toggleDetails = (index: number) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  /**
   * Here we define the each of the fields for the CDataTable
   */
  const fields = [
    {
      key: 'score',
      _style: { width: '10%', textAlign: 'center' },
      sorter: true,

    },
    { key: 'createdBy', _style: { width: '10%' } },
    { key: 'created', _style: { width: '10%' } },
    { key: 'state', _style: { width: '10%', textAlign: 'center' } },
    {
      key: 'show_details',
      label: '',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
    {
      key: 'delete',
      label: '',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
  ];

  const handleSelectScoreState = (score: Score, state: ScoreState) => {
    dispatch(updateScorecardScore({scorecardUuid, score: {...score, state}}));
  }
  const handleDeleteScore = (score: Score) => {
    const args = {
      scorecardUuid: scorecardUuid as string,
      scoreUuid: score.uuid as string
    }
    dispatch(deleteScorecardScore(args));
  }

  return (
    <div className="scorecard-scores-table"  data-tut="scores-table">
      <CDataTable
        items={scores}
        fields={fields}
        addTableClasses={`${tableClasses} ${styles.scoresTableWrapper}`}
        itemsPerPage={25}
        hover
        size="lg"
        sorter
        loading={loading}
        pagination
        scopedSlots={{
          score: (item: Score, index: number) => <td className="text-center">{Math.round(item.score as number)}</td>,

          // Handles the state of the scorecard
          // TODO: Fix this so that if the score card is in pending the it is a button to approve the score
          state: (item: Score, index: number) => (
            <td className="justify-content-center border-0 bg-transparent">
              {item.state === SCORECARD.SCORE.STATE.REVIEW ? (
                <div className="btn-group drop-left">
                  <button type="button" className={`btn btn-secondary ${styles.pendingButton}`} data-toggle="dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {t(SCORECARD.SCORE.getState(item.state))}
                    <i className="ri-arrow-down-s-fill ri-xl"></i>
                  </button>
                   
                  <div className="dropdown-menu ">
                    {Object.values(SCORECARD.SCORE.STATE).map((s) =>
                      s !== SCORECARD.SCORE.STATE.DRAFT ? (
                        <button className={`dropdown-item ${s === item.state ? 'active' : ''}`} key={`${item.uuid}-${s}-drop-item`} disabled={s === SCORECARD.SCORE.STATE.DRAFT} onClick={() => handleSelectScoreState(item, s)}>
                          {t(SCORECARD.SCORE.getState(s))}
                        </button>
                      ) : null,
                    )}
                  </div>
                </div>
              ) : (
                t(SCORECARD.SCORE.getState(item.state))
              )}
            </td>
          ),
          created: (item: Scorecard) => <td>{createDateTimeLabel(new Date(item.created))}</td>,

          show_details: (item: Score, index: number) => (
            <td>
              {item.state !== SCORECARD.SCORE.STATE.DRAFT ? (
                <button
                  type="button"
                  className="btn btn-secondary"
                  disabled={item.state === SCORECARD.SCORE.STATE.DRAFT}
                  onClick={() => {
                    toggleDetails(index);
                  }}>
                  {details.includes(index) ? 'Hide' : 'Review'}
                </button>
              ) : null}
            </td>
          ),
          details: (item: Score, index: number) => (
            <Collapsible toggle={details.includes(index)}>
              <ScoreDetailsSection scorecardUuid={scorecardUuid} path={path} score={item} totalRequirementWeight={totalRequirementWeight} />
            </Collapsible>
          ),
          delete: (item: Score) => (
            <td className="py-2">
              <button type="button" className="btn btn-danger" onClick={() => handleDeleteScore(item)}>
                <i className="ri-delete-bin-6-line ri-1x"></i>
              </button>
            </td>
          ),
        }}/>
    </div>
  );
};

export default ScoresTable;