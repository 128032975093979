import React from 'react'
import { AlgorithmicAssessmentImpact } from '../../../features/aia/components'


const AIAScreen = () => {

  return (
    <>
    <div>
      <AlgorithmicAssessmentImpact isStandard />
    </div>
    </>
  )
}

export default AIAScreen