import { Tool } from 'machine-trust-platform';
import React from 'react';


import styles from './tool.card.module.scss';

interface Props {
  tool: Tool;
}

const ToolCard = ({tool}: Props) => {

  return (
    <div className={`${styles.container} col-3`}>
      <h3 className={styles.header}>
        {tool.name}
      </h3>
      <div className={styles.content}>


      </div>
    </div>
  )
}

export default ToolCard;