import { useEffect, useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Survey, Model, StylesManager } from 'survey-react';
import { SurveyPDF } from 'survey-pdf';
import { getNameOfMonthFromTimeStamp } from '../../utils/time';
import { getSurveyAnswers, cacheSurveyAnswers } from './surveyform.helpers';
import { Button } from "../../components";
import 'survey-react/survey.css';
import styles from "./App.module.css";
//import axios from axios;

// import styles from "./survey.form.module.scss";

interface Props {
  content: any;
  Survey_KEY: any;
  name: string;
  completeButton: any;
  currentPage: number;
  //setCurrentPage: (val: number) => void

  //hasRecommendation: any;

}

const SurveyForm2 = ({ content, Survey_KEY, name, completeButton, currentPage}: Props) => {
  const { t } = useTranslation();
  const [hasRecommendation, setRecommendation] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [completeDisable, setCompleteDisable] = useState(true);


  StylesManager.applyTheme('bootstrap');

  const onSavePDFClicked = () => {

    // Where do these values come from? A4 paper size in mm.
    const pdfWidth = surveyModel.pdfWidth || 210;
    const pdfHeight = surveyModel.pdfHeight || 297;

    const date = new Date();
    const filename = `${name}-${getNameOfMonthFromTimeStamp(date).substring(0, 3)}-${date.getDate()}.pdf`;
    const options = {
      fontSize: 10,
      margins: {
        left: 12,
        right: 12,
        top: 12,
        bot: 12,
      },
      format: [pdfWidth, pdfHeight],
    };

    let h2="<html><head><style></style></head><body><p>"+surveyModel.processedCompletedHtml+"</p></body></html>"
    let result={
      type: "html",
      name: "AIAnote",
      html: h2,
    }

    content["pages"][content["pages"].length-1]["elements"].push(result)
    const surveyPDF = new SurveyPDF(content, options);
    surveyPDF.data = surveyModel.data;
    content["pages"][content["pages"].length-1]["elements"].pop()
    // Here we render the filename in the margin at the bottom. Until the license fee is
    // paid the margin at the top is taken by the commercial message.
    //
    // There are examples on the SurveyJS website showing how to render logos in that space:
    // https://surveyjs.io/Examples/Pdf-Export?id=survey-pdf-header&platform=Reactjs&theme=modern#content-js

    surveyPDF.onRenderFooter.add((_, ntop) => {
      ntop.drawText({
        text: filename,
        fontSize: 10,
      });
    });
    
    surveyPDF.save(filename);
  };

  

  const onSurveyAfterPageRender = () => surveyModel.data = getSurveyAnswers(Survey_KEY);

  const onSurveyValueChanged = useCallback((model: any) => {
    if (Object.keys(model.data).length > 0) {
      cacheSurveyAnswers(model.data, Survey_KEY);
    }
  }, [Survey_KEY]);

  const clearSurvey = () => {
    surveyModel.clear();
    window.localStorage.clear();
  }


  const surveyModel = useMemo(() => {
    const model = new Model(content);
    model.showNavigationButtons = false;
    model.isRequired = true;
    model.currentPageNo = currentPage;
    return model
  }, [content, currentPage])

  useEffect(() => {
    onSurveyValueChanged(surveyModel);
  }, [surveyModel, onSurveyValueChanged]);

  const handleShowComplete = useCallback((model: Model) => {
    setCompleteDisable(!model.isLastPage)
  }, [])

  const doOnCurrentPageChanged = useCallback((model: Model) => {
    const surveyComplete = document.getElementById('surveyComplete');
    // TODO: Currently we are filtering every time the there is a change this can be optimized
    if (surveyComplete) handleShowComplete(model)//setCompleteDisable(!(Object.keys(model.data).length > 0 && model.isLastPage));

    const surveyPrev = document.getElementById('surveyPrev');
    if (surveyPrev) surveyPrev.style.display = !model.isFirstPage && !model.isLastPage ? 'inline' : 'none';

    const surveyNext = document.getElementById('surveyNext');
    if (surveyNext) surveyNext.style.display = !model.isLastPage ? 'inline' : 'none';

    // const surveyPageNo = document.getElementById('surveyPageNo');
    // if (surveyPageNo) surveyPageNo.value = model.currentPageNo;
  }, [])
  
  useEffect(() => {
    doOnCurrentPageChanged(surveyModel);
  }, [surveyModel, doOnCurrentPageChanged]);


  return (
    <>
        
      <Survey model={surveyModel} onAfterRenderPage={onSurveyAfterPageRender} onValueChanged={onSurveyValueChanged} onCurrentPageChanged={doOnCurrentPageChanged}/>

      <div className="panel-footer card-footer">
        <div className="svFooterRight right-survey-footer">
          <Button id="surveySavePDF" className="svFooterRight" onClick={onSavePDFClicked} data-tut="save-ntop">
            {t('pdf.label')}
          </Button>
        </div>
        <div className="svFooterRight right-survey-footer">
          <Button id="surveyClearPDF" className="svFooterRight" onClick={clearSurvey} data-tut="clear-ntop">
            {t('clear.label')}
          </Button>
        </div>
        {completeButton ?
          (<div className="svFooterRight right-survey-footer">
            <Button id="surveyComplete" disabled={completeDisable} className="svFooterRight" onClick={() => {
              surveyModel.completeLastPage()
              setIsOpen(true);
              setRecommendation(surveyModel);
            }
            }>
              Complete
            </Button>
          </div>) : (<span></span>)
        }

        <div className="svFooterRight right-survey-footer">
            <Button
              id="surveyPrev"
              onClick={(e) => {
                surveyModel.prevPage();
               // setCurrentPage(currentPage - 1);
                e.preventDefault();
                console.log(currentPage)
              }}>
              {t('previous.label')}

            </Button>

          </div>

          <div className="svFooterRight right-survey-footer">
            <Button
              id="surveyNext"
              onClick={(e) => {
                surveyModel.nextPage();
               // setCurrentPage(currentPage + 1);
               e.preventDefault();
                console.log(currentPage)
              }}>
              {t('next.label')}
              </Button>
          </div>


      </div>
    </>
  );
};

export default SurveyForm2;