import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store";

export interface NavigationState {
  drawer: "open" | "close";
  serviceMenu: "open" | "close";
  drawerSubTitle: string;
  drawerIsDisabled: boolean;
}


const initialState: NavigationState = {
  drawer: "close",
  drawerIsDisabled: true,
  serviceMenu: "close",
  drawerSubTitle: '',

};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.drawer = state.drawer === "close" ? "open" : "close";
    },
    toggleServiceMenu: (state) => {
      state.serviceMenu = state.serviceMenu === "close" ? "open" : "close";
    },
    openServiceMenu: (state) => {
      state.serviceMenu = 'open'
    },

    closeServiceMenu: (state) => {
      state.serviceMenu = 'close'

    },
    setDrawerSubTitle: (state, action: PayloadAction<string>) => {
      state.drawerSubTitle = action.payload
    },

    disableDrawer: (state) => {
      state.drawerIsDisabled = true
      state.drawer = 'close'
    },
    enableDrawer: (state) => {
      state.drawerIsDisabled = false
      state.drawer = 'open'
    }

  },
  extraReducers: (builder) => {},
});

// UI State Actions
export const { toggleDrawer, toggleServiceMenu, openServiceMenu, closeServiceMenu, setDrawerSubTitle, disableDrawer, enableDrawer } = navigationSlice.actions;

// Selectors
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectDrawerState = (state: RootState) => state.uiState.navigation.drawer;
export const selectServerMenuState = (state: RootState) => state.uiState.navigation.serviceMenu;
export const selectDrawerSubTitle = (state: RootState) => state.uiState.navigation.drawerSubTitle;
export const selectDrawerIsDisabled = (state: RootState) => state.uiState.navigation.drawerIsDisabled;

export default navigationSlice.reducer;
