import { FeatureDetails } from 'machine-trust-platform';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrivFeature } from './Group';

import styles from './group.module.scss';

interface Props {
  group: PrivFeature;
  protectedFeatures?: string[];
  featureDetails: Record<string, FeatureDetails>;
  onFeatureChange: (f: PrivFeature) => void;
}

const PrivUnPrivFeatureSelect = ({ group, protectedFeatures, onFeatureChange, featureDetails }: Props) => {
  const { t } = useTranslation()

  return (
    <div
      className={styles.selectContainer}
    
    >
      <select
        className={styles.select}
        value={group.feature || 'please_select'}
        onChange={(e) => onFeatureChange({feature: e.target.value, label: undefined})}
      >
        <option value="please_select" disabled>{t('select.feature.label')}</option>

        {protectedFeatures?.map(pf => (
          <option key={`${pf}-priv-unpriv-feature-details`} value={pf}>
            {pf}
          </option>
        ))}
      </select>

      
      <select
        className={styles.select}
        value={group.label || 'please_select'}
        disabled={group.feature === undefined}
        onChange={(e) => onFeatureChange({label: e.target.value})}
      >
        <option value="please_select">{t('select.label.label')}</option>

        {group.feature && Array.from(featureDetails[group.feature].labels)?.map(l=> (
          <option key={`${l}-priv-unpriv-label-details`} value={l}>
            {l}
          </option>
        ))}
      </select>

    </div>
  )
}

export default PrivUnPrivFeatureSelect;