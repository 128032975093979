import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { client } from "../../../utils/api";

import type { Scorecard } from "machine-trust-platform";

interface ScorecardState {
  list: Array<Scorecard>;
  selected?: Scorecard;
  userLastModTime: number;
  lastFetchTime: number;
  status: "idle" | "loading" | "failed";
}

const initialState: ScorecardState = {
  list: [],
  selected: undefined,
  userLastModTime: -1,
  lastFetchTime: -1,
  status: 'idle'
}

/* -------------------------- Async Thunk --------------------------*/

const getScorecard = createAsyncThunk("get/scorecard", async (scorecardUuid: string, thunkApi) => {
  const idToken = (thunkApi.getState() as RootState).auth.tokens
    ?.id_token as string;

  // First try tp find the Scorecard in the array and return that instead
  const scorecard = (thunkApi.getState() as RootState).scorecard.element.list.find(s=> s.uuid === scorecardUuid)
  if (scorecard) return scorecard
    
  // If the scorecard was not found make the API Call to fetch it
  try {
    const response = await client(`/scorecard/${scorecardUuid}`, idToken);
    return response.data;
  } catch (error) {
    thunkApi.rejectWithValue(error);
  }
});

// This gets the data for scorecards
const getAllScorecards = createAsyncThunk(
  "all/scorecards",
  async (_, thunkApi) => {
    const idToken = (thunkApi.getState() as RootState).auth.tokens
      ?.id_token as string;

    try {
      const response = await client("/scorecard", idToken);
      return response.data;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  }
);

/* -------------------------- App Thunk --------------------------*/

export const scorecardSlice = createSlice({
  name: "scorecard",
  initialState,
  reducers: {

    updateStatus: (state, action) => {
      state.status = action.payload
    },
    setSelectedScorecard: (state, action: PayloadAction<Scorecard | undefined>) => {
      state.selected = action.payload
    },

  },
  extraReducers: (builder) => {
    builder
      /*--------------  Get AIGS --------------  */
      .addCase(getScorecard.fulfilled, (state, action: PayloadAction<Scorecard>) => {
        const scorecard = action.payload;

        state.list = scorecard
          ? [...state.list, scorecard]
          : state.list;
        //state.userLastModTime = new Date().getTime()
        state.selected = scorecard;
        state.status = "idle";
      })
      /*-------------- Get All Scorecards --------------  */
      .addCase(
        getAllScorecards.fulfilled,
        (state, action: PayloadAction<Scorecard[]>) => {
          state.list = action.payload || [];
        }
      )
      .addMatcher(
        isFulfilled,
        (state) => {
          state.status = 'idle'
        }
      )
      .addMatcher(
        isPending,
        (state) => {
          state.status = 'loading'
        }
      )
      .addMatcher(
        isRejected,
        (state) => {
          state.status = 'failed'
        }
      )


  },
});

// Scorecard State Actions
export const { updateStatus, setSelectedScorecard } = scorecardSlice.actions;
export { getScorecard, getAllScorecards };

// Selectors
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectScorecardStatus = (state: RootState) =>
  state.scorecard.element.status;
export const selectSelectedScorecard = (state: RootState) =>
  state.scorecard.element.selected;
export const selectScorecardList = (state: RootState) => state.scorecard.element.list;


export default scorecardSlice.reducer;
