import React, { useEffect } from "react";
import { Drawer } from "../../app/components";
import { ORGANIZATION, MTPPath } from "../../constants";
import { useDispatch } from "../../app/hooks";
import {
  disableDrawer,
  enableDrawer,
} from "../../app/features/ui/slices/navigation.slice";
import { useLocation } from "react-router";

const { TIER } = ORGANIZATION;

const ToolsDrawer = () => {
  const dispatch = useDispatch();

  const basePath = MTPPath.TOOLS;
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === (basePath as String)) {
      dispatch(disableDrawer());
    } 
    return () => {
      dispatch(enableDrawer());
    };
  }, [basePath, dispatch, location.pathname]);

  return (
    <>
      <Drawer.Link
        basePath={basePath}
        path={`${basePath}/AIF360`}
        label="AIF360"
        title="tools.aif360.title"
        tier={TIER.ALL}
      />
      <Drawer.Link
        basePath={basePath}
        path={`${basePath}/fairlearnML`}
        label="FairlearnML"
        title="tools.fairlearn.title"
        tier={TIER.ALL}
      />

      <Drawer.Link
        id="tool-request-drawer-btn"
        basePath={basePath}
        path={`${basePath}/results`}
        label="results.label"
        title="results.label"
        tier={TIER.ALL}
      />
    </>
  );
};

export default ToolsDrawer;
