/*
 * File: Questionnaire.tutorial.jsx
 * Project: Machine Trust Platform
 * File Created: Monday, 14th June 2021 3:31:02 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 22nd June 2021 3:36:43 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import {  Step } from 'react-joyride';
import CustomJoyRide from '../CustomJoyRide';

const QuestionnaireTutorial = () => {
  const { t } = useTranslation();
  
  const STEPS: Step[] = [
    {
      target: '#nuenergy-questionnaire',
      title: "AI Governance Scorecard (AIGS) Questionnaire",
      content: t('aigs.questionnaire.tutorial.step.1'),
      disableBeacon: true,
      placement: 'center',
      disableScrolling: true,
    },
    // {
    //   target: '#nuenergy-questionnaire',
    //   content: 'Try selecting an answer to one of the questions and press the save on the bottom right hand corner. (The save will only appear once there is something to be saved)',

    // },
    // {
    //   target: '[data-tut="control-buttons"]',
    //   title: "Questionnaire Controls",
    //   content: <p>Notice you can select the draft you just saved and you can clear your answers to start fresh.</p>,
    // },
    // {
    //   target: '#aigs-scores-drawer-btn',
    //   title: "Approving and Managing your Scores",
    //   content: <p>The final step to populating the dashboard is approving your completed AIGS (AI Governance Scorecard) Results.</p>,
    // },
  ];
  return <CustomJoyRide steps={STEPS} />;
};

export default QuestionnaireTutorial;