import React from 'react';
import NuEnergyBase64Logo from '../NuEnergyBase64Logo';

const containerStyle = {
  height: '400px',
  padding: '20px 50px',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
};

interface Props {
  children: React.ReactNode;
};

const DemoContainer = ({ children }: Props) => {
  return (
    <div className="survey-container">
      <div>
        <NuEnergyBase64Logo width={102} height={30} />
      </div>
      <div style={containerStyle}>
        <h2>{children}</h2>
      </div>
      </div>
  );
};

export default DemoContainer;
