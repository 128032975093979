import React from 'react';
import { ConfigurableOnRequest } from '../../../components'

const WaterlooScreen = () => {

  return (
    <>
      <ConfigurableOnRequest />
    </>
    )
}

export default WaterlooScreen