import { Tier } from 'machine-trust-platform'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MTPPath } from '../../../../../../constants'
import { TierCheckWrapper } from '../../../../auth/components';
import _uniqueId from 'lodash/uniqueId';

import styles from './tab.module.scss';
import ReactTooltip from 'react-tooltip';

interface Props {
  icon?: string,
  label?: string,
  title?: string,
  path: MTPPath,
  tier: Tier,
  isActive: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, path: MTPPath) => void
  width?: string | number;
}

const Tab = ({icon, label, title, path, tier, isActive, onClick, width="15vw" }: Props) => {
  const { t } = useTranslation()

  const tooltipId = _uniqueId('nav-tab-')

  return (
    <TierCheckWrapper tier={tier}>
      <div
        key={path}
        className={`${styles.tab}`}
        style = {{width}}

        // Tooltip
        data-tip={`${title ? t(title) : title}`}
        data-for={tooltipId}
        data-place='bottom'
        data-multiline={true}
        data-delay-show={500}
      >

        
        <div
          className={`${styles.link} ${isActive ? styles.active : ''}`}
          onClick={(e) => onClick(e, path)}>
          {icon!== undefined && <i className={icon}></i>}
          {label!==undefined && t(label)}
        </div>
      </div>

      {  // Only Show the Tooltip if there is a title set
        title && <ReactTooltip id={tooltipId} className={styles.title} effect="solid" type="dark" />
      }
    </TierCheckWrapper>
  )
}

export default Tab;