import React, { useCallback } from 'react'
import Joyride, { ACTIONS, CallBackProps, Props as JoyrideProps } from 'react-joyride';
import { useDispatch, useSelector } from '../../../../../hooks';
import { selectTutorial, turnOffTutorial } from '../../../slices/tutorial.slice';
// import {

interface Props extends JoyrideProps {
  run?: boolean
  callback?: (data: CallBackProps) => void;
  
 }

/**
 * Description used to display the tutorials
 * We used a custom class to unsure the tutorials zIndex was 
 * always greater then the Navigation components.
 */
const CustomJoyRide = ({ styles, callback, run = true, ...rest }: Props) => {
  const dispatch = useDispatch()

  const open = useSelector(selectTutorial)

  /**
   * Custom Callback function 
   */
  const joyrideCallBack = useCallback((data: CallBackProps) => {
    const { action } = data;
    // The custom call back function
    callback && callback(data)

    // If the user clicks the 'x' or clicks outside of the tutorial 
    // the tutorial will close until turned back on
    if (ACTIONS.CLOSE === action) {
      dispatch(turnOffTutorial())
    }

  }, [dispatch, callback])

  return (
    <Joyride
      
      {...rest}
      callback={joyrideCallBack}
      run={run && open}
      styles={{
        ...styles,
        options: {
          zIndex: 1100,

          // TODO: These colors need to be adjusted 
          // such that they use the style sheets
          primaryColor: '#20a7d8',
          textColor: '#000000',
          ...styles?.options
        },
      }}

    />)
}

export default CustomJoyRide