import { createAsyncThunk, createSlice, isAnyOf, PayloadAction, } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { client } from "../../../utils/api";

import type { Project, Requirement, Status } from "machine-trust-platform";
import { setOrganization, login } from "../../auth/auth.slice";
import { addProject, getAllProjects, getProject } from "../../project/project.slice";

interface RequirmentState {
  list: Requirement[];
  selected?: string;
  lastFetchTime: number;
  userLastModTime: number;
  status: Status;
}
// record with key of ScorecardUUid where string is
type State = Record<string, RequirmentState>;

// This is used when initializing a requirement state for a scorecard
const initialScorecardRequirementState = {
  list: [],
  selected: undefined,
  lastFetchTime: -1,
  userLastModTime: -1,
  status: "loading" as Status

}

const initialState: State = {};

/* -------------------------- Async Thunk --------------------------*/


const getAllScorecardRequirement = createAsyncThunk(
  "get/AllScorecardRequirement",
  async (scorecardUuid: string, thunkApi) => {
    const idToken = (thunkApi.getState() as RootState).auth.tokens
      ?.id_token as string;

    try {

      const response = await client(`/scorecard/${scorecardUuid}/requirement`, idToken);
      // This is the payload
      return { scorecardUuid: scorecardUuid, list: response.data } as { scorecardUuid: string, list: Requirement[] };
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  }
);

/* -------------------------- App Thunk --------------------------*/

/* -------------------------- Slice --------------------------*/
export const requirementSlice = createSlice({
  name: "RequirementState",
  initialState,
  reducers: {
    updateRequirementStatus: (state, action: PayloadAction<{ scorecardUuid: string, status: "idle" | "loading" | "failed" }>) => {
      const { scorecardUuid, status } = action.payload;
      state[scorecardUuid].status = status
    },
    setSelectedRequirement: (state, action: PayloadAction<{ scorecardUuid: string, requirement: Requirement }>) => {
      const { scorecardUuid, requirement } = action.payload;
      state[scorecardUuid].selected = requirement?.uuid;
    },

  },
  extraReducers: (builder) => {
    builder

      /*-------------- Get All requirement --------------  */
      .addCase(getAllScorecardRequirement.pending, (state, action) => {

      })
      .addCase(getAllScorecardRequirement.fulfilled, (state, action) => {
        if (action.payload?.scorecardUuid) {
          state[action.payload?.scorecardUuid].list = action.payload.list;
          state[action.payload?.scorecardUuid].status = "idle";
        }
      })

      .addMatcher(isAnyOf(getProject.fulfilled, addProject.fulfilled), (state, action: PayloadAction<Project>) => {
        const project = action.payload
        state[project.scorecardUuid as string] = initialScorecardRequirementState
      })
      .addMatcher(isAnyOf(getAllProjects.fulfilled), (state, action: PayloadAction<Project[]>) => {
        const projectList = action.payload || []
        if (projectList.length > 0) {
          for (let project of projectList) {
            state[project.scorecardUuid as string] = initialScorecardRequirementState;
          }
        }
      })


      .addMatcher(isAnyOf(setOrganization, login), (state, action) => {
        const { scorecardUuid } = action.payload.organization
        if (!state[scorecardUuid] || (state[scorecardUuid] && !state[scorecardUuid].status)) {
          state[scorecardUuid] = initialScorecardRequirementState;
        }

      })
    // TODO: handle matcher for projects to prep project scorecard requirement state like above.
    // Also needs to be done for questions and scores slice
  },
})


// Scorecard Requirement State Actions
export const { updateRequirementStatus, setSelectedRequirement } = requirementSlice.actions;
export { getAllScorecardRequirement };


// Selectors
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
//export const getSelectRequirement = (state: RootState,  scorecardUuid: string) => state.scorecardScores[scorecardUuid] 
export const selectSelectedRequirementList = (state: RootState, scorecardUuid?: string) => scorecardUuid ? state.scorecard.requirement[scorecardUuid].list : undefined
export const selectRequirementLastFetchTime = (state: RootState, scorecardUuid?: string) => scorecardUuid ? state.scorecard.requirement[scorecardUuid].lastFetchTime : -1
export const selectRequirementStatus = (state: RootState, scorecardUuid?: string) => scorecardUuid ? state.scorecard.requirement[scorecardUuid].status : 'loading'


export default requirementSlice.reducer;









