import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { AlgorithmicImpactAssessment } from 'machine-trust-platform';
import { ConditionalWrapper } from '../../../../components';
import { drawChart, levelAlert } from '../../aia.helper';
//medha'scomment
//other new comment
//other other comment 

interface Props {
  assessment?: AlgorithmicImpactAssessment;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  link?: any;
};

const AIAChart = ({ assessment = undefined, link = undefined }: Props) => {

  const { t } = useTranslation();
  const [data, setData] = useState<Array<{
    value: number;
    color: string;
    name: string;
  }> | null>(null);
  const height = 100;
  const width = 75;

  useEffect(() => {
    if (assessment) {
      let { mitigation_score, total_score, raw_risk_score } = assessment;
      mitigation_score = mitigation_score || 0;
      total_score = total_score || 0;
      raw_risk_score = raw_risk_score || 0;


      // NOTE: The translations will effect the styling this needs to be fixed 
      setData([
        {
          value: raw_risk_score,
          color: '#e83e8c',
          name: t('impact.score.label'),
        },
        {
          value: mitigation_score,
          color: '#20a8d8',
          name: t('mitigation.score.label'),
        },
        { value: total_score, color: '#4dbd74', name: t('total.score.label') },
      ]);
    }
  }, [assessment, t]);

  useEffect(() => {
    if (data) {
      drawChart(height, width, data);
    }
  }, [data, height, width]);

  return (
    <ConditionalWrapper
      condition={link !== undefined}
      wrapper={(children: React.ReactNode) => (
        <Link style={{ textDecoration: 'none', height: 'fit-content' }} to={link}>
          {children}
        </Link>
      )}>
      <>
        {assessment ? (
          <div className="aia-chart">
            {/* Government Logo */}
            {/* <div className='aia-chart__gov-logo'>
        <img src="https://www.canada.ca/etc/designs/canada/cdts/gcweb/v4_0_30/assets/sig-blk-en.svg" alt='Government of Canada' />
      </div> */}
            <img className="canadian-flag" width="50px" src={`${process.env.PUBLIC_URL}/images/Flag_of_Canada.svg`} alt="Canada" />
            {/* Legend */}
            <div className="legend">
              {data && (
                <>
                  <ul>
                    {data.map((d) => (
                      <li key={d.name} className={`aia-legend-${d.name.replace(' ', '-').toLocaleLowerCase()}`}>
                        {d.name}
                      </li>
                    ))}
                  </ul>
                  <img src={`${process.env.PUBLIC_URL}/images/aia.png`} alt="Algorithmic Impact Assessment" />
                </>
              )}
            </div>

            {/* Middle section (Level) */}
            <div className="level-container">
              <div className="level-wrapper">
                <div className={`aia-level ${levelAlert(assessment.level)}`}>
                  <span>{assessment.level}</span>
                </div>
                <span className={`level-label ${levelAlert(assessment.level)}`}>{t('level.label')}</span>
              </div>
            </div>
            {/* Bar Chart Container */}
            <div id="aia-bar-chart-container" className="aia-bar-chart-container">
              <div id="aia-bar-chart" className="aia-bar-chart" />
            </div>
          </div>
        ) : (
          <div className="aia-chart__no-data container md-shadow-4dp">
            {/* <img src={`${process.env.PUBLIC_URL}/images/aia.png`} alt='Algorithmic Impact Assessment' /> */}
            <span>{t('no.compliance.assessment.data.message')}</span>
          </div>
        )}
      </>
    </ConditionalWrapper>
  );
};

export default AIAChart;
