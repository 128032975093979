import React, { useState } from "react";
import { a, useSpring } from "@react-spring/web";
import styles from "./dropdownbutton.module.scss"


const DropDownButton = ()=>{

const [toggle, setToggle] = useState(false);

const spring = useSpring<any>({
    display: "inline-block",
    backfaceVisibility: "hidden",
    transform: toggle ? `rotate(180deg)` : `rotate(0deg)`,
  });


return (

<div className={styles.dropdown}>
  {/* <button className={styles.dropdownToggle} type="button" onClick = {()=> setToggle(toggle)} >
-    Select from Options
-  </button>
-  <ul className={styles.dropdownMenu} role="listbox" aria-expanded="false">
-    <li >Option 1</li>
-    <li >Option 2</li>
-    <li >Option 3</li>
-    <li >Option 4</li>
-    <li >Option 5</li>
-  </ul> */}
</div>

);


}

export default DropDownButton;