import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './favorable.unfavorable.module.scss'

interface DepLabels {
  favorable?: number;
  unfavorable?: number;
}

interface Props {
  dependantFeature?: string;
  featureLabels?: number[]
  labels: number[];
  labelSelectCallback: (labels: DepLabels) => void;
}



const PLEASE_SELECT = "please-select-Afrs59dkA3921";

const FavorableUnfavorable = ({ dependantFeature, labels, labelSelectCallback }: Props) => {
  const { t } = useTranslation()

  const [favUnfavLabels, setFavUnfavLabels] = useState<DepLabels>({ "favorable": undefined, "unfavorable": undefined })


  const handleLabelSelect = (value: number, label: string) => {
    let otherValue = labels.find(l => l !== value) as number
    let newLabels = {
        "favorable":    label === 'favorable' ? value       : otherValue,
        "unfavorable":  label === 'favorable' ? otherValue  : value,
      }
    setFavUnfavLabels(newLabels)
    labelSelectCallback && labelSelectCallback(newLabels)
  }

  useEffect(() => {
    // Reset labels if dependant feature changes.
    setFavUnfavLabels({ "favorable": undefined, "unfavorable": undefined })
  }, [dependantFeature])

  return (
    <div className={`${styles.labelSelectContainer}`}>
      {dependantFeature &&
        <div className={styles.selectLabelContainer}>
          {["favorable", "unfavorable"].map(fType => (
            <label
              key={`${fType}-${1000 * Math.random()}`}
              className={styles.selectLabel}>
              {
                t(`${fType}.label`)
              }:
              <select
                // Need to check if the label is undefined to display the default value
                // In JavaScript 0 is similar to undefined when used as a boolean
                value={favUnfavLabels[fType as ("favorable" | "unfavorable")] === undefined ? PLEASE_SELECT : favUnfavLabels[fType as ("favorable" | "unfavorable")]}
                onChange={(e) => handleLabelSelect(Number(e.target.value), fType)}
              >
                <option value={PLEASE_SELECT} disabled>{t(`select.${fType}.label`)}</option>
                {// Convert the set of labels to an array and map them to the options
                  labels.map(l => (
                    <option key={`${l}-${fType}`} value={l}>{l}</option>
                  ))}
              </select>
            </label>
          ))}
        </div>}
    </div>
  )
}

export default FavorableUnfavorable