
import React from "react";
import Score from "./Score";
import Report from "./Report";
import "./styles/BContainer.scss"
import "./styles/tabs.scss"
import styles from "./styles/temp.module.scss";
import { stubFalse, template } from "lodash";
import { style } from "d3";
import { StylesManager } from "survey-react";
const DEFAULT_ANSWER = "idk";
const NO_ANSWER = "idk";

const ANSWER_KEY = {
    yes: "Yes",
    no: "No",
    idk: "Not Applicable",
};

export default class Bitrapy extends React.Component {
    constructor(props) {
        super(props);
        this.aims=props.aims
        this.state = {
            questions: [],
            active_component:
                window.location.hash.length > 7
                    ? window.location.hash.substr(7)
                    : "conceptualization",
        };
    }
    componentDidMount() {
        let json;
        if(this.aims=="ised"){
            json=require("./ised_bitrapy.json").questions
        }else if(this.aims=="pspc"){
            json=require("./pspc_bitrapy.json").questions
        }else{
            json=require("./rcmp_bitrapy.json").questions
        }
        let shuffled = json
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)

        this.setState({
            questions: shuffled.map(item => {
                item.answer = DEFAULT_ANSWER;
                return item;
            }),
        });
    }

    calculate_score(metric, component, questions = this.state.questions) {
        let numerator = questions
            .map(question => {
                let answer_weight;
                if(question["reverse_answer"]!==true){
                    answer_weight = question.answer === "yes";
                }else{
                    answer_weight = question.answer === "no";
                }
                const component_weight = component
                    ? question.component === component
                    : 1;
                return (
                    answer_weight *
                    component_weight *
                    // question.weight *
                    (metric
                        ? question[metric]
                        : question.privacy +
                          question.bias +
                          question.transparency)
                );
            })
            .reduce((accumulator, current) => accumulator + current, 0);
        let denominator = questions
            .map(question => {
                const component_weight = component
                    ? question.component === component
                    : 1;
                return Math.max(
                    0, // question.weight *
                    component_weight *
                        (metric
                            ? question[metric]
                            : question.privacy +
                              question.bias +
                              question.transparency)
                );
            })
            .reduce((accumulator, current) => accumulator + current, 0);
        return Math.max(0, Math.round(100 * (numerator / denominator)));
    }

    load_scoreboard() {
        return (
            <div>
                {this.load_fact_score()}
                {/* {this.load_metric_scoreboard()}
                {this.load_component_scoreboard()} */}
            </div>
        );
    }

    load_fact_score() {
        const fact_score = this.calculate_score();
        return (
            <nav className="bitrapy_level">
                <div className="level-item is-hidden-mobile"></div>
                <div className="level-item is-hidden-mobile"></div>
                <div className="level-item is-hidden-mobile"></div>
                <div className="level-item is-hidden-mobile"></div>
                <div className="level-item is-hidden-mobile"></div>
                <Score score_value={fact_score} />
                <div className="level-item is-hidden-mobile"></div>
                <div className="level-item is-hidden-mobile"></div>
                <div className="level-item is-hidden-mobile"></div>
                <div className="level-item">
                    <nav className="level is-mobile margin-0">
                        <Report
                            answer_key={ANSWER_KEY}
                            calculate_score={this.calculate_score}
                            questions={this.state.questions}
                            no_answer={NO_ANSWER}
                        />
                        {/* <Badge score={fact_score} /> */}
                    </nav>
                </div>
            </nav>
        );
    }

    load_component_scoreboard() {
        return (
            <nav className="level is-mobile">
                <Score
                    label="Conceptualization"
                    score_value={this.calculate_score(null, "conceptualization")}
                />
                <Score
                    label="Prototyping"
                    score_value={this.calculate_score(null, "prototyping")}
                />
                <Score
                    label="Testing"
                    score_value={this.calculate_score(null, "testing")}
                />
                <Score
                    label="Deployment"
                    score_value={this.calculate_score(null, "deployment")}
                />
                <Score
                    label="Maturity&Feedback"
                    score_value={this.calculate_score(null, "maturity&feedback")}
                />
            </nav>
        );
    }

    load_metric_scoreboard(component) {
        return (
            <nav className="bitrapy_level">
                <div className={`${styles.level_item} ${styles.center_align}`}>
                    <nav className="bitrapy_level is-mobile margin-1">
                        <Score
                            label="Transparency"
                            score_value={this.calculate_score(
                                "transparency",
                                component
                            )}
                        />
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <Score
                            label="Privacy"
                            score_value={this.calculate_score(
                                "privacy",
                                component
                            )}
                        />
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <div className="level-item is-hidden-mobile"></div>
                        <Score
                            label="Bias"
                            score_value={this.calculate_score(
                                "bias",
                                component
                            )}
                        />
                    </nav>
                </div>
            </nav>
        );
    }

    set_active_component(component_name) {
        return () => {
            this.setState({ active_component: component_name });
        };
    }

    load_tabs() {
        return (
            <section className={styles.section}>
                <div className="tabs is-centered is-boxed is-medium">
                    <ul>
                        <li
                            className={
                                this.state.active_component === "conceptualization"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            <a
                                href="#/conceptualization"
                                onClick={this.set_active_component("conceptualization")}
                            >
                                <span>Conceptualization</span>
                            </a>
                        </li>
                        <li
                            className={
                                this.state.active_component === "prototyping"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            <a
                                href="#/prototyping"
                                onClick={this.set_active_component("prototyping")}
                            >
                                <span>Prototyping</span>
                            </a>
                        </li>
                        <li
                            className={
                                this.state.active_component === "testing"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            <a
                                href="#/testing"
                                onClick={this.set_active_component("testing")}
                            >
                                <span>Testing</span>
                            </a>
                        </li>
                        <li
                            className={
                                this.state.active_component === "deployment"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            <a
                                href="#/deployment"
                                onClick={this.set_active_component("deployment")}
                            >
                                <span>Deployment</span>
                            </a>
                        </li>
                        <li
                            className={
                                this.state.active_component === "maturity&feedback"
                                    ? "is-active"
                                    : ""
                            }
                        >
                            <a
                                href="#/maturity&feedback"
                                onClick={this.set_active_component("maturity&feedback")}
                            >
                                <span>Maturity and Feedback</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </section>
        );
    }

    change_answer(index) {
        return event => {
            let updated_questions = this.state.questions;
            updated_questions[index].answer = event.target.value;
            this.setState({
                questions: updated_questions,
            });
        };
    }

    load_question(item, index) {
        return (
            <div
                className={
                    styles.field +
                    (item.component === this.state.active_component
                        ? ""
                        : " is-hidden")
                }
            >
                <div className={styles.field_label}>{item.question}</div>
                <div className="field-body has-text-centered">
                    <div className={styles.select}>
                        <select
                            value={item.answer}
                            onChange={this.change_answer(index)}
                        >
                            {Object.entries(ANSWER_KEY).map(
                                ([answer_key, answer_value]) => {
                                    return (
                                        <option
                                            value={answer_key}
                                            key={answer_key}
                                        >
                                            {answer_value}
                                        </option>
                                    );
                                }
                            )}
                        </select>
                    </div>
                </div>
            </div>
        );
    }

    load_questions() {
        return this.state.questions.map((item, index) => {
            return <div key={index}>{this.load_question(item, index)}</div>;
        });
    }

    no_active_component() {
        return (
            <div className="has-text-centered">
                Calculate your Bitrapy score by answering a set of simple
                Yes/No questions. Select any tab to get started.
            </div>
        );
    }

    answer_summary(component) {
        return (
            <div className={styles.field}>
                <div className={styles.field_label} ># Answered</div>
                <div className={styles.field_label_right}>
                    {
                        this.state.questions.filter(
                            question =>
                                question.component === component &&
                                question.answer !== NO_ANSWER
                        ).length
                    }
                    /
                    {
                        this.state.questions.filter(
                            question => question.component === component
                        ).length
                    }
                </div>
            </div>
        );
    }
    render() {
        return (
            <div className={styles.bitrapyRoot}>
                    
                    <div className="hero-body">
                        <div className={styles.level}>
                            <div className={styles.center}>
                                <h1 className={styles.title}>
                                    BiTraPy Tool
                                </h1>
                                <br />
                                <h1 className={styles.subtitle}>
                                    BiTraPy tool is a responsible AI ethical tool that checks the Bias, Transparency and Privacy of an AI System
                                    over the lifecycle of an AI Project. It helps to measure AI Categories of Bias, Transparency and Privacy at the
                                    Conceptualization, Prototyping, Development, Deployment, Maturity and Feedback stages.
                                </h1>
                                <br></br>
                                <h1 className={styles.subtitle}><strong>Disclaimer:</strong> The scores displayed for Bias, Transparency and Privacy are based on the input received.</h1>
                            </div>
                        </div>
                          
                    </div>

            
                <br></br>
                
                <div>
                    <center>
                        <h1 className={styles.title}>Interpretation of Scores</h1>
                    </center>
                   

                    <table className={styles.center}>
                        <tr>
                            <Score
                                label="legend"
                                score_value="<30"
                            />
                        
                            <td><h1 className={styles.subtitle}> Substantial work needs to be done on the specific AI Trust Category.
                                                        Keep iterating and keep working on your guardrails!</h1></td>                      
                        </tr>
                        <br></br>
                        <tr>
                            <Score
                                label="legend"
                                score_value="30-70"
                            />
                        
                            <td><h1 className={styles.subtitle}> More work needs to be done on the specific AI Trust Category. Keep
                                            iterating and keep working on your guardrails!</h1></td>                      
                        </tr>
                        <br></br>
                        <tr>
                            <Score
                                label="legend"
                                score_value=">70"
                            />
                        
                            <td><h1 className={styles.subtitle}> Great Work. Keep monitoring your guardrails.</h1></td>                      
                        </tr>
                        </table>

                </div>
                <br></br>
                <br></br>
                <br></br>
                <nav className="bitrapy_level">
                        <div className="level-item is-hidden-mobile"></div>
                        <h1 className={styles.center}><strong>Overall Scores</strong></h1>
                        <div className="level-item is-hidden-mobile"></div>
                </nav>
                    
                    <nav className="bitrapy_level">
                        <div className="level-item is-hidden-mobile"></div>
                        <h1 className={styles.subtitle}>The score here indicates the cumulative scores over the entire lifecycle of the AI system. If you are
working on just one stage of your AI system, then do not worry about this score.</h1>
                        <div className="level-item is-hidden-mobile"></div>
                    </nav>
                    
                <div className="container">
                    {this.load_scoreboard()}

                        <div class="boxed">
                            <nav className="bitrapy_level">
                                <h1 className={styles.title}>Instructions</h1>
                            </nav>
                            <br></br>
                            <nav className="bitrapy_level">
                                <h1 className={styles.subtitle}>Depending on the stage or lifecycle of your AI project, you can click on the
        appropriate button that represents your AI system and provide the required inputs.</h1>
                            </nav>
                        </div>
                    {this.load_tabs()}
                    {this.state.active_component
                        ? this.load_metric_scoreboard(
                              this.state.active_component
                          )
                        : null}
                    {this.answer_summary(this.state.active_component)}
                    {this.state.active_component
                        ? this.load_questions()
                        : this.no_active_component()}
                </div>
            </div>
        )
    }
}
