/*
 * File: Scores.tutorial.jsx
 * Project: Machine Trust Platform
 * File Created: Monday, 14th June 2021 3:31:27 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 22nd June 2021 3:37:38 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import { Step } from 'react-joyride';
import CustomJoyRide from '../CustomJoyRide';
// import Tutorial from '../TutorialContainer';
// import { useTranslation } from 'react-i18next'

const ScoresTutorial = () => {
  const STEPS: Step[] = [
    {
      target: '[data-tut="scores-table"]',
      title: "Score Management Table",
      content: <p>Here you can review and manage your results - If you have any. This is also where you or your Admin will be able approve and pending scores. Approved Scores get displayed on the dashboard.</p>,
      disableScrolling: true,
    },
  ];
  return <CustomJoyRide steps={STEPS} />;
};

export default ScoresTutorial;
