/*
 * File: Standards.route.jsx
 * Project: Machine Trust Platform
 * File Created: Tuesday, 27th July 2021 12:49:41 pm
 * Author: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Last Modified: Friday, 20th August 2021 12:16:21 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React from "react";
import {
  Route,
  Switch,
  useRouteMatch,
  Redirect
} from "react-router-dom";
import Screens from '../app/screens'

const StandardsRoute = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      {/* Standards does not have a home/main page so redirect to AIA */}
      <Route exact path={url}>
        <Redirect to={`${url}/algorithmic-impact-assessment`} />
      </Route>
      
      <Route path={`${url}/algorithmic-impact-assessment`} component={Screens.Standards.AIA} />
      <Route path={`${url}/alt-ai`} component={Screens.Standards.ALTAI} />
      <Route path={`${url}/ciosc`} component={Screens.Standards.CIOSC} />
      <Route path={`${url}/ieee`} component={Screens.Standards.IEEE} />
      <Route path={`${url}/Waterloo`} component={Screens.Standards.Waterloo} />
      <Route path={`${url}/singapore`} component={Screens.Standards.Singapore} />

    </Switch>
  );
};

export default StandardsRoute;
