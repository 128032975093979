import { FeatureDetails, AIF360Input } from 'machine-trust-platform';
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { Button } from '../../../../../../../components'

import styles from './group.module.scss'
import PrivUnprivFeatureSelect from './PrivUnprivFeatureSelect';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  inputValues: AIF360Input;
  featureDetails: Record<string, FeatureDetails>;
  type: 'unprivilegedGroups' | 'privilegedGroups';
  updateInput?: (update: AIF360Input) => void
}

export interface PrivFeature {
  feature?: string;
  label?: string;
}

const translateMap = {
  "unprivilegedGroups": "unprivileged.groups",
  "privilegedGroups": "privileged.groups"
}

const Group = ({ inputValues, featureDetails, type, updateInput, ...rest }: Props) => {
  const { t } = useTranslation()

  const [groupList, setGroupList] = useState<PrivFeature[][]>([[{ feature: undefined, label: undefined }]])


/**
 * Updates a feature in a specific group. 
 * @param {number} groupIndex - The index of the group to be updated
 * @param {number} featureIndex - The index of the feature to be updated
 * @param {PrivFeature} privFeature - The new feature
 * @description  For example to update the age label, we would call, 
 *  handleGroupFeatureChange(0, 1, {feature: "age", label: "0"}). Where 0 is 
 *  the groupIndex and 1 is the feature index.
 * @example
 *  [
 *    [{feature: "gender", label: "1"}, {feature: "age", label: "1"}]
 *    [{feature: "Race", label: "1"}]
 * ]
 * 
 * 
 */  const handleGroupFeatureChange = (groupIndex: number, featureIndex: number, privFeature: PrivFeature) => {
    setGroupList(
      [
        ...groupList.slice(0, groupIndex),
        [
          ...groupList[groupIndex].slice(0, featureIndex),
          Object.assign({}, groupList[groupIndex][featureIndex], privFeature),
          ...groupList[groupIndex].slice(featureIndex + 1)
        ],
        ...groupList.slice(groupIndex + 1)
      ]
    )
  }

  /**
   * Adds a new Feature to a group. 
   * @param {number} groupIndex - The index of the group to be updated
   * @example
   *  [
   *    [{feature: "gender", label: "1"}, {feature: undefined, label: undefined}]
   * ]
   */
  const handleAddFeature = (groupIndex: number) => {
    setGroupList([
      ...groupList.slice(0, groupIndex),
      [
        ...groupList[groupIndex],
        { feature: undefined, label: undefined }
      ],
      ...groupList.slice(groupIndex + 1)
    ]
    )
  }

  /**
 * Deletes a Feature from a group
 * @param {number} groupIndex - The index of the group to be updated
 */
  const handleDeleteFeature = (groupIndex: number, featureIndex: number) => {
    let updatedGroup = [...groupList[groupIndex]]
    updatedGroup.splice(featureIndex, 1)
    setGroupList([
      ...groupList.slice(0, groupIndex),
      updatedGroup,
      ...groupList.slice(groupIndex + 1)
    ])
  }

  /**
   * Adds a Group 
   */
  const handleAddGroup = (e: any) => setGroupList([...groupList, [{ feature: undefined, label: undefined }]])

  /**
   * Deletes a full Group List
   * @param {number} groupIndex - 
   */
  const handleDeleteGroup = (groupIndex: number) => {
    let updatedGroupList = [...groupList]
    updatedGroupList.splice(groupIndex, 1)
    setGroupList(updatedGroupList)
  }


  /**
   * 
   * @param {PrivFeature[][]} _groupList - the group to be reformated
   * @returns {any}
   * 
   * @description Reformats the groups fo the use of AIF360. 
   * @example
   *  [
   *    [{feature: "gender", label: "1"}, {feature: "age", label: "1"}]
   *    [{feature: "Race", label: "1"}]
   * ]
   *  // Converts too
   *  [
   *    { "gender": 1, "age": 1 },
   *    { "race": 1 }
   * ]
   */
  const formatGroups = useCallback(async (_groupList: PrivFeature[][]) => {
    const groups: Record<string, number>[] = []

    await _groupList.forEach(async (group) => {
      const aif360Group: Record<string, number> = {}

      await group.forEach(async (f) => {
        if (f.feature && f.label) {
          aif360Group[f.feature] = Number(f.label)
        }
      })
      groups.push(aif360Group)
    })
    return { [type]: groups }
  }, [type])


  useEffect(() => {
    (async () => {
      let updatedInput = await formatGroups(groupList)
      updateInput && updateInput(updatedInput)
    })()
    // eslint-disable-next-line
  }, [groupList])



  return (
    <div
      className={`${styles.container}`}
      {...rest}
    >

      <div className={styles.title}>
        <span>{t(`${translateMap[type]}.label`)}</span>
      </div>

      <div className={`${styles.content}`}>
        {groupList.map((group, i) => (
          <div className={`${styles.group}`} key={v4()}>
            <div className={styles.header}>
              <span>{t('priv.unpriv.group.label', { number: i + 1 })}</span>

              <div className={styles.controller}>
                {i > 0 &&
                  <Button
                    width={35}
                    height={35}
                    variant='alert'
                    className={styles.btn}
                    title={t('delete.group.label')}
                    onClick={(e: any) => handleDeleteGroup(i)}
                  >
                    <i className="fas fa-trash-alt" />
                  </Button>}
                <Button
                  width={35}
                  height={35}
                  title={t('add.feature.label')}
                  className={styles.btn}
                  onClick={(e: any) => handleAddFeature(i)}
                >
                  <i className="fas fa-plus" />
                </Button>

              </div>
            </div>

            {group.map((f, j: number) => (
              <div key={v4()} className={styles.featureDetails}>

                <PrivUnprivFeatureSelect
                  group={f}
                  protectedFeatures={inputValues.protectedFeatures as string[]}
                  featureDetails={featureDetails}
                  onFeatureChange={(f: PrivFeature) => handleGroupFeatureChange(i, j, f)}
                />
                {j > 0 &&
                  <Button
                    variant='alert'
                    style={{
                      width: 30,
                      height: 30,
                      padding: 0,
                    }}

                    onClick={(e: any) => handleDeleteFeature(i, j)}
                  >
                    <i className="fas fa-trash-alt" />
                  </Button>}
              </div>
            ))}


          </div>
        ))
        }
      </div>
      <div className={styles.footer}>
        <Button
          onClick={handleAddGroup}
        >
          {t('add.group.label')}

        </Button>
      </div>
    </div>
  )
}

export default Group;