import AIGovernance from "./AIGovernance.drawer";
import Projects from "./Projects.drawer";
import AddOns from "./AddOn.drawer";
import Tools from "./Tools.drawer"
import Standards from './Standards.drawer'
import { MTPPath } from "../../constants";
import Home from "./Home.drawer";

const Drawers = {
  [MTPPath.AI_GOVERNANCE]: AIGovernance,
  [MTPPath.PROJECTS]: Projects,
  [MTPPath.ADDON]: AddOns,
  [MTPPath.TOOLS]: Tools,
  [MTPPath.STANDARDS]: Standards,
  [MTPPath.HOME]: Home
}

export default Drawers;