import React from 'react'
import { Link } from 'react-router-dom'
import { titleCase } from '../../../../utils/stringUtils'


import styles from './breadcrumbs.module.scss'

interface Props {
  pathArray: string[];
  index: number
}

const Breadcrumb = ({ pathArray, index }: Props) => {

  const path = `/${pathArray.slice(0, index + 1).join('/')}`
  const title = `${titleCase(pathArray[index]).replaceAll('-', ' ')}`
  const selected = pathArray.length - 1 === index; 

  return (
    <div className={styles.breadcrumb}>
      <i className="ri-arrow-right-s-line" />
        <Link className={`${styles.title} ${selected?styles.selected:""}` } to={path}>
            {title}
        </Link>
    </div>

  )
}

export default Breadcrumb