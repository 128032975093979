/*
 * File: SurveyControlButton.jsx
 * Project: mtp-webui
 * File Created: Sunday, 18th April 2021 6:54:46 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 25th August 2021 1:55:15 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from '../../../../../hooks';
import { Button } from '../../../../../components'
import {
  // getAnswers,
  cacheAnswers,
} from '../../../scorecard.helper';
import * as time from '../../../../../utils/time';
import {
  selectScorecardScoreDraftList,
  selectSelectedDraft,
  setSelectedScorecardScore,
  // selectScorecardScoreLastFetchTime,
  // getAllScorecardScore
} from '../../../slices/score.slice'

import type { Scorecard, Score } from 'machine-trust-platform';

import DropDownButton from "../../../../../components/DropDownButton"


// import styles from './questionnaire.control.button.module.scss'
// import { differenceInMinutes } from 'date-fns';

interface Props {
  scorecard: Scorecard;
  hasAnswers: boolean;
  clearSurvey: (e: any) => void;
}

const SurveyControlButtons = ({ scorecard, hasAnswers, clearSurvey }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // The list of all the drafts for the draft dropdown
  const scorecardDraftList: Score[] | undefined = useSelector((state) => selectScorecardScoreDraftList(state, scorecard.uuid)) || []
  const selectedDraft: Score | undefined = useSelector((state) => selectSelectedDraft(state, scorecard.uuid))

  // Boolean used to disable the draft list dropdown
  const noDraftsToDisplay = !scorecardDraftList || scorecardDraftList.length < 1;

  /**
   * 
   * @param {string|null} key
   * @param {any} e
   * @returns {any}
   */
  const handleSelectDraftItem = (key: string | null, e: any) => {
    const draft = (scorecardDraftList as Score[]).find((d) => d.uuid === key);
    const answers = draft?.answers;
    cacheAnswers(scorecard.uuid, answers);
    dispatch(setSelectedScorecardScore({scorecardUuid:scorecard.uuid, score: draft}));
    e.preventDefault();
  };

  return (

    <div className="survey-control-btns" role="group">
      <div className="control-btn-container" data-tut="control-buttons" role="group">
        <DropdownButton className="draft-dropdown-list" id="dropdown-split-variants-secondary" variant="secondary" title={t('drafts.label')} disabled={noDraftsToDisplay}>
          {scorecardDraftList
            && scorecardDraftList.map((d) => (
              <Dropdown.Item className="draft-drop-down-item"
                key={d.uuid}
                eventKey={d.uuid}
              active={selectedDraft?.uuid === d.uuid}
              onSelect={handleSelectDraftItem}>
                <div className="draft-drop-down-text">
                  {d.createdBy}
                  <span>{time.createDateTimeLabel(new Date(d.modified))}</span>
                </div>
              </Dropdown.Item>
            ))}
        </DropdownButton>
        <DropDownButton/>
        {hasAnswers &&
          <Button className="clear-btn" onClick={clearSurvey}>
            {t('clear.label')}
          </Button>
        }
      </div>
    </div>
  );
};

export default SurveyControlButtons;
