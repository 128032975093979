import React from "react";
import styles from "./styles/temp.module.scss";


const DEFAULT_DANGER_THRESHOLD = 30;
const DEFAULT_SUCCESS_THRESHOLD = 70;

export default class Score extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            danger_threshold:
                this.props.danger_threshold || DEFAULT_DANGER_THRESHOLD,
            success_threshold:
                this.props.success_threshold || DEFAULT_SUCCESS_THRESHOLD,
        };
    }

    color_class() {
        return this.props.score_value < this.state.danger_threshold ||
            this.props.score_value === "<30"
            ? "has-background-danger"
            : this.props.score_value > this.state.success_threshold ||
              this.props.score_value === ">70"
            ? "has-background-success"
            : "has-background-warning";
    }

    advice() {
        return this.props.score_value < this.state.danger_threshold ||
            this.props.score_value === "<30"
            ? "Substantial work needs to be done on the specific AI Trust Category. Keep iterating and keep working on your guardrails!"
            : this.props.score_value > this.state.success_threshold ||
              this.props.score_value === ">70"
            ? "Great Work. Keep monitoring your guardrails."
            : "More work needs to be done on the specific AI Trust Category. Keep iterating and keep working on your guardrails!";
    }

    main(){
        return (
            <div>
                {this.props.label ? (
                    this.props.label === "legend" ? (
                        <p className={styles.score_label}>
                            {/* {"this.advice()"} */}
                        </p>
                    ) : (
                        <p className={styles.score_label}>
                            {this.props.label}
                        </p>
                    )
                ) : (
                    <p className={styles.score_label}>
                        Overall
                    </p>
                )}
                <p
                    className={
                        "round-score title has-text-white "+
                        this.color_class()
                    }
                >
                    {this.props.score_value}%
                </p>
            </div>
        )
    }
    
 

    render() {
        return (
            <div align="left"
                className={
                    `${styles.level_item}
                    ${(this.props.label || "bitrapy").toLowerCase()}`
                }
            >
                {this.props.label === "legend" ?(
                    <div className= "has-tooltip-bottom" data-tooltip={null}>
                        {this.main()}
                    </div>
                ) : (
                    <div className= "has-tooltip-bottom" data-tooltip={this.advice()}>
                        {this.main()}
                    </div>
                )}
            </div>
        );
    }
}
