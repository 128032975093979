import React from 'react';
import { DecisionTreeContainer } from "../../../features/decisiontree/components";
import { QuestionnaireWrapper } from "../../../components";
import { useTranslation } from 'react-i18next';
import styles from "./DecisionTree.screen.module.scss"
import DTGraphic from "../../../assets/images/DT-high-level.png"
const DecisionTree = () => {
  const { t } = useTranslation();

  return (
    <>
      <QuestionnaireWrapper title={t('decisionTree.assessment.title')}>
      {/* <div className={`${styles.DTtext}`}>
      The AI Governance guide is an algorithm developed by NuEnergy to help users navigate to the metrics, tools, or processes relevant to their AI project. The diagram below shows a high-level walkthrough of the process and flow of this guide.
      </div>
      <div className={`${styles.DTimg}`}>
        <img src={DTGraphic}/>
      </div> */}
        <DecisionTreeContainer />
      </QuestionnaireWrapper>
    </>
  );
}

export default DecisionTree;