import React from 'react';
import { ConfigurableOnRequest } from '../../../components'

const ALTAIScreen = () => {

  return (
    <>
      <ConfigurableOnRequest />
    </>
      )
}

export default ALTAIScreen;