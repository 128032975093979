import React, { useState,  useMemo, Fragment } from 'react'
import { FeatureDetails, AIF360Input } from 'machine-trust-platform'
import styles from './select.dependent.feature.module.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  aif360InputValues: AIF360Input;
  featureDetails: Record<string, FeatureDetails>;
  selectionCallback: (arg: string) => void;
  // labelSelectCallback: (fav: number, unfav: number) => void;
}

const SelectDependantFeature = ({
  aif360InputValues,
  selectionCallback,
  featureDetails,
  ...rest }: Props) => {

  const [dependantFeature, setDependantFeature] = useState<string>(aif360InputValues.dependentFeature || "")

  // Sort the Features alphabetically
  const sortedList = useMemo(() => Object.values(featureDetails).sort((a, b) => a.feature < b.feature ? -1 : a.feature > b.feature ? 1 : 0), [featureDetails])

  const binaryFeatureList = sortedList.filter(f => f.isBinary)

  /**
   * Description
   * @param {any} e
   * @returns {any}
   */
  const handleSelectDependantFeature = (e: any) => {
    const featureName = e.target?.value
    setDependantFeature(featureName)
    selectionCallback && selectionCallback(featureName)
  }

  return (
    <div
      className={styles.selectDep}
      {...rest}
    >
      <div className={`select-features`}>
        {binaryFeatureList.map(f => (
          <Fragment key={f.feature}>

          <label
            className={'feature-label'}
            // For Tooltip
            // data-tip={`${f.feature}`}
            // data-place='left'
            // data-for={`${f.feature}-dependent-feature`}
            // data-multiline={true}
            // data-delay-show={500}
          >
            <input
              type="radio"
              value={f.feature}
              checked={dependantFeature === f.feature}
              name='dependantFeature'
              onChange={handleSelectDependantFeature}
              disabled={!f.isBinary || !f.isNumeric}

            />
            {f.feature}
            </label>
            {/* <ReactTooltip id={`${f.feature}-dependent-feature`} effect="solid" type="dark" /> */}
          </Fragment>
          ))}
        {/* <ReactTooltip id="dependent-feature" effect="solid" type="dark" /> */}
      </div>
    </div>
  )
}

export default SelectDependantFeature;