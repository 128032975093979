import { Column as RTColumn, DefaultFilterTypes, FilterTypes } from 'react-table';
import { ColumnFilter } from '.';

type CFilterType = 'select' | 'slider' | 'numberRange' | 'default'
const isCFilterType = (arg: any): arg is CFilterType  => (['select', 'slide', 'numberRange','default'].some(e=> e === arg))

export type Column =  RTColumn &  {
  Filter?: CFilterType | DefaultFilterTypes | FilterTypes<any>
}

const getFilterType = (type: CFilterType) => {
  switch (type) {
    case 'select':
      return ColumnFilter.Select
    case 'numberRange':
      return ColumnFilter.NumberRange
    case 'slider':
      return ColumnFilter.Slider
    default:
      return ColumnFilter.Default
  }
}

/**
 * Description
 * @returns {any}
 */
export const initializeColumns = (columns: Column[], columnFiltersEnabled: boolean): RTColumn<any>[] => (
  (columnFiltersEnabled ?
    columns.map(col => {
      // 
      if (isCFilterType(col.Filter)) {
        col.Filter = getFilterType(col.Filter) as unknown as Column['Filter']
      }
      
      // Disable any Filters that are not defined
      if (col.Filter === undefined && col.disableFilters === undefined) {
        col.disableFilters = true
      }


      return col

  }) :
    columns
  ) as RTColumn<any>[] 
)







