/* eslint-disable */
/**
 * File: AIGSSurveyResults.js
 * Project: mtp-webui
 * File Created: Monday, 1st March 2021 10:47:30 am
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 11th May 2021 4:14:33 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai>)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React, { createRef, useEffect, useRef } from 'react';
// import { useParams } from 'react-router-dom';
import { useQuery, useDispatch, useSelector } from '../../../hooks';
// import { DownloadPDF } from '../../components/buttons';

import ScorecardWrapper from '../../../components/QuestionnaireWrapper';
import { SCORECARD } from '../../../../constants';
import { BaseQuestionResult, Score } from 'machine-trust-platform';
import { selectScorecardScoreList } from '../../../features/scorecard/slices/score.slice';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import QuestionInfo from './QuestionInfo';
import { selectScorecardList } from '../../../features/scorecard/slices/scorecard.slice';
import DownloadPDF from '../../../components/DownloadPDFButton';
import { createDateLabel } from '../../../utils/time';
import Progressbar from '../ProgressBar/ProgressBar';

const getProgressVariant = (score: number) => {
	let variant : string;
	if( score < 50 ){
		variant = 'danger'
	}else if(score <= 70){
		variant = 'warning'
	}else{
		variant = 'success'
	}
	return variant
}

const Results = () => {
	const { t } = useTranslation();

	// Get the score uuid form the query
	// This can be null the scorecardUuid cannot be
	const query = useQuery();
	const scoreUuid = query.get('score');
	const scorecardUuid = query.get('scorecard') || '';
	const requirementUuid =  query.get('requirement')

	const scoreList = useSelector((state) =>
		selectScorecardScoreList(state, scorecardUuid)
	)?.filter((r) => r.state !== SCORECARD.SCORE.STATE.DRAFT);

	let results: Score | undefined = undefined;
	if (scoreList && scoreList.length > 0) {
		if (scoreUuid) {
			results = scoreList.find((s) => s.uuid === scoreUuid) || undefined;
		} else {
			results = scoreList[0];
		}
	}

	// Calculate the total weight for Requirements
	let totalOfRequirementWeights = 0;

	if (results && results.requirements) {
		results.requirements.forEach((r) => {
			totalOfRequirementWeights += r.weight;
		});
	}

	// generate a map of refs for each requirement with the key being the uuid
	const requirementHeaderRefs = useRef<{[key: string]: any}>({})
	results?.requirements?.forEach((r) => {
		requirementHeaderRefs.current[r.requirement.requirement_uuid] = requirementHeaderRefs.current[r.requirement.requirement_uuid] || createRef()
	})
	
	const scrollToRequirement = (id: string) => {
		const wrapper = document.getElementById("nuenergy-questionnaire"); // this is an id in ScorecardWrapper
		const elem = requirementHeaderRefs.current[id]?.current
		if (wrapper && elem) {
			window.scrollTo({
				top: elem.getBoundingClientRect().bottom - wrapper.getBoundingClientRect().top
			})
		}
	}

	useEffect(() => {
		if (requirementUuid) {
			scrollToRequirement(requirementUuid)
		}
	}, [requirementUuid])

	// Scorecard
	const scorecardList = useSelector(selectScorecardList) || undefined;
	const scorecard =
		scorecardList && scorecardUuid
			? scorecardList?.find((s) => s.uuid === scorecardUuid)
			: undefined;

	if (results && scorecard) {
		const title = scorecard.title == 'AIGS'
			? 'AI Governance Scorecard Results'
			: t("aims.scorecard.title");

		return (
			<>
				<ScorecardWrapper id="nuenergy-questionnaire" title={title}>
					<div className="scorecard-results__scorecard-title">
						<h2
							className="overall-score"
							data-tut="questionnaire-results-score"
						>
							{results ? `${Math.round(results.score as number)}%` : ''}{' '}
						</h2>
					</div>
					{/* added completed date */}
					<div className="scorecard-results__scorecard-date">
						<p>Date taken: {createDateLabel(new Date(scorecard.created))} </p>
					</div>
					<div
						className="scorecard-results__requirement-scores"
						data-tut="questionnaire-results-requirement-score"
					>
						{results && results.requirements
							? results.requirements.map((r) => (
									<div className="requirement-score" key={r.requirement.name}>
										{/* Requirement Heading */}
										<div className="requirement-progress__label">
											<h4 onClick={() => scrollToRequirement(r.requirement.requirement_uuid)}>{r.requirement.name}</h4>
											<span>{`${t('weight.label')}: ${Math.round(
												(r.weight / totalOfRequirementWeights) * 100
											)}%`}</span>
										</div>
										{/* Requirement Score */}
										<div className="requirement-progress__bar">
											{r.score !== -1 ? (
												// <ProgressBar
												// 	now={Math.round(r.score)}
												// 	label={`${Math.round(r.score)}%`}
												// 	// variant={getProgressVariant(Math.round(r.score))}
												// 	variant=''
												// />
												<Progressbar bgcolor='red' completed={Math.round(r.score)} />
												// <ProgressBar striped variant="warning" now={50} />
												// <ProgressBar key={idx} bgcolor={item.bgcolor} completed={item.completed} />
												
											) : (
												'N/A'
											)}
										</div>
									</div>
							  ))
							: null}
					</div>

					<div
						className="scorecard-results__requirement_question-scores"
						data-tut="questionnaire-results-requirement-question-score"
					>
						{/* Requirement Results */}
						{results && results.requirements
							? // TODO: Double check that r.requirement.uuid has a value
							  results.requirements.map((r) => (
									<div
										key={r.requirement.requirement_uuid}
										className="requirement"
									>
										<h3  className="requirement__header" ref={requirementHeaderRefs.current[r.requirement.requirement_uuid]}>
											{r.requirement.name}
										</h3>

										{/*  Question Results */}
										<div className="requirement__question-list">
											{r.question_results.map((question) => (
												<div
													className="requirement-question-result"
													key={question.question}
												>
													<QuestionInfo question={question} result={r} />
													{question.sub_questions ? (
														<div className="">
															{question.sub_questions?.map(
																(sq: BaseQuestionResult) => (
																	<QuestionInfo
																		key={`${sq.question}-sub_q`}
																		question={sq}
																		isSubQuestion
																	/>
																)
															)}
														</div>
													) : null}
												</div>
											))}
										</div>
										<div className="">As viewed on: {createDateLabel(new Date())}</div>
									</div>
							  ))
							: null}
					</div>
					<DownloadPDF
						title={`${scorecard.title}-results`}
						elementID={'nuenergy-questionnaire'}
					/>
				</ScorecardWrapper>
			</>
		);
	} else {
		/* TODO: show not found */
		return <></>;
	}
};

export default Results;
