import React from 'react';

import { useSelector } from '../../../../hooks'
import { selectTier } from '../../auth.slice';
import { Tier } from 'machine-trust-platform';

interface Props {
  tier: Tier
  children: React.ReactNode
}

const TierCheckWrapper = ({ tier, children }: Props) => {

  // Get the Tier of the Organization to ensure the user has the rights 
  // to proceed to view/use the content
  const organizationTier = useSelector(selectTier) as Tier

  const canViewContent = tier <= organizationTier

  return <>{canViewContent && children}</>
}

export default TierCheckWrapper;