import React from 'react'

import styles from './add.ons.screen.module.scss';


// TODO: Remove all Hard coded Text and use Translations

const AddOnScreen = () => {
  
  return (
    <div className={`${styles.container} container`}>
      <div className = {`${styles.addOnText}`}>
        <h1 className = {`${styles.addOnHeader}`}>Add-Ons</h1>
      </div>

      <div className = {`${styles.AddOnsContent}`}>
        <div className = {`${styles.AddOnsContentLeft}`}>
          <button className = {`${styles.MTI}`}>Machine Trust Index 
          <div>MTI is an open framework to measure trust in Artificial Intelligence over the lifecycle of the system, and can be used to quantify trust in AI products and services. It’s a methodology that is designed to provide an explicit evaluation of the trustworthiness of AI implementation based on current and future Ethics measures, Regulation and Standards compliances, Technology measures, and Accountability. The MTI methodology is versatile, auditable, and transparent.
          </div><div><a target="_blank" rel="noopener noreferrer" href="https://www.nuenergy.ai/machine-trust-index/">More Info</a></div></button>    

          <button className = {`${styles.IEEE}`}>IEEE P2863 Standards
          <div>This standard recommends practice specifying governance criteria such as safety, transparency, accountability, responsibility and minimizing bias, and process steps for effective implementation, performance auditing, training, and compliance in the development or use of artificial intelligence within organizations.
          </div>
          <div><a target="_blank" rel="noopener noreferrer" href="https://standards.ieee.org/ieee/2863/10142/">More Info</a></div>
          </button>

        </div>

        <div className = {`${styles.AddOnsContentRight}`}>
          <button className = {`${styles.EU}`}>(Eu)-Alt-AI
          <div>
          A practical tool that guides businesses and organizations to self-assess the trustworthiness of their AI systems under development. The concept of Trustworthy AI is based on 7 key requirements.
          <ol>
            <li>Human Agency and Oversight</li>
            <li>Technical Robustness and Safety</li>
            <li>Privacy and Data Governance</li>
            <li>Transparency</li>
            <li>Diversity, Non-discrimination, and Fairness</li>
            <li>Environmental and Societal well-being</li>
            <li>Accountability</li>
          </ol> It is intended to help organizations identify how proposed AI systems might generate risks, and to identify whether and what kind of active measures may need to be taken to avoid and minimize those risks. It raises the potential impact of AI on society, the environment, consumers, workers, and citizens. It seeks to make ethics a core pillar for developing a unique approach to AI, one that aims to benefit, empower, and protect both individual human flourishing and the common good for society.
          </div>
          <div><a target="_blank" rel="noopener noreferrer" href="https://futurium.ec.europa.eu/en/european-ai-alliance/pages/altai-assessment-list-trustworthy-artificial-intelligence">More Info</a></div>
          </button>

          <button className = {`${styles.CIOSC}`}>CIOSC 
          <div>CIO strategy council has deployed a nationally accredited, agile, and consensus-based standards-setting process that matches the speed of innovation and advancement in ICT. This Standard specifies minimum requirements in protecting human values and incorporating ethics in the design and use of automated decision systems. This Standard is limited to artificial intelligence (AI) using machine learning for automated decisions. This Standard applies to all organizations, including public and private companies, government entities, and not-for-profit organizations. It provides a framework and process to help organizations address AI ethics principles, such as those described by the OECD: - AI should benefit people and the planet by driving inclusive growth, sustainable development, and well-being.</div>
          <div><a target="_blank" rel="noopener noreferrer" href="https://ciostrategycouncil.com/standards/">More Info</a></div>
          </button>
        </div>
      
        <div className = {`${styles.contactText}`}>
          <h1 className = {`${styles.contactHeader}`}><a target="_blank" rel="noopener noreferrer" href="https://www.nuenergy.ai/contact/">Contact us</a></h1>
          {/* <p className = {`${styles.contactContent}`}>Needs to be written</p> */}
        </div>

      </div>
    </div>
  )
}

export default AddOnScreen