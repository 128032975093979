import { AlgorithmicImpactAssessment, Project } from 'machine-trust-platform';
import React, { useCallback, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from '../../../../hooks';
import config from '../../../../config.json';
import { submitProjectAIA } from '../../../project/project.slice';


const MSG = {
  STANDARD: 'standard',
  PROJECT: 'project',
};

interface Props {
  assessment?: AlgorithmicImpactAssessment;
  isStandard?: boolean;
  project?: Project;
};

const AlgorithmicAssessmentImpact = ({ assessment, isStandard = false, project = undefined }: Props) => {
  const dispatch = useDispatch();

  let messageCode = isStandard ? MSG.STANDARD : undefined;
  messageCode = project && !messageCode ? MSG.PROJECT : messageCode;

  // TODO: handle different AIA version
  const aiaUri = config.aia_uri;
  const encode = btoa(
    JSON.stringify({
      payload: assessment ? { toolData: JSON.parse(assessment.answers as string) } : null,
      messageCode,
      assessmentUuid: assessment ? assessment.uuid : uuidv4(),
    }),
  );

  const receiveMessage = useCallback(
    (event) => {
      const { message } = event.data;

      switch (message) {
        case MSG.PROJECT:
          dispatch(submitProjectAIA({ project, aia: event.data.value }));
          break;
        case MSG.STANDARD:
          break;
        default:
          break;
      }
    },
    [dispatch, project],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [receiveMessage]);

  return (
    <div className="algorithmic-impact-assessment-container" data-tut="AIA">
      <iframe title="Algorithmic Impact Assessment" id="aia-iframe" src={`${aiaUri}?compliance=${encode}`} />
    </div>
  );
};

export default AlgorithmicAssessmentImpact