import ToolSelection from './ToolSelection.screen';
import AIF360 from './AIF360.screen';
import ToolRequests from './ToolRequests.screen';
import Fairlearn from './Fairlearn.screen';
import Landing from './Landing.screen';

const ToolScreens = {
  ToolSelection,
  AIF360,
  ToolRequests,
  Fairlearn,
  Landing,
}

export default ToolScreens;
