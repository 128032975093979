/*
 * File: Tools.route.jsx
 * Project: Machine Trust Platform
 * File Created: Tuesday, 27th July 2021 12:49:41 pm
 * Author: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Last Modified: Friday, 20th August 2021 12:00:57 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { createToolRequestStateForUserID, getToolRequestStateForUserId } from "../app/features/tools/slices/request.slice";
import { useDispatch, useSelector } from "../app/hooks";
import Screen from "../app/screens";

interface Props {
  contextUuid: string
}

const ToolsRoute = ({contextUuid}: Props) => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch()

  const toolRequestState = useSelector(state => getToolRequestStateForUserId(state, contextUuid))
  
  useEffect(() => {
    if (!toolRequestState) {
      dispatch(createToolRequestStateForUserID(contextUuid))
    }

  }, [toolRequestState, contextUuid, dispatch])

  return (
    <Switch>
      <Route path={url} exact component={Screen.Tools.ToolSelection} /> 
        {/* <Redirect to={`${url}/AIF360`}/> */}
      {/* </Route> */}
      <Route path={`${url}/results`}>
        <Screen.Tools.ToolRequests userId={contextUuid} />
      </Route>

      <Route path={`${url}/AIF360`}>
        <Screen.Tools.AIF360 userId={contextUuid} />
      </Route>

      <Route path={`${url}/FairlearnML`}>
        <Screen.Tools.Fairlearn userId={contextUuid} />
      </Route>
    </Switch>
  );
};

export default ToolsRoute;
