import React, { useMemo,  Fragment } from 'react'
import { AIF360Input, FairlearnMLInput, FeatureDetails, ToolWizardInput } from 'machine-trust-platform';
import styles from './select.protected.features.module.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  featureDetails: Record<string, FeatureDetails>;
  inputValues: ToolWizardInput;
  onFeatureChange: (input: AIF360Input | FairlearnMLInput) => void;
  disableFeature?: (f: FeatureDetails) => boolean;
}

const ProtectedFeatures = ({ featureDetails, inputValues, onFeatureChange, disableFeature = (f: FeatureDetails) => false,  ...rest }: Props) => {

  const protectedFeatureList = inputValues.protectedFeatures || []

  // Sort the Features alphabetically
  const featureList = useMemo(() => Object.values(featureDetails).filter(f => f.feature !== inputValues.dependentFeature).sort((a, b) => a.feature < b.feature ? -1 : a.feature > b.feature ? 1 : 0), [featureDetails, inputValues])

  /**
   * Description
   * @param {React.ChangeEvent<HTMLInputElement>} e - 
   * @returns {Void}
   */
  const handleFeatureSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target?.value
    onFeatureChange({
      protectedFeatures:
        protectedFeatureList.includes(value) ?
          protectedFeatureList.filter(feature => feature !== value)
        :
          [...protectedFeatureList, value]
    })
  }

  return (
    <div
      className={`${styles.container}`}
      {...rest}
    >
      <div className={`select-features`}>
        {featureList.map(f => (
          <Fragment key={`${f.feature}-checkbox`}>
          <label
            // key={`${f.feature}-checkbox`}
            className='feature-label'
            
            // For Tooltip
            // data-tip={`${f.feature}`}
            // data-place='left'
            // data-for={`${f.feature}-protected-features`}
            // data-multiline={true}
            // data-delay-show={500}
          >
            <input
              type="checkbox"
              value={f.feature}
              checked={protectedFeatureList.includes(f.feature)}
              onChange={handleFeatureSelection}
              disabled={disableFeature(f)}
            />
            {f.feature}
          </label>
            {/* <ReactTooltip id={`${f.feature}-protected-features`} effect="solid" type="dark" /> */}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default ProtectedFeatures;