import React from 'react';
import { FrameworkContainer }  from "../../../features/framework/components";
import { QuestionnaireWrapper } from "../../../components" ;
import { useTranslation } from 'react-i18next';

const Framework = () => {
    const { t } = useTranslation();

    return (
      <>
        <QuestionnaireWrapper title={t('framework.assessment.title')}>
          <FrameworkContainer/>
        </QuestionnaireWrapper>
      </>
    );
}

export default Framework;