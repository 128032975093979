/*
 * File: AIMSScores.jsx
 * Project: mtp-webui
 * File Created: Wednesday, 5th May 2021 2:32:36 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 22nd June 2021 5:49:37 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from '../../hooks';
import { ScoresTable } from '../../features/scorecard/components';
import { selectSelectedScorecard } from '../../features/scorecard/slices/scorecard.slice';
import { getAllScorecardScore, selectScorecardScoreLastFetchTime, selectScorecardScoreList } from '../../features/scorecard/slices/score.slice';
import { differenceInMinutes } from 'date-fns';

interface Props {
  path: string;
}

const ScorecardScores = ({ path }: Props): JSX.Element => {
  const dispatch = useDispatch()
  // const project = useSelector(state => state.projects.selected) || null
  const scorecard = useSelector(selectSelectedScorecard) || undefined
  const scorecardScoreList = useSelector((state) => selectScorecardScoreList(state, scorecard?.uuid))
  const lastFetchTime = useSelector(state => selectScorecardScoreLastFetchTime(state, scorecard?.uuid))

  useEffect(() => {
    if (scorecard && (lastFetchTime === -1 || differenceInMinutes(new Date(lastFetchTime), new Date()) >= 5)) {
      dispatch(getAllScorecardScore(scorecard.uuid))
    }
  }, [scorecard, lastFetchTime, dispatch])


  return (
    <>
      <div className="container">
        <ScoresTable path={path} scorecardUuid={scorecard?.uuid} scores={scorecardScoreList} />
      </div>
    </>
  );
};

export default ScorecardScores;
