import { combineReducers } from "redux";
import uiStateReducer from "./uistate.slice";
import navigationReducer from "./navigation.slice";
import tutorialReducer from "./tutorial.slice";


export default combineReducers({
  main: uiStateReducer,
  navigation: navigationReducer,
  tutorial: tutorialReducer,
})