
import React from 'react'
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ManagementDrawer from './Management.drawer';
import SelectedProjectDrawer from './Selected.drawer'

const ProjectDrawer = () => {
  const { path } = useRouteMatch();

  return (
      <Switch>
        <Route path={`${path}`} exact component={ManagementDrawer} />
        <Route path={`${path}/:name`} component={SelectedProjectDrawer} />
      </Switch>
  )
}

export default ProjectDrawer;