import React from 'react';
import { useTranslation } from 'react-i18next';

import { CanvasContainer }  from "../../../features/canvas/components";
import { QuestionnaireWrapper } from "../../../components" ;

const Canvas = () => {
  const { t } = useTranslation();

  return (
    <>
      <QuestionnaireWrapper title={t('canvas.questionnaire.title')}>
        <CanvasContainer />
      </QuestionnaireWrapper>
    </>
  );
};



export default Canvas;
