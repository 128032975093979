import React from 'react';
import NuEnergyBase64Logo from '../NuEnergyBase64Logo';
import CircleIcon from "../CircleIcon"

// import "./questionnaire.wrapper.module.scss"


interface Props {
  id?: string;
  title?: string;
  children: React.ReactNode;
}

const ScorecardWrapper = ({id = 'nuenergy-questionnaire', title = '', children }: Props) => {
  let t=""
  let sub=""
  if(title.includes(":")){
    t=title.substring(0,title.indexOf(':'))
    sub=title.substring(title.indexOf(':')+1, title.length)
  }else{
    t=title
  }

  return (
    <div style={{
      paddingBottom: '25vh'
    }}>
    <div id={id} className="scorecard-results survey-container" data-tut="questionnaire-results">
      <div className="scorecard-results__nuenergy-logo">
        <NuEnergyBase64Logo width={102} height={30} />
      </div>
      <div id="scorecard-title" className="scorecard-results__title">
        <CircleIcon iconClass="ri-share-fill" reverse />
        <div>
          <span className="project-surveys-titles">{t}</span>
          <sub>{sub}</sub>    
        </div>
      </div>
      <div className="scorecard-results__container container">
        {children}
      </div>
      </div>
    </div>
  );
};

export default ScorecardWrapper;