import React, {
  useMemo,
  useState,
  useCallback,
  useEffect
} from "react";
import type { Project } from "machine-trust-platform";
import {
  useDispatch,
  useSelector
} from "../../../../hooks";
import { format } from "date-fns";
import projectSlice, {
  // selectSelectedProject,
  selectProjectList,
  setSelectedProject,
  deleteProject,
  editProject,
} from "../../project.slice";

import Table, { } from '../../../../components/Table'
import { MTPPath } from "../../../../../constants";
import { Link } from "react-router-dom";
import CreateForm from "../CreateForm"

import styles from "./projecttable.module.scss";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ConfirmActionConfig } from "../../../../components/Popup/ConfirmAction";
import { Popup } from "../../../../components";

const ProjectTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation()

  const projectList = useSelector(selectProjectList);
  const [listOfSelectedProjects, setListOfSelectedProjects] = useState<Project[]>([])

  const [config, setActionConfig] = useState<ConfirmActionConfig | undefined>()

  const handleDeleteProject = useCallback(() => {
    if (listOfSelectedProjects.length === 1) {
      const project = listOfSelectedProjects[0] as Project
      setActionConfig({
        actionPrincipal: 'delete.project.label',
        messageCodes: [['confirm.delete.project.label', { "project": `${project.name}`}]] as any,
        actionButtonLabelCode: 'delete.label',
        action: () => dispatch(deleteProject((listOfSelectedProjects[0] as Project).uuid)),
      })
  }
  }, [listOfSelectedProjects, dispatch])

  const handleUpdateProject = (project: Partial<Project>) => {
    dispatch(editProject(project))
  }

  const handleRowSelection = useCallback((items: any) => {
    setListOfSelectedProjects(items as Project[])
  }, [])

  const columns = useMemo(() => (
    [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value, row }: { value: string, row: any }) =>
          <Link
            className={styles.projectName}
            to={`${MTPPath.PROJECTS}/${value}`}
            onClick={() => {
              if ((row.original as Project).uuid) dispatch(setSelectedProject(row.original as Project))
            }}>
            {value}
          </Link>,
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value, row }: { value: string, row: any }) => {
          return <ProjectDescriptionTableColumn
            project={row.original}
            handleUpdateProject={handleUpdateProject}
          />
        }
      },
      {
        Header: "Cycle",
        accessor: 'cycle',

      },
      {
        Header: "Date Created",
        accessor: "created",
        Cell: ({ value }: { value: string }) => format(new Date(value), 'dd/MM/yyyy'),
        // disableFilters: false
      }
    ]
  ), [dispatch])

  return (
    <>
      <Popup.ConfirmAction config={config} />
      
      <div className={`${styles.container} container`} data-tut="projects-tables">
        <div className={styles.header}>
          {/* TODO: Remove Create form and replace with POPUP that handles errors correctly */}
          <CreateForm />
          <DropdownButton title={t('actions.label')}>
            <Dropdown.Item
              onSelect={handleDeleteProject}
              disabled={!(listOfSelectedProjects.length === 1)}
            >
              {t('delete.label')}
            </Dropdown.Item>

            
            {/* <Dropdown.Item

              // TODO: Add Project Edit Capabilities

            >
              {t('edit.label')}
            </Dropdown.Item> */}
          </DropdownButton>


        </div>

        <Table
          columns={columns}
          items={projectList}
          handleRowSelection={handleRowSelection}
          sortable
          initialState={{sortBy : [{id: 'created', desc: true}]}}
        // columnFilter
        // globalFilter
        />

      </div>

    </>
  );
};


const ProjectDescriptionTableColumn = (props: {
  project: Project
  handleUpdateProject: (project: Partial<Project>) => void
}) => {
  const { t } = useTranslation()

  const [editing, setEditing] = useState<boolean>(false)
  const [description, setDescription] = useState<Project['description']>(props.project.description)

  const handeSubmit = () => {
    try {
      props.handleUpdateProject({uuid: props.project.uuid, description: description})
      setEditing(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (editing) {
      setDescription(props.project.description)
    }
  }, [editing, props.project.description])

  return editing 
    ? <>
      <Form.Control
        as="textarea"
        rows={4}
        value={description}
        maxLength={512}
        onChange={(e) => setDescription(e.target.value)}
      />
      <div style={{marginTop: "5px"}}>
        <Button onClick={handeSubmit} disabled={description?.length >= 512} style={{float: "right"}}>
          {t("save.label")}
        </Button>
        <Button onClick={() => setEditing(false)} variant="secondary" style={{float: "right", marginRight: "5px"}}>
          {t("cancel.label")}
        </Button>
      </div>
    </> 
    : <>
      <div className={styles.projectDescription} onClick={() => setEditing(true)}>
        {props.project.description}
        <i className={props.project.description ? "ri-edit-fill" : "ri-add-fill"}></i>
      </div>
    </> 
}

export default ProjectTable;
