import { createSlice, PayloadAction, createAsyncThunk, isRejected, isPending } from "@reduxjs/toolkit";
import axios from 'axios'
import { RootState } from "../../store";
import config from '../../config.json'
import type { FaultReport, Dataset } from 'machine-trust-platform'


type Status = "idle" | "loading" | "failed";

export interface DatasetState {
  selected?: any; // This will be the dataset object whether it be the file or information about the dataset
  list: Dataset[]
  status: 'idle' | 'loading' | 'failed'
  error?: string
}

const initialState: DatasetState = {
  selected: undefined,
  list: [],
  status: 'idle',
  error: undefined
}

/**
* @param 
* 
*/
const uploadFile = createAsyncThunk(
  'dataset/uploadFile',
  async (form: FormData, thunkApi) => {

    try {
      const res = await axios.post(`${config.ai_tool_uri}/UploadDataSetFile`, form)
      if (res.data.faultReport.isFault) {
        alert(JSON.stringify(res.data.faultReport.faultDescription))
        return thunkApi.rejectWithValue(res.data.faultReport as FaultReport)
      }
      
      let dataset: Dataset = {
        datasetId: res.data.response.userFileId,
        filename: res.data.response.userFileId
      }
      
      alert(JSON.stringify(res.data.response))
      
      return dataset

    } catch (err) {
      return thunkApi.rejectWithValue({ isFault: true, faultDescription: err} as FaultReport)
    }
  }
  )

export const datasetStateSlice = createSlice({
  name: "dataset",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<Status>) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.pending, (state) => {


      })
      .addCase(uploadFile.fulfilled, (state, action) => {


      })

      /**
       * Use Matchers to set the pending and rejected states
       */
      .addMatcher(
        isPending,
        (state) => {
          state.status = 'loading'
        }
      )
      .addMatcher(
        isRejected,
        (state) => {
          state.status = 'failed'
        }
      )



  },
});

// Dateset Actions
export const { setLoading } = datasetStateSlice.actions;
export {
  uploadFile,

}

// Selectors
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectDatasetStatus = (state: RootState) => state.dataset.status;

export default datasetStateSlice.reducer;
