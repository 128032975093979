import AIGovernance from "./AIGovernance";
import Project from "./Project";
import Scorecard from "./Scorecard";
import Tools from "./Tools"
import Home from "./Home/Home.screen";
import Standards from './Standards';
import AddOns from './AddOns';
import Settings from "./Settings";

const Screens = {
  AIGovernance,
  Project,
  Scorecard,
  Tools,
  Standards,
  Home,
  AddOns,
  Settings,
}

export default Screens;