import SelectionTable from "./ProjectManagementTable.screen";
import Dashboard from "./Dashboard.screen";
import Tools from "../Tools/ToolSelection.screen";
import AIA from './AIA.screen'
import NTop from "./NTop.screen";
import Framework from "./Framework.screen";
import DecisionTree from "./DecisionTree.screen";
import Bitrapy from "./Bitrapy.screen";
import preAIA from "./preAIA.screen";


const ProjectScreens = {
  SelectionTable,
  Dashboard,
  AIA,
  Tools,
  NTop,
  Framework,
  Bitrapy,
  DecisionTree,
  preAIA,

}

export default ProjectScreens;

