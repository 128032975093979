import React, { useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import _uniqueId from 'lodash/uniqueId';
// import styles from './button.module.css';
// import { Ripple, calcEventRelativePos } from './Ripple';

import styles from "./button.module.scss";

export type ButtonVariant = "primary" | "secondary" | "third" |"green" | "warn" | "alert" | "white" |"purple";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  className?: any;
  variant?: ButtonVariant;
  border?: string;
  color?: string;
  children?: React.ReactNode;
  width?: string | number;
  height?: string | number;
  radius?: string;
  disabled?: boolean;
  title?: string;
}

const Button = ({
  className = "",
  border,
  color,
  children,
  height,
  onClick,
  radius,
  width,
  variant = "primary",
  disabled = false,
  title = "",
  ...rest
}: Props) => {
  const rippleRef = useRef<HTMLButtonElement>(null);

  // The coordinates of the click
  const [coords, setCoords] = useState({ x: -1, y: -1 });

  const tooltipId = _uniqueId('btn-')

  return (
    <button
      
      ref={rippleRef}

      data-tip={`${title}`}
      data-for={tooltipId}
      data-place='right'
      data-multiline={true}
      data-delay-show={500}

      className={`${styles[`nu-btn-${variant}`]} ${styles.rippleButton
        } ${className}`}
      style={{
        backgroundColor: color,
        border,
        borderRadius: radius,
        height,
        width,
      }}
      disabled={disabled}
      onClick={(e: any) => {
        const rect = rippleRef.current?.getBoundingClientRect();
        setCoords({
          x: e.clientX - (rect?.left as number),
          y: e.clientY - (rect?.top as number),
        });

        setTimeout(() => {
          setCoords({ x: -1, y: -1 });
          onClick && onClick(e);
        }, 600);
      }}
      {...rest}
    >
      {coords.x > 0 && (
        <span
          className={styles.ripple}
          style={{
            // TODO: get lighter color of parent
            // background:
            left: coords.x,
            top: coords.y,
          }}
        />
      )}
      <span className={styles.content}>{children}</span>
      <ReactTooltip id={tooltipId} />
    </button>
  );
};

export default Button;
