import React, { useEffect } from "react";
// import { useDispatch } from 'react-redux';
import { Route, Switch, useRouteMatch } from "react-router-dom";
import QuestionnaireRoute from './Questionnaire.route';
import Screens from "../app/screens";
import { selectAIGSScorecardUuid } from "../app/features/auth/auth.slice";
import { useSelector, useDispatch } from "../app/hooks";
import { getScorecard, setSelectedScorecard } from "../app/features/scorecard/slices/scorecard.slice";

const AIGovernanceRoute = () => {
  const dispatch = useDispatch()
  const { url } = useRouteMatch();

  const scorecardUuid = useSelector(selectAIGSScorecardUuid) as string;

  useEffect(() => {
    // Get specific Scorecard
    // By default this sets the scorecard returned from the api call
    // to selected. Meaning you can  safely assume that the selected 
    // scorecard is AIGS in any of the routes defined below
    dispatch(getScorecard(scorecardUuid))
    
    return () => {
      // Remove the selected scorecard when the user leaves AI Governance Service
      dispatch(setSelectedScorecard(undefined))
    }

  }, [scorecardUuid, dispatch])

  
  return (
    <Switch>
      {/* Screens */}
      <Route path={url} exact component={Screens.AIGovernance.Dashboard} />
      
      <Route path={`${url}/canvas`} component={Screens.AIGovernance.Canvas} />
      
      <Route path={`${url}/scores`}>
        <Screens.Scorecard.Scores path={url} />
      </Route>

      
      {/* Nested Routes */}
      <Route path={`${url}/questionnaire`} >
        <QuestionnaireRoute scorecardUuid={scorecardUuid} />
      </Route>
      
    </Switch>
  );
};

export default AIGovernanceRoute;
